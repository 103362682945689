// api obj to contain api endpoints and their required url params
export const api = {
  generateToken: {
    url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/app/{ClientID}/{UserID}/generateToken',
    params: ['{ClientID}', '{UserID}'],
  },
  generateAuthTokenU2: {
    url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/app/{ClientID}/{UserID}/generateAuthToken',
    params: ['{ClientID}', '{UserID}'],
  },
  createTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Create/Ticket',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Create/Ticket',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  getMapLayers: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/MapLayers',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/MapLayers',
        params: ['{ClientID}'],
      },
    },
  },
  mapCaptures: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/clients/{ClientID}/maps/captures',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/clients/{ClientID}/maps/captures',
        params: ['{ClientID}'],
      },
    },
  },
  drawingForms: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/clients/{ClientID}/drawingForms',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/clients/{ClientID}/drawingForms',
        params: ['{ClientID}'],
      },
    },
  },
  getMapDrawing: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/MapDrawing',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/MapDrawing',
        params: ['{ClientID}'],
      },
    },
  },
  uploadDocument: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Upload/Document',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Upload/Document',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  queueEmails: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/QueueEmails',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/QueueEmails',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  autoEngineController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/AutoEngineController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/AutoEngineController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  userCategoryController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/UserCategoryController ',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/UserCategoryController ',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  refreshAdminTables: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/RefreshAdminTables',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/RefreshAdminTables',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  downloadTicketUX: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://vkghz81dt9.execute-api.ca-central-1.amazonaws.com/{stage}/ux/{ClientID}/DownloadTicket',
        params: ['{stage}', '{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://vkghz81dt9.execute-api.ca-central-1.amazonaws.com/{stage}/ux/{ClientID}/DownloadTicket',
        params: ['{stage}', '{ClientID}'],
      },
    },
  },
  addDigsiteToTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/AddDigsiteToTicket',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/AddDigsiteToTicket',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  gatherDigsites: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/GatherDigsites',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Ticket/GatherDigsites',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  retrieveDocumentsS3: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Documents/Retrieve/{DocumentID}/S3',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Documents/Retrieve/{DocumentID}/S3',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  documentToAngularController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/DocumentToAngularController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/DocumentToAngularController',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  getEditTables: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{UserID}/EditTable/GetEditTableList',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{U3USER.USERID}/EditTable/GetEditTableList',
        params: ['{ClientID}', '{U3USER.USERID}'],
      },
    },
  },
  modifyEditTables: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{UserID}/EditTable/EditTablesModify/{EditTableID}/{ModifyType}/{PrimaryFieldName}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{U3USER.USERID}/EditTable/EditTablesModify/{EditTableID}/{ModifyType}/{PrimaryFieldName}',
        params: ['{ClientID}', '{U3USER.USERID}'],
      },
    },
  },
  openEditTables: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{UserID}/EditTable/OpenEditTable',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u3/{ClientID}/{U3USER.USERID}/EditTable/OpenEditTable',
        params: ['{ClientID}', '{U3USER.USERID}'],
      },
    },
  },

  schedulerEvent: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/scheduler/event',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/scheduler/event',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  schedulerResource: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/scheduler/resource',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/scheduler/resource',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  schedulerSchedule: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/scheduler/schedule',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/scheduler/schedule',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  schedulerTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/scheduler/ticket',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/scheduler/ticket',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  gatherTicketLatLng: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/GatherTicketLatLng',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Ticket/GatherTicketLatLng',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  createTicketController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Create/Ticket/Controller',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Create/Ticket/Controller',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  quickTicketActions: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/actions/{UserID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/actions/{USERID}',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  changeExcavationDate: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/ExcavationDate',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/ExcavationDate',
        params: [],
      },
    },
  },
  clearTicketBulk: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Clear',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Clear',
        params: [],
      },
    },
  },
  groupTickets: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Group',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Group',
        params: [],
      },
    },
  },
  ungroupTickets: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Ungroup',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Auxiliaries/Ungroup',
        params: [],
      },
    },
  },
  gatherTicketActions: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/TicketActions/Gather',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/TicketActions/Gather',
        params: [],
      },
    },
  },
  bulkTicketActions: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Actions',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Actions',
        params: [],
      },
    },
  },
  cancelTicketAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Cancel',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Cancel',
        params: [],
      },
    },
  },
  reassignTicketAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Reassign',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Reassign',
        params: [],
      },
    },
  },
  sendTwoWayMessage: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/SendTwoWayMessage',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/SendTwoWayMessage',
        params: ['{ClientID}'],
      },
    },
  },
  gatherExcavatorsForTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Tickets/Excavators/Gather',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Tickets/Excavators/Gather',
        params: [],
      },
    },
  },
  officeCompleteTicketAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/OfficeComplete',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/OfficeComplete',
        params: [],
      },
    },
  },
  officeOngoingTicketAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/OfficeOngoing',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/OfficeOngoing',
        params: [],
      },
    },
  },
  downloadTicketU2: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/download/{UserID}/{AssignmentID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/download/{UserID}/{AssignmentID}',
        params: ['{ClientID}', '{UserID}'],
      },
    },
  },
  downloadAllUserTicketsU2: {
    type: 'PUT',
    url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/download/{UserID}',
    pararms: ['{ClientID}', '{UserID}'],
  },
  uploadTickets: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/upload/{UserID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/upload/{USERID}',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  uploadTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/Upload',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/Ticket/Upload',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  uploadDocNew: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/dev/u2/{ClientID}/{UserID}/assignments/{AssignmentID}/primaries/{PrimaryID}/DocumentAccessController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/dev/u2/{CLIENTID}/{USERID}/assignments/{AssignmentID}/primaries/{PrimaryID}/DocumentAccessController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  TicketProtection: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/TicketProtection',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/TicketProtection',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  DocumentController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/DocumentController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/DocumentController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  UtilocateDownloadTicketAll: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/Download/{AssignmentID}/All',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/Ticket/Download/{AssignmentID}/All',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  UtilocateDownloadTicketPrimary: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/Download/{AssignmentID}/Primary/{PrimaryID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/Ticket/Download/{AssignmentID}/Primary/{PrimaryID}',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  UtilocateDownloadTicketRequestNum: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Ticket/Download/Request/{RequestNumber}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/Ticket/Download/Request/{RequestNumber}',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  generateAuthTokenU4: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u4/generateAuthToken',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/u4/generateAuthToken',
        params: [],
      },
    },
  },
  uncompleteTicketAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Uncomplete',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/Uncomplete',
        params: [],
      },
    },
  },
  downloadLocatorTickets: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/ClientID/{ClientID}/Tickets/Users/{UserID}/Download',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/ClientID/{ClientID}/Tickets/Users/{USERID}/Download',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  createTicketControllerFieldSide: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Create/Ticket/Controller',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{USERID}/Create/Ticket/Controller',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  uploadTicketsFieldSide: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/upload/{UserID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/upload/{USERID}',
        params: ['{ClientID}', '{USERID}'],
      },
    },
  },
  retrieveDocumentsS3FieldSide: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{ClientID}/tickets/download/{UserID}/Document/{DocumentID}/S3',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://rr6j3yar5e.execute-api.ca-central-1.amazonaws.com/{stage}/{CLIENTID}/tickets/download/{USERID}/Document/{DocumentID}/S3',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  xmlTemplateController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/XMLTemplateController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/XMLTemplateController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  clearTicket: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Users/Assignments/Primaries/{PrimaryID}/Auxiliaries/Clear/{ClearTypeID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Users/Assignments/Primaries/{PrimaryID}/Auxiliaries/Clear/{ClearTypeID}',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  rouingPresetController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/RoutingPresetController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/RoutingPresetController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  preparePDFGeneration: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/PreparePDFGeneration',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/PreparePDFGeneration',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  userManagerGetUsers: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/All',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/All',
        params: ['{CLIENTID}'],
      },
    },
  },
  userManagerUpdateUser: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/{UserID}',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/{UserID}',
        params: ['{CLIENTID}'],
      },
    },
  },
  userManagerCreateUser: {
    type: 'POST',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/{UserID}',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/{UserID}',
        params: ['{CLIENTID}'],
      },
    },
  },
  userManagerArchiveUsers: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/Archive',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/Archive',
        params: ['{CLIENTID}'],
      },
    },
  },
  userManagerUnarchiveUsers: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/Unarchive',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/Unarchive',
        params: ['{CLIENTID}'],
      },
    },
  },
  userManagerAssociateUser: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Users/Associate',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Users/Associate',
        params: ['{CLIENTID}'],
      },
    },
  },
  systemMaintenanceController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/SystemMaintenanceController',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/SystemMaintenanceController',
        params: ['{CLIENTID}'],
      },
    },
  },
  getTimesheetEntries: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Timesheet/Entries',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Timesheet/Entries',
        params: ['{CLIENTID}'],
      },
    },
  },
  timesheetSync: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Timesheet/Sync',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Timesheet/Sync',
        params: ['{CLIENTID}'],
      },
    },
  },
  getUserSettings: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/Settings',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/Settings',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  ratesNodes: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/RatesNodes',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/RatesNodes',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  gatherSelfAssignTickets: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/ClientID/{ClientID}/Tickets/GatherSelfAssignTickets',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/ClientID/{CLIENTID}/Tickets/GatherSelfAssignTickets',
        params: ['{CLIENTID}'],
      },
    },
  },
  savedSearchController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/Search',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/Search',
        params: ['{CLIENTID}'],
      },
    },
  },
  LSPController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/LSPController',
        params: ['{ClientID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/LSPController',
        params: ['{CLIENTID}'],
      },
    },
  },
  sendToLSPAction: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/SendToLSP',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Assignments/Primaries/SendToLSP',
        params: [],
      },
    },
  },
  appCheckIn: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/AppCheckIn',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/AppCheckIn',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  getUserCheckins: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Users/check-ins',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/Users/check-ins',
        params: [],
      },
    },
  },
  dispatchAreaController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/DispatchAreaController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/DispatchAreaController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  reportController: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/ReportController',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/ReportController',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  generateAdvancedReport: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/advancedreports/{UserID}/generatereport/{ReportID}',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/advancedreports/{USERID}/generatereport/{ReportID}',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  assignedTickets: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/AssignedTickets',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://bxz8wn0ur8.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/AssignedTickets',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  queryLocators: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/QueryLocators',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/QueryLocators',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  checkEmail: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u4/checkEmail/{email}',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u4/checkEmail/{email}',
        params: [],
      },
    },
  },
  gatherAccounts: {
    type: 'GET',
    versions: {
      0: {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u3/gatherAccounts/{token}',
        params: [],
      },
      '1.2.0.0': {
        url: 'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u3/gatherAccounts/{token}',
        params: [],
      },
    },
  },
  updateTicketRoutes: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/UpdateTicketRoutes',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/UpdateTicketRoutes',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  },
  validateDocumentManifest: {
    type: 'PUT',
    versions: {
      0: {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{ClientID}/{UserID}/ValidateDocumentManifest',
        params: ['{ClientID}', '{UserID}'],
      },
      '1.2.0.0': {
        url: 'https://r77xy1wtc5.execute-api.ca-central-1.amazonaws.com/{stage}/u2/{CLIENTID}/{USERID}/ValidateDocumentManifest',
        params: ['{CLIENTID}', '{USERID}'],
      },
    },
  }
};

// key/value pair of api urls
// values MUST MATCH api keys EXACTLY
export const apiKeys = {
  competers: {
    generateToken: 'generateToken',
    generateAuthTokenU4: 'generateAuthTokenU4',
    generateAuthTokenU2: 'generateAuthTokenU2',
    checkEmail: 'checkEmail',
    gatherAccounts: 'gatherAccounts',
  },
  u2: {
    getMapLayers: 'getMapLayers',
    mapCaptures: 'mapCaptures',
    drawingForms: 'drawingForms',
    getMapDrawing: 'getMapDrawing',
    queueEmails: 'queueEmails',
    createTicket: 'createTicket',
    uploadDocument: 'uploadDocument',
    refreshAdminTables: 'refreshAdminTables',
    addDigsiteToTicket: 'addDigsiteToTicket',
    gatherDigsites: 'gatherDigsites',
    retrieveDocumentsS3: 'retrieveDocumentsS3',
    documentToAngularController: 'documentToAngularController',
    getEditTables: 'getEditTables',
    modifyEditTables: 'modifyEditTables',
    openEditTables: 'openEditTables',
    schedulerEvent: 'schedulerEvent',
    schedulerResource: 'schedulerResource',
    schedulerSchedule: 'schedulerSchedule',
    schedulerTicket: 'schedulerTicket',
    gatherTicketLatLng: 'gatherTicketLatLng',
    createTicketController: 'createTicketController',
    downloadTicketU2: 'downloadTicketU2',
    downloadAllUserTickets: 'downloadAllUserTicketsU2',
    quickTicketActions: 'quickTicketActions',
    uploadTickets: 'uploadTickets',
    uploadTicket: 'uploadTicket',
    TicketProtection: 'TicketProtection',
    DocumentController: 'DocumentController',
    UtilocateDownloadTicketAll: 'UtilocateDownloadTicketAll',
    UtilocateDownloadTicketPrimary: 'UtilocateDownloadTicketPrimary',
    UtilocateDownloadTicketRequestNum: 'UtilocateDownloadTicketRequestNum',
    gatherTicketActions: 'gatherTicketActions',
    bulkTicketActions: 'bulkTicketActions',
    cancelTicketAction: 'cancelTicketAction',
    changeExcavationDate: 'changeExcavationDate',
    groupTickets: 'groupTickets',
    ungroupTickets: 'ungroupTickets',
    clearTicketBulk: 'clearTicketBulk',
    reassignTicketAction: 'reassignTicketAction',
    sendTwoWayMessage: 'sendTwoWayMessage',
    gatherExcavatorsForTicket: 'gatherExcavatorsForTicket',
    uncompleteTicketAction: 'uncompleteTicketAction',
    downloadLocatorTickets: 'downloadLocatorTickets',
    createTicketControllerFieldSide: 'createTicketControllerFieldSide',
    uploadTicketsFieldSide: 'uploadTicketsFieldSide',
    retrieveDocumentsS3FieldSide: 'retrieveDocumentsS3FieldSide',
    xmlTemplateController: 'xmlTemplateController',
    clearTicket: 'clearTicket',
    officeCompleteTicketAction: 'officeCompleteTicketAction',
    officeOngoingTicketAction: 'officeOngoingTicketAction',
    rouingPresetController: 'rouingPresetController',
    preparePDFGeneration: 'preparePDFGeneration',
    userManagerGetUsers: 'userManagerGetUsers',
    userManagerUpdateUser: 'userManagerUpdateUser',
    userManagerCreateUser: 'userManagerCreateUser',
    userManagerArchiveUsers: 'userManagerArchiveUsers',
    userManagerUnarchiveUsers: 'userManagerUnarchiveUsers',
    userManagerAssociateUser: 'userManagerAssociateUser',
    systemMaintenanceController: 'systemMaintenanceController',
    getUserSettings: 'getUserSettings',
    timesheetSync: 'timesheetSync',
    getTimesheetEntries: 'getTimesheetEntries',
    ratesNodes: 'ratesNodes',
    gatherSelfAssignTickets: 'gatherSelfAssignTickets',
    savedSearchController: 'savedSearchController',
    LSPController: 'LSPController',
    sendToLSPAction: 'sendToLSPAction',
    appCheckIn: 'appCheckIn',
    getUserCheckins: 'getUserCheckins',
    autoEngineController: 'autoEngineController',
    reportController: 'reportController',
    generateAdvancedReport: 'generateAdvancedReport',
    dispatchAreaController: 'dispatchAreaController',
    userCategoryController: 'userCategoryController',
    assignedTickets: 'assignedTickets',
    queryLocators: 'queryLocators',
    updateTicketRoutes: 'updateTicketRoutes',
    uploadDocNew: 'uploadDocNew',
    validateDocumentManifest: 'validateDocumentManifest'
  },
  uexcavate: {
    downloadTicket: 'downloadTicketUX',
  },
};

// any API key not listed here will be defaulted to "live"
export const apiStage = {
  liveV0api2: [
    'getMapLayers',
    'getMapDrawing',
    'mapCaptures',
    'drawingForms',
    'queueEmails',
    'createTicket',
    'uploadDocument',
    'refreshAdminTables',
    'addDigsiteToTicket',
    'gatherDigsites',
    'documentToAngularController',
    'getEditTables',
    'modifyEditTables',
    'openEditTables',
    'schedulerEvent',
    'schedulerResource',
    'schedulerSchedule',
    'schedulerTicket',
    'gatherTicketLatLng',
    'createTicketController',
    'gatherTicketActions',
    'bulkTicketActions',
    'changeExcavationDate',
    'groupTickets',
    'ungroupTickets',
    'clearTicketBulk',
    'cancelTicketAction',
    'reassignTicketAction',
    'sendTwoWayMessage',
    'gatherExcavatorsForTicket',
    'TicketProtection',
    'DocumentController',
    'UtilocateDownloadTicketAll',
    'UtilocateDownloadTicketPrimary',
    'uncompleteTicketAction',
    'downloadLocatorTickets',
    'createTicketControllerFieldSide',
    'uploadTicketsFieldSide',
    'generateAuthTokenU4',
    'xmlTemplateController',
    'clearTicket',
    'officeCompleteTicketAction',
    'officeOngoingTicketAction',
    'rouingPresetController',
    'preparePDFGeneration',
    'userManagerGetUsers',
    'userManagerUpdateUser',
    'userManagerCreateUser',
    'userManagerArchiveUsers',
    'userManagerUnarchiveUsers',
    'userManagerAssociateUser',
    'systemMaintenanceController',
    'getUserSettings',
    'timesheetSync',
    'getTimesheetEntries',
    'ratesNodes',
    'uploadTicket',
    'gatherSelfAssignTickets',
    'savedSearchController',
    'LSPController',
    'sendToLSPAction',
    'appCheckIn',
    'getUserCheckins',
    'autoEngineController',
    'retrieveDocumentsS3',
    'reportController',
    'generateAdvancedReport',
    'dispatchAreaController',
    'userCategoryController',
    'queryLocators',
    'assignedTickets',
    'checkEmail',
    'gatherAccounts',
    'updateTicketRoutes',
    'uploadDocNew',
    'validateDocumentManifest'
  ],

  V7Live: ['downloadTicketU2', 'quickTicketActions', 'uploadTickets', 'retrieveDocumentsS3FieldSide'],
};

// url that should be cached by Cache Interceptor
export const cacheableApi = [
  apiKeys.u2.refreshAdminTables,
  apiKeys.u2.downloadLocatorTickets,
  apiKeys.u2.UtilocateDownloadTicketAll,
  apiKeys.u2.UtilocateDownloadTicketPrimary,
];
