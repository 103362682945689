<div class="bg-white w-full h-full">
  <!-- Header Section -->
  <div class="bg-white p-6 flex flex-col min-w-[600px]" *ngIf="userCategory && accessLevel">
    <!-- title -->
    <div class="pb-4 font-rajdhani uppercase font-semibold text-headline-5">
      {{ userCategory.Title }}
    </div>
    <!-- Edit name and Description -->
    <div class="w-80 self-start flex flex-col items-end">
      <app-text-field-edit
        *ngIf="userCategory.Title"
        [text]="userCategory.Title"
        [required]="true"
        leadingText="Name"
        (textChange)="onTitleUpdate($event)"
        [readOnly]="true" />

      <app-text-field-edit
        *ngIf="userCategory.Description"
        [text]="userCategory.Description"
        [required]="true"
        leadingText="Description"
        (textChange)="onDescriptionUpdate($event)"
        [readOnly]="true" />
    </div>

    <!-- Settings List Component  -->
    <app-edit-settings-component
      [accessLevel]="accessLevel"
      [settingIDs]="settingsToShow"
      [(editState)]="editState"
      [menuOptions]="menuOptions"
      [adminSettings]="getAdminSettings()"
      (settingsChangeEvent)="onSettingChange($event)" />

    <!-- Save Button -->
    <button
      mat-flat-button
      [matTooltip]="editState ? 'Cannot save while adding settings' : null"
      [disabled]="loading"
      *ngIf="accessLevel !== ModifyType.View"
      class="self-end mb-6 fixed bottom-0"
      [ngClass]="{
        'btn-save ': !editState,
        'w-32 h-9 rounded-md opacity-75 text-white bg-gray-400 border-none font-rajdhani font-semibold uppercase text-md hover:cursor-not-allowed':
          editState
      }"
      color="primary"
      [matBadge]="pendingChanges ? '!' : null"
      matBadgeColor="red"
      (click)="editState ? null : saveCategory()">
      <div class="font-rajdhani uppercase font-semibold text-lg">Save</div>
    </button>
  </div>
</div>
