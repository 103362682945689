import { inject, Injectable } from '@angular/core';

import { DialogPosition, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { getAdminTablesFromUserCategoryID } from './locator-admin-tables';
import { SyncModalService, SyncProgressID, SyncProgressText } from '../notification/sync-modal.service';
import { BehaviorSubject, Observable, Subject, Subscriber } from 'rxjs';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { UtilocateAdminCacheService } from 'src/app/modules/core/cache/utilocate-admin.service';
import { UtilocateCompletionCacheService } from '../cache/utilocate-completions.service';
import { safeAwait } from 'src/app/modules/core/validators/validator';
import { COMPLETION_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import { AssignmentIDCacheResult, CacheWhereClauseType } from 'src/app/modules/core/cache/cache.interface';
import { TicketSyncModalComponent } from '../../modals/ticket-sync-modal/ticket-sync-modal.component';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { TicketDocumentsService } from '../../ticket-documents/ticket-documents.service';
import { AdminLookupService } from '../../../../core/admin/admin-lookup.service';

export function SyncStatus(SyncKey: string, data = null) {
  this.SyncKey = SyncKey;
  if (data) {
    this.data = data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TicketSyncService {
  // services
  private adminLookupService: AdminLookupService = inject(AdminLookupService);

  private syncModal: MatDialogRef<TicketSyncModalComponent>;
  private AssignmentIDsToUpload: string[];
  private assignedTicketList: string[] = [];
  numTicketsToSync: any;
  onSyncClick: Subject<any>;
  AssignmentID: any;
  title: string;
  key: any;

  modalPosition: DialogPosition;

  // observables
  private _isSyncing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private utilocateApiService: UtilocateApiService,
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private utilocateCompletionService: UtilocateCompletionCacheService,
    private syncModalService: SyncModalService,
    private dialog: MatDialog,
    private datetime$: DatetimeService,
    private ticketDocumentService: TicketDocumentsService
  ) {
    this.AssignmentIDsToUpload = [];
    this.AssignmentID = sessionStorage.getItem('AssignmentID');

    this.syncModal = null;
  }

  private openModal() {
    const config: MatDialogConfig = new MatDialogConfig();
    config.height = '560px';
    config.width = '650px';
    config.disableClose = true;
    config.position = this.modalPosition;

    this.syncModal = this.dialog.open(TicketSyncModalComponent, config);
  }

  closeModal() {
    this.syncModal.close();
  }

  setLoadingModalPosition(config: DialogPosition) {
    this.modalPosition = config;
  }

  /**
   * Set the list of AssignmentIDsToUpload to upload
   * @param assignmentIDs
   * @returns
   */
  async getAssignmentIDToUpload(assignmentIDs: string[] = []) {
    await this.setAssignmentIDToUpload(assignmentIDs);
    return this.AssignmentIDsToUpload;
  }

  /**
   * Sets AssignmentIDsToUpload to subset of the input assignmentIDs if their ticketChanged property is true
   * @param assignmentIDs
   * @returns
   */
  async setAssignmentIDToUpload(assignmentIDs: string[] = []) {
    const queryResult = [];
    // get all the assignmentIDs that have 'ticketChanged = true' from the idb
    for (const assignmentID of assignmentIDs) {
      const assignmentObject: AssignmentIDCacheResult =
        await this.utilocateCompletionService.queryAssignmentID(assignmentID);
      if (assignmentObject && assignmentObject.ticketChanged) {
        queryResult.push(assignmentID);
      }
    }
    this.AssignmentIDsToUpload = queryResult;
    return this.AssignmentIDsToUpload;
  }

  /**
   * Removes these assignmentIDs from the local cache
   * @param assignmentIDs
   * @returns
   */
  async removeLocalTicket(assignmentIDs: string[]) {
    return this.utilocateCompletionService.clearKeys(assignmentIDs);
  }

  async clearTicketChanged(assignmentIDs: string[]) {
    // get all the assignmentIDs that have 'ticketChanged = true' from the idb
    for (const assignmentID of assignmentIDs) {
      const assignmentObject: AssignmentIDCacheResult = await this.utilocateCompletionService.queryAssignmentID(
        assignmentID.toString()
      );
      if (assignmentObject) {
        assignmentObject.ticketChanged = false;
        await this.utilocateCompletionService.setAssignmentID(assignmentID.toString(), assignmentObject);
      }
    }
  }

  /**
   * Gets the list of assignmentIDs assigned to the user
   */
  async getAssignedTickets(): Promise<string[]> {
    const list = await this.utilocateApiService.getAssignedTickets(this.userService.getUserID());
    if (list) {
      // set this.assignedTicketList to the assignmentIDs inside list
      this.assignedTicketList = list.map((x) => x.AssignmentID.toString());
      //filter out duplicates
      this.assignedTicketList = this.removeDuplicates(this.assignedTicketList);
    }
    return this.assignedTicketList;
  }

  /**
   * Downloads a ticket by its primaryID
   * @param assignmentID
   * @param primaryID
   * @returns
   */
  async downloadTicketByPrimaryID(assignmentID: string, primaryID: string) {
    return await this.utilocateApiService.downloadTicketByPrimaryID(assignmentID, primaryID);
  }

  /**
   * Returns a list of local tickets that are assigned to the user, seperate from server ticket list
   * @returns
   */
  async getLocalAssignedTickets(): Promise<{ assignmentID: string; hasChanged: boolean }[]> {
    const keys = await this.utilocateCompletionService.listKeys();
    const locallyAssignedTickets = [];
    for (const key of keys) {
      const assignmentObject: AssignmentIDCacheResult = await this.utilocateCompletionService.queryAssignmentID(key);
      if (assignmentObject && assignmentObject.assigned) {
        locallyAssignedTickets.push({ assignmentID: key.toString(), hasChanged: assignmentObject.ticketChanged });
      }
    }
    return locallyAssignedTickets;
  }

  removeDuplicates(arr: (string | number)[]): string[] {
    // Convert all values to strings and create a Set to ensure uniqueness
    const uniqueSet = new Set(arr.map((value) => String(value)));

    // Convert Set back to array
    return Array.from(uniqueSet);
  }

  startSync(
    performUpload: boolean,
    performDownload: boolean,
    assignmentIDs: string[] = [],
    checkAssignedTicketsForDownload: boolean = false,
    primaryIDs: string[] = [],
    showSyncModal: boolean = true
  ): Observable<{ syncKey: any; result: any }> {
    //remove duplicates from the assignmentIDs list
    assignmentIDs = this.removeDuplicates(assignmentIDs);



    // console.log(`startSync| Upload: ${performUpload}, Download: ${performDownload}, AssignmentIDs: ${assignmentIDs}, Recalculate AssIDS: ${checkAssignedTicketsForDownload}`);

    this._isSyncing$.next(true);
    return new Observable((subscriber) => {
      if (showSyncModal) {
        this.openModal();
      }
      this.setAssignmentIDToUpload(assignmentIDs).then(() => {
        let uploadedTickets: string[] = [];
        if (performUpload) {
          this.upload(assignmentIDs).subscribe({
            next: (succeededAssignmentIDs: string[]) => {
              uploadedTickets = succeededAssignmentIDs;
            },
            error: () => {},
            complete: async () => {
              await this.uploadGPSAppCheckIn();
              await this.clearTicketChanged(assignmentIDs);
              this.AssignmentIDsToUpload = [];
              this.numTicketsToSync = 0;
              if (performDownload) {
                const serverAssignedTickets = await this.getAssignedTickets();
                const localAssignedTickets = await this.getLocalAssignedTickets();

                //get the tickets that exist in localAssignedTickets but not serverAssignedTickets
                const additionalTicketsToRemove = localAssignedTickets.filter(
                  (tick) => !serverAssignedTickets.includes(tick.assignmentID)
                );
                additionalTicketsToRemove.forEach((x) => {
                  uploadedTickets.push(x.assignmentID.toString());
                });
                await this.removeLocalTicket(uploadedTickets); //clear only the tickets that were uploaded
                await this.utilocateAdminCacheService.clear(); //clear admin tables

                //get what tickets we need to download
                let assignmentIDsToDownload = assignmentIDs;
                if (checkAssignedTicketsForDownload) {
                  assignmentIDsToDownload = serverAssignedTickets;
                }

                // if hasChanged is true, remove the assignmentID from the assignmentIDsToDownload list
                // This is because we don't want to download a ticket that has changed locally - The user will
                // need to upload this before they can download it again
                localAssignedTickets.forEach((ticket) => {
                  if (ticket.hasChanged) {
                    assignmentIDsToDownload = assignmentIDsToDownload.filter((x) => x !== ticket.assignmentID);
                  }
                });

                //get the failed tickets
                const failedTickets = assignmentIDs.filter((x) => !uploadedTickets.includes(x));

                //download only tickets that didn't fail
                assignmentIDsToDownload = assignmentIDsToDownload.filter((x) => !failedTickets.includes(x));
                this.callDownload(assignmentIDsToDownload, subscriber, primaryIDs);
              } else {
                subscriber.complete();
              }
              this._isSyncing$.next(false);
            },
          });
        } else if (performDownload) {
          this.AssignmentIDsToUpload = [];
          if (checkAssignedTicketsForDownload) {
            this.getAssignedTickets().then((newAssignmentIDs) => {
              this.callDownload(newAssignmentIDs, subscriber);
            });
          } else {
            this.callDownload(assignmentIDs, subscriber, primaryIDs);
          }
        }
      });
    });
  }

  /**
   * Calls this.download and updates the subscriber
   * @param assignmentIDs
   * @param subscriber
   */
  callDownload(
    assignmentIDs: string[],
    subscriber: Subscriber<{
      syncKey: any;
      result: any;
    }>,
    primaryIDs: string[] = []
  ) {
    this.download(assignmentIDs, primaryIDs).subscribe({
      next: (downloadNextVal) => {
        if (downloadNextVal.syncKey == SyncProgressText.DOWNLOAD_TICKETS) {
          subscriber.next(downloadNextVal);

          subscriber.complete();
        }
      },
      error: (downloadError) => {
        subscriber.error(downloadError);
        subscriber.complete();
      },

      complete: () => {
        this.ticketDocumentService.setreloadTab(true);
        this.ticketDocumentService.restartQueue();
      },
    });
  }

  resume() {
    return new Observable((subscriber) => {
      (async () => {
        const [tbCompletions_Assignments, error] = await safeAwait(
          this.utilocateCompletionService.queryTable(COMPLETION_TABLE_NAMES.tbCompletions_Assignments)
        );
        const [tbCompletions_Documents, error1] = await safeAwait(
          this.utilocateCompletionService.queryTable(COMPLETION_TABLE_NAMES.tbCompletions_Documents)
        );

        const [tbCompletions_S3Documents] = await safeAwait(
          this.utilocateCompletionService.queryTable(COMPLETION_TABLE_NAMES.tbCompletions_S3Documents)
        );

        if (!error && !error1) {
          subscriber.next({
            tbCompletions_Assignments: { Data: tbCompletions_Assignments },
            tbCompletions_Documents: { Data: tbCompletions_Documents },
            tbCompletions_S3Documents: { Data: tbCompletions_S3Documents },
          });
        } else {
          subscriber.error();
        }
        subscriber.complete();
      })();
    });
  }

  /**
   * Refreshes the admin tables for the user
   * @returns
   */
  async getAdminTables() {
    const UserCategoryID = this.userService.getUserCategoryID();
    const adminTablesToDownload: string[] = getAdminTablesFromUserCategoryID(UserCategoryID);
    return await this.adminLookupService.getAdminTables(adminTablesToDownload);
  }

  download(assignmentIDs: string[] = [], primaryIDs: string[] = []): Observable<{ syncKey: any; result: any }> {
    const primaryIDMap = {};
    const downloadByPrimaryIDs = assignmentIDs.length === primaryIDs.length && primaryIDs.length > 0;
    if (downloadByPrimaryIDs) {
      //map primaryIDs so we can index it with assignmentIDs. Assume they are ordered as they should be
      for (let i = 0; i < primaryIDs.length; i++) {
        primaryIDMap[assignmentIDs[i]] = primaryIDs[i];
      }
    }
    return new Observable((subscriber) => {
      (async () => {
        this.syncModalService.updateSyncDownload(
          SyncProgressText.DOWNLOAD_ADMIN_TABLES,
          SyncProgressID.LOADING,
          SyncProgressText.DOWNLOAD_ADMIN_TABLES
        );

        const adminTableResult = await this.getAdminTables();

        if (adminTableResult) {
          subscriber.next({
            syncKey: SyncProgressText.DOWNLOAD_ADMIN_TABLES,
            result: adminTableResult,
          });
          this.syncModalService.updateSyncDownload(
            SyncProgressText.DOWNLOAD_ADMIN_TABLES,
            SyncProgressID.DONE,
            SyncProgressText.DOWNLOAD_ADMIN_TABLES
          );

          this.syncModalService.updateSyncDownload(
            SyncProgressText.DOWNLOAD_TICKETS,
            SyncProgressID.LOADING,
            SyncProgressText.DOWNLOAD_TICKETS + ` (0 of ${assignmentIDs.length})`
          );

          const downloadTicketErrArr = [];
          let downloadTicketSuccessCount = 0;
          const downloadPromises = assignmentIDs.map(async (assignmentID) => {
            try {
              let ticket = null;
              if (downloadByPrimaryIDs) {
                console.log(`downloading primaryID ${primaryIDMap[parseInt(assignmentID)]} for assID ${assignmentID}`);
                ticket = await this.utilocateApiService.downloadTicketByPrimaryID(
                  assignmentID,
                  primaryIDMap[parseInt(assignmentID)]
                );
              } else {
                ticket = await this.utilocateApiService.downloadTicketByAssignmentID(
                  assignmentID,
                  this.assignedTicketList.includes(assignmentID)
                );
              }
              if (ticket) {
                downloadTicketSuccessCount++;
                this.syncModalService.updateSyncDownload(
                  SyncProgressText.DOWNLOAD_TICKETS,
                  SyncProgressID.LOADING,
                  SyncProgressText.DOWNLOAD_TICKETS + ` (${downloadTicketSuccessCount} of ${assignmentIDs.length})`
                );
              } else {
                downloadTicketErrArr.push(`${assignmentID} ERROR: Unknown Error`);
                this.syncModalService.updateSyncDownload(
                  SyncProgressText.DOWNLOAD_TICKETS_ERROR,
                  SyncProgressID.ERROR,
                  SyncProgressText.DOWNLOAD_TICKETS_ERROR + `${downloadTicketErrArr.join('\n')}`
                );
              }
            } catch (error) {
              downloadTicketErrArr.push(`${assignmentID} ERROR: ${error.message}`);
              this.syncModalService.updateSyncDownload(
                SyncProgressText.DOWNLOAD_TICKETS_ERROR,
                SyncProgressID.ERROR,
                SyncProgressText.DOWNLOAD_TICKETS_ERROR + `${downloadTicketErrArr.join('\n')}`
              );
            }
          });

          // Wait for all download promises to resolve or reject
          const tickets = await Promise.all(downloadPromises);
          this.syncModalService.updateSyncDownload(
            SyncProgressText.DOWNLOAD_TICKETS,
            SyncProgressID.DONE,
            SyncProgressText.DOWNLOAD_TICKETS
          );
          subscriber.next({
            syncKey: SyncProgressText.DOWNLOAD_TICKETS,
            result: tickets,
          });
          subscriber.complete();
        } else {
          subscriber.error({
            syncKey: SyncProgressText.DOWNLOAD_ADMIN_TABLES,
            result: adminTableResult,
          });
          this.syncModalService.updateSyncDownload(
            SyncProgressText.DOWNLOAD_ADMIN_TABLES,
            SyncProgressID.ERROR,
            SyncProgressText.DOWNLOAD_ADMIN_TABLES
          );
          subscriber.complete();
        }
      })();
    });
  }

  upload(assignmentIDs: string[] = []) {
    //check to make sure the ticket is downloaded
    return new Observable((subscriber) => {
      (async () => {
        this.syncModalService.updateSyncUpload(
          SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS,
          SyncProgressID.LOADING,
          SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS
        );

        const UserCategoryID = this.userService.getUserCategoryID();
        const UserID = this.userService.getUserID();
        if (UserCategoryID == UserCategoryID) {
          if (assignmentIDs) {
            this.syncModalService.updateSyncUpload(
              SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS,
              SyncProgressID.DONE,
              SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS
            );

            const uploadPromiseArr = [];
            this.syncModalService.updateSyncUpload(
              SyncProgressText.UPLOAD_GATHER_UPLOAD_TICKETS,
              SyncProgressID.LOADING,
              SyncProgressText.UPLOAD_GATHER_UPLOAD_TICKETS
            );

            // this.syncModalService.updateSyncUpload(
            //   SyncProgressText.UPLOAD_DOCUMENTS,
            //   SyncProgressID.LOADING,
            //   SyncProgressText.UPLOAD_DOCUMENTS
            // );

            //prepares both doc databases for upload (S3 and tbCompletions_Documents)
            const documentsToUpload = [];

            const uploadEachDocument = new Observable((subscriber) => {
              const completed = 0;

              if (documentsToUpload.length == 0) {
                this.syncModalService.updateSyncUpload(
                  SyncProgressText.UPLOAD_DOCUMENTS,
                  SyncProgressID.DONE,
                  SyncProgressText.UPLOAD_DOCUMENTS + ' (' + completed + '/' + documentsToUpload.length + ')'
                );
                subscriber.complete();
              }

            });

            uploadEachDocument.subscribe({
              complete: () => {
                (async () => {
                  //After each document is completed, then upload tickets
                  for (let i = 0; i < assignmentIDs.length; i++) {
                    const assignmentObjectFromIDB = await this.utilocateCompletionService.queryAssignmentID(
                      assignmentIDs[i]
                    );
                    if (assignmentObjectFromIDB != null) {
                      const locatorTables = await this.getSingleLocatorTicket(
                        assignmentIDs[i],
                        assignmentObjectFromIDB
                      );

                      uploadPromiseArr.push(this.utilocateApiService.uploadTickets(locatorTables));
                    }
                  }
                  this.syncModalService.updateSyncUpload(
                    SyncProgressText.UPLOAD_GATHER_UPLOAD_TICKETS,
                    SyncProgressID.DONE,
                    SyncProgressText.UPLOAD_GATHER_UPLOAD_TICKETS
                  );

                  this.syncModalService.updateSyncUpload(
                    SyncProgressText.UPLOAD_API,
                    SyncProgressID.LOADING,
                    SyncProgressText.UPLOAD_API + ' (0/' + uploadPromiseArr.length + ')'
                  );
                  const uploadEachTicket = new Observable((subscriber) => {
                    let completed = 0;

                    if (uploadPromiseArr.length == 0) {
                      this.syncModalService.updateSyncUpload(
                        SyncProgressText.UPLOAD_API,
                        SyncProgressID.DONE,
                        SyncProgressText.UPLOAD_API + ' (' + completed + '/' + uploadPromiseArr.length + ')'
                      );
                      subscriber.complete();
                    }
                    const erroredTickets = [];
                    const successAssignmentIDs = [];
                    for (let i = 0; i < uploadPromiseArr.length; i++) {
                      uploadPromiseArr[i]
                        .then((result) => {
                          if (result && result.error && result.error.message) {
                            throw new Error(result.error.message);
                          }
                          ++completed;

                          const parsedValue = JSON.parse(result.body.value);
                          this.key = Object.keys(parsedValue);
                          successAssignmentIDs.push(this.key.toString());
                          this.syncModalService.updateSyncUpload(
                            SyncProgressText.UPLOAD_API,
                            SyncProgressID.LOADING,
                            SyncProgressText.UPLOAD_API +
                              ' (' +
                              completed +
                              '/' +
                              uploadPromiseArr.length +
                              ') ID (' +
                              this.key +
                              ')'
                          );

                          if (completed + erroredTickets.length == uploadPromiseArr.length) {
                            this.syncModalService.updateSyncUpload(
                              SyncProgressText.UPLOAD_API,
                              SyncProgressID.DONE,
                              SyncProgressText.UPLOAD_API +
                                ' (' +
                                completed +
                                '/' +
                                uploadPromiseArr.length +
                                ') ID (' +
                                this.key +
                                ')'
                            );
                            subscriber.next(successAssignmentIDs);
                            subscriber.complete();
                          }
                        })
                        .catch((error) => {
                          erroredTickets.push(error.message);
                          this.syncModalService.updateSyncUpload(
                            SyncProgressText.UPLOAD_ERROR,
                            SyncProgressID.ERROR,
                            SyncProgressText.UPLOAD_ERROR + ` ${erroredTickets.length} Tickets`
                          );
                          if (completed + erroredTickets.length == uploadPromiseArr.length) {
                            this.syncModalService.updateSyncUpload(
                              SyncProgressText.UPLOAD_API,
                              SyncProgressID.DONE,
                              SyncProgressText.UPLOAD_API +
                                ' (' +
                                completed +
                                '/' +
                                uploadPromiseArr.length +
                                ') ID (' +
                                this.key +
                                ')'
                            );
                            subscriber.next(successAssignmentIDs);
                            subscriber.complete();
                          }
                        });
                    }
                  });

                  uploadEachTicket.subscribe({
                    next: (assignmentIDs) => {
                      subscriber.next(assignmentIDs);
                      subscriber.complete();
                    },
                    complete: () => {
                      subscriber.complete();
                    },
                  });
                })();
              },
            });
          } else {
            this.syncModalService.updateSyncUpload(
              SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS,
              SyncProgressID.ERROR,
              SyncProgressText.UPLOAD_GATHER_LOCAL_TICKETS
            );
            subscriber.error(assignmentIDs);
            subscriber.complete();
          }
        }
      })();
    });
  }

  private async getSingleLocatorTicket(AssignmentID: string, assignmentObject: AssignmentIDCacheResult) {
    const tablesToUpload: Record<string, any> = {};

    tablesToUpload.tbCompletions_AssignmentPolygons = assignmentObject.getTable('tbCompletions_AssignmentPolygons');
    tablesToUpload.tbCompletions_Assignments = assignmentObject.getTable('tbCompletions_Assignments');
    tablesToUpload.tbCompletions_Primary = assignmentObject.getTable('tbCompletions_Primary');
    tablesToUpload.tbCompletions_Autolog = assignmentObject.getTable('tbCompletions_Autolog');
    tablesToUpload.tbSync_ExpectedDocumentHash = assignmentObject.getTable('tbSync_ExpectedDocumentHash');

    const error =
      tablesToUpload.tbCompletions_Assignments === null ||
      tablesToUpload.tbCompletions_Assignments.Data.length === 0 ||
      tablesToUpload.tbCompletions_Autolog === null ||
      tablesToUpload.tbCompletions_Autolog.Data.length === 0 ||
      tablesToUpload.tbCompletions_Primary === null ||
      tablesToUpload.tbCompletions_Primary.Data.length === 0 ||
      tablesToUpload.tbSync_ExpectedDocumentHash === null ||
      // tablesToUpload.tbSync_ExpectedDocumentHash.Data.length === 0 ||
      tablesToUpload.tbCompletions_AssignmentPolygons === null ||
      tablesToUpload.tbCompletions_AssignmentPolygons.Data.length === 0;

    if (!error) {
      const { PrimaryID } = tablesToUpload.tbCompletions_Primary.Data[0];
      const [tbCompletions_PrimaryDetails] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_PrimaryDetails,
          [
            {
              Column: 'PrimaryID',
              Value: PrimaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_CommonDetails] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_CommonDetails,
          [
            {
              Column: 'PrimaryID',
              Value: PrimaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_AuxiliaryDetails, error5] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_AuxiliaryDetails,
          [
            {
              Column: 'PrimaryID',
              Value: PrimaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_PreCompletionCategories] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_PreCompletionCategories,
          [
            {
              Column: 'PrimaryID',
              Value: PrimaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_PreCompletionDetails] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_PreCompletionDetails,
          [
            {
              Column: 'PrimaryID',
              Value: PrimaryID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_ExcavationDateLog] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_ExcavationDateLog,
          [
            {
              Column: 'AssignmentID',
              Value: AssignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      const [tbCompletions_AssignmentToTags] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_AssignmentToTags,
          [
            {
              Column: 'AssignmentID',
              Value: AssignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );

      tablesToUpload.tbCompletions_PrimaryDetails = tbCompletions_PrimaryDetails;
      tablesToUpload.tbCompletions_CommonDetails = tbCompletions_CommonDetails;
      tablesToUpload.tbCompletions_PreCompletionCategories = tbCompletions_PreCompletionCategories;
      tablesToUpload.tbCompletions_PreCompletionDetails = tbCompletions_PreCompletionDetails;
      tablesToUpload.tbCompletions_ExcavationDateLog = tbCompletions_ExcavationDateLog;
      tablesToUpload.tbCompletions_AssignmentToTags = tbCompletions_AssignmentToTags;

      if (!error5) {
        const AuxIDs = tbCompletions_AuxiliaryDetails.Data.reduce((total, value) => {
          if (total.indexOf(value.AuxiliaryDetailID) == -1) {
            total.push(value.AuxiliaryDetailID);
          }
          return total;
        }, []);
        const [tbCompletions_Billing] = await safeAwait(
          this.utilocateCompletionService.queryTable(
            COMPLETION_TABLE_NAMES.tbCompletions_Billing,
            [
              {
                Column: 'AuxiliaryDetailID',
                Value: AuxIDs,
                ValueType: CacheWhereClauseType.ARRAY,
              },
            ],
            true,
            AssignmentID
          )
        );

        tablesToUpload.tbCompletions_Billing = tbCompletions_Billing;
        tablesToUpload.tbCompletions_AuxiliaryDetails = tbCompletions_AuxiliaryDetails;
      } else {
        throw error5;
      }

      const [tbCompletions_Documents] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_Documents,
          [
            {
              Column: 'AssignmentID',
              Value: AssignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      tablesToUpload.tbCompletions_Documents = {
        ...tbCompletions_Documents,
        Data: [],
      };
      //s3 documents
      const [tbCompletions_S3Documents] = await safeAwait(
        this.utilocateCompletionService.queryTable(
          COMPLETION_TABLE_NAMES.tbCompletions_S3Documents,
          [
            {
              Column: 'AssignmentID',
              Value: AssignmentID,
              ValueType: CacheWhereClauseType.NUMBER,
            },
          ],
          true,
          AssignmentID
        )
      );
      tablesToUpload.tbCompletions_S3Documents = {
        ...tbCompletions_S3Documents,
        Data: [],
      };
    } else {
      throw new Error(`Warning: One table about to upload is null or has no data`);
    }

    return tablesToUpload;
  }

  async uploadGPSAppCheckIn() {
    try {
      const date = this.datetime$.localDateToDBDateStr(new Date());
      if (navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const body = {
              Latitude: position.coords.latitude,
              Longitude: position.coords.longitude,
              CreatedDate: date,
            };
            this.utilocateApiService.callAppCheckIn(body);
          },
          (error) => {
            console.log(error);
          },
          { timeout: 3000 }
        );
      }
    } catch (error) {
      // throw new Error(`uploadGPSAppCheckIn: ${error}`)
      console.error(error.message);
    }
  }

  get isSyncing$(): Observable<boolean> {
    return this._isSyncing$.pipe();
  }

  get isSyncing(): boolean {
    return this._isSyncing$.value;
  }
}
