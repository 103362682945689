[
  {
    "SettingID": "160",
    "Type": "select",
    "Default": 1
  },
  {
    "SettingID": "163",
    "Type": "slider",
    "Max": 46,
    "Default": 8,
    "Step": 2
  },
  {
    "SettingID": "164",
    "Type": "slider",
    "Max": 46,
    "Default": 8,
    "Step": 1
  },
  {
    "SettingID": "166",
    "Type": "select",
    "Default": [
      2,3,4,5,6,7,8
    ],
    "Multiselect": true,
    "Options": [
      {
        "value": 2,
        "name": "Priority 0"
      },
      {
        "value": 3,
        "name": "Priority 1"
      },
      {
        "value": 4,
        "name": "Priority 2"
      },
      {
        "value": 5,
        "name": "Priority 3"
      },
      {
        "value": 6,
        "name": "Priority 4"
      },
      {
        "value": 7,
        "name": "Standard"
      },
      {
        "value": 8,
        "name": "Project Work"
      }
    ]
  },
  {
    "SettingID": "248",
    "Type": "select",
    "Default": [
      1
    ],
    "Options": [
      {
        "value": 1,
        "name": "Exclude Prompt in U2"
      },
      {
        "value": 0,
        "name": "Include Prompt in U2"
      }
    ]
  },
  {
    "SettingID": "266",
    "Type": "select",
    "Default": [
      0
    ],
    "Options": [
      {
        "value": 0,
        "name": "ESRI Aux Screenshot Only"
      },
      {
        "value": 1,
        "name": "ESRI Screenshot and Aux Screenshot"
      }
    ]
  },
  {
    "SettingID": "269",
    "Type": "select",
    "Multiselect": true,
    "Default": [
      1,3
    ],
    "Options": [
      {
        "value": 1,
        "name": "Email"
      },
      {
        "value": 3,
        "name": "Positive Response"
      }
    ]
  },
  {
    "SettingID": "292",
    "Type": "select",
    "Default": [
      1
    ],
    "Options": [
      {
        "value": 1,
        "name": "Full Access"
      },
      {
        "value": 4,
        "name": "View Only"
      }
    ]
  },
  {
    "SettingID": "304",
    "Type": "select",
    "Default": 1,
    "Options": [
      {
        "value": 1,
        "name": "Full Access"
      },
      {
        "value": 4,
        "name": "View Only"
      }
    ]
  },
  {
    "SettingID": "306",
    "Type": "select",
    "Default": [1],
    "Options": [
      {
        "value": 1,
        "name": "Full Access"
      },
      {
        "value": 4,
        "name": "View Only"
      }
    ]
  },
  {
    "SettingID": "309",
    "Type": "select",
    "Multiselect": true,
    "Default": [
      4
    ],
    "Options": [
      {
        "value": 4,
        "name": "Internal Documents"
      },
      {
        "value": 6,
        "name": "Photos"
      },
      {
        "value": 10,
        "name": "Office Internal Documents"
      },
      {
        "value": 106,
        "name": "Auxiliary Images"
      }
    ]
  }
]