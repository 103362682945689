import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { ComponentMessage, MessageAction } from 'src/app/modules/core/component-messaging/component-message';
import { ComponentMessagingService } from 'src/app/modules/core/component-messaging/component-messaging.service';
import { zipFile } from 'src/app/modules/core/services/document/zip-helper';
import { LocaleService } from 'src/app/modules/core/services/locale/locale.service';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { ProgressBarService } from 'src/app/modules/shared/progress-bar/progress-bar.service';
import { SnackbarService } from 'src/app/modules/shared/snackbar/snackbar.service';
import { SnackbarType } from 'src/app/modules/shared/snackbar/snackbar/snackbar';
import { PadOptions } from '../../../signature-pad/padOptions';
import { SignaturePadComponent } from '../../../signature-pad/signature-pad.component';
import { dataURLToFile } from '../../../../../shared/functions/image';

@Component({
  selector: 'app-signature-view',
  templateUrl: './signature-view.component.html',
  styleUrls: ['./signature-view.component.scss'],
})
export class SignatureViewComponent implements OnInit, AfterViewInit {
  className: string = 'SignatureViewComponent';
  @ViewChild('signaturePad') signaturePadComponent: SignaturePadComponent;
  @ViewChild('sigBox') formElement: ElementRef;
  @Input() props: any;

  documentName: string = null;

  signaturePadOptions: PadOptions = {
    minWidth: 2,
    backgroundColor: 'rgba(255,255,255,1)',
    penColor: 'rgba(0,0,0,1)',
  };
  childProps;
  form: FormGroup;
  locale: any;

  constructor(
    // error$: MainErrorService,
    private loggerService: LoggerService,
    // alert$: NotificationService,
    private breakpoint$: BreakpointObserver,
    private messaging$: ComponentMessagingService,
    private formBuilder: FormBuilder,
    private locale$: LocaleService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService
  ) {
    this.childProps = {
      messageService: messaging$,
      name: 'signatureViewComponent',
    };
  }

  /**
   * when receiving a message from sigpad, update size using breakpoints
   * @param nextMsg received message
   */
  onSigPadMessage(nextMsg) {
    //get breakpoint
    //set wh
    const width = this.formElement?.nativeElement.offsetWidth - 10;
  }

  /**
   * setup subscriptions to locale and messaging,
   * setup filename,
   * send ready message,
   * call init functions for breakpoints and forms
   */
  ngOnInit(): void {
    //setup locale
    this.locale$.getLocaleSubscription().subscribe((newLocale) => {
      if (newLocale.locale && newLocale.locale[this.className]) {
        this.locale = newLocale.locale[this.className];
      } else {
        //if reach then locale files setup incorrectly.
        this.loggerService.log('Component language not supported');
      }
    });

    //setup filename
    const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);
    this.documentName = aid + '_sig.png';

    this.props.messageService.getMessageStream().subscribe((nextMsg) => {
      this.onReceivedMessage(nextMsg);
    });

    this.messaging$.getMessageStream().subscribe((nextMsg) => {
      if (nextMsg.action == MessageAction.READY && nextMsg.senderID == 'SignaturePadComponent') {
        this.onSigPadMessage(nextMsg);
      }
    });

    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { sigViewReady: true },
      senderID: this.className,
    };
    this.props.messageService.sendToMessageStream(message);

    this.initForms();
  }

  ngAfterViewInit(): void {
    this.initBreakpoints();
  }

  /**
   * setup form fields
   */
  initForms() {
    this.form = this.formBuilder.group(
      {
        FirstName: ['', [Validators.required, Validators.maxLength(45)]], //new FormControl('', [Validators.required, Validators.email])
        LastName: ['', [Validators.required, Validators.maxLength(45)]],
        PhoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      },
      {}
    );
  }

  /**
   * subscribe to breakpoints to keep sig pad size reactive
   */
  initBreakpoints() {
    this.breakpoint$.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.resizeSigPad();
      }
    });

    this.breakpoint$.observe([Breakpoints.Medium]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.resizeSigPad();
      }
    });
    this.breakpoint$.observe([Breakpoints.Small]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.resizeSigPad();
      }
    });
    this.breakpoint$.observe([Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.resizeSigPad();
      }
    });
  }

  /**
   * parse message and if pdfNameReady and filename, update documentName variable
   * @param compMessage received message
   */
  onReceivedMessage(compMessage: ComponentMessage) {
    if (compMessage && compMessage.message) {
      if (compMessage.message.pdfNameReady && compMessage.message.filename) {
        if (compMessage.message.filename) {
          const parsedName = compMessage.message.filename.split('.');
          this.documentName = parsedName[0] + '_sig.png';
        }
      }
    }
  }

  /**
   * clear the signature pad image
   */
  onClickClear() {
    this.signaturePadComponent.clearPad();
  }

  /**
   * get data from signature pad, zip the file and send a message to upload it
   */
  onClickAccept() {
    this.progressBarService.start();

    // check that the signature pad isn't empty
    if (this.form.valid) {
      const formValues = this.form.getRawValue();

      if (this.signaturePadComponent.getRawData().length > 0) {
        const url = this.signaturePadComponent.getDataURL('png');
        const blob = dataURLToFile(url, 'blob');

        zipFile(this.documentName, blob).then((zip) => {
          const message: ComponentMessage = {
            action: MessageAction.READY,
            message: {
              signed: true,
              fileName: this.documentName,
              file: zip,
              formValues: formValues,
            },
            senderID: 'SignatureViewComponent',
          };
          this.props.messageService.sendToMessageStream(message);
        });
      } else {
        this.progressBarService.stop();
        let errorMsg = 'Signature Required';
        if (this.locale && this.locale.signatureMissingResponse) {
          errorMsg = this.locale.signatureMissingResponse;
        }
        this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
      }
    } else {
      this.form.markAllAsTouched();
      this.progressBarService.stop();
    }
  }

  /**
   * send ReadAgain message to stream
   */
  onClickReadAgain() {
    const message: ComponentMessage = {
      action: MessageAction.READY,
      message: { readAgain: true },
      senderID: 'SignatureViewComponent',
    };

    this.props.messageService.sendToMessageStream(message);
  }

  /**
   * update dimensions of signature pad
   * @param w width
   * @param h height
   */
  resizeSigPad() {
    if (this.signaturePadComponent) {
      this.signaturePadComponent.resizeCanvas();
    }
  }

  /**
   * was commented out in OLD, doesn't actually do anything as is
   */
  getErrorMessage() {
    // if ( this.form.controls.firstName && this.form.controls.firstName.invalid) {
    // }
    // if (this.form) {
    // return "Field must have a value";
    // }
    // return '';
  }
}
