import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger/logger.service';
import localforage from 'localforage';
import { ErrorHandlingService } from '../services/error-handling/error-handling.service';

//Index of store names
export enum StoreType {
  ADMIN = 0,
  COMPLETIONS = 1,
  SCHEDULER_RESOURCE = 2,
  SCHEDULER_EVENT = 3,
  SCHEDULER_SCHEDULER = 4,
  SCHEDULER_TICKET = 5,
  DOCUMENTS = 6,
  TICKET_CHANGED = 7,
  DOCUMENT = 8,
  S3DOCUMENTS = 9,
  PUNCH_CLOCK = 10,
  DIG_SITE = 11,
  FORM_TEMPLATES = 12,
  DOCUMENT_QUEUE = 13
}

//Array with store instance names
export const StoreNameByType = [
  'lookupStore',
  'completionStore',
  'schedulerResourceStore',
  'schedulerEventStore',
  'schedulerScheduleStore',
  'schedulerTicketStore',
  'documentsStore',
  'ticketChanged',
  'documents',
  'S3Documents',
  'punchClock',
  'digSiteStore',
  'formTemplates',
  'documentQueue'
];

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  lookupStore: LocalForage; // instance of lookup db
  completionStore: LocalForage; // instance of completions lookup db
  documentsStore: LocalForage; // instance of documents lookup db
  schedulerResourceStore: LocalForage; //instance of scheduler lookup db
  schedulerEventStore: LocalForage; //instance of scheduler lookup db
  schedulerScheduleStore: LocalForage; //instance of scheduler lookup db
  schedulerTicketStore: LocalForage; //instance of scheduler lookup db
  documents: LocalForage;
  S3Documents: LocalForage;
  punchClock: LocalForage;
  digSiteStore: LocalForage;
  formTemplates: LocalForage;
  documentQueue: LocalForage;
  priorityDocumentQueue: LocalForage;

  private cacheNames = new Set([...StoreNameByType]);

  //public mainError$: MainErrorService,
  constructor(
    public loggerService: LoggerService,
    private errorHandler: ErrorHandlingService
  ) {
    const SCHEDULE_DBNAME = 'scheduler';

    // create instance of localforage
    this.lookupStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'admin',
      storeName: 'lookup',
    });

    this.completionStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'completions',
      storeName: 'assignments',
    });

    this.documentsStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'completions',
      storeName: 'documents',
    });

    this.schedulerResourceStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: SCHEDULE_DBNAME,
      storeName: 'resource',
    });

    this.schedulerEventStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: SCHEDULE_DBNAME,
      storeName: 'event',
    });

    this.schedulerScheduleStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: SCHEDULE_DBNAME,
      storeName: 'schedule',
    });

    this.schedulerTicketStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: SCHEDULE_DBNAME,
      storeName: 'ticket',
    });

    this.documents = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'documents',
      storeName: 'documents',
    });

    this.S3Documents = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'documents',
      storeName: 'S3Documents',
    });

    this.punchClock = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'punchClock',
      storeName: 'punchTimes',
    });

    this.digSiteStore = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'digSite',
      storeName: 'digSite',
    });

    this.formTemplates = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'formTemplates',
      storeName: 'formTemplates',
    });

    this.documentQueue = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'documentQueue',
      storeName: 'documentQueue',
    });

    this.priorityDocumentQueue = localforage.createInstance({
      driver: localforage.INDEXEDDB,
      name: 'documentQueue',
      storeName: 'manifest',
    });
  }

  newStore(storeName: string, tables: Array<string> = undefined): Record<string, LocalForage> {
    if (!this.cacheNames.has(storeName)) {
      this.cacheNames.add(storeName);
      const lf = {};
      if (tables) {
        tables.forEach((table) => {
          lf[table] = localforage.createInstance({
            driver: localforage.INDEXEDDB,
            name: storeName,
            storeName: table,
          });
        });
      } else {
        lf[storeName] = localforage.createInstance({
          driver: localforage.INDEXEDDB,
          name: storeName,
        });
      }
      return lf;
    } else {
      throw new Error('Store name already exists');
    }
  }

  // returns remaining storage in bytes, if available; returns -1 if not
  async estimateRemainingStorageInBytes(): Promise<number> {
    let remaining: number;

    try {
      if (navigator.storage && navigator.storage.estimate) {
        const result = await navigator.storage.estimate();
        const quota = result.quota;
        const usage = result.usage;
        remaining = quota - usage;
      } else {
        remaining = -1;
      }
    } catch (error) {
      this.loggerService.error('cache$: insert: function error', error);
    }
    return remaining;
  }

  // insert key, value pair into db
  async insert(storeType, key, value) {
    let success: boolean = false;
    try {
      if (key && value) {
        let result = null;
        // if (storeType == StoreType.ADMIN) {
        //   result = await this.lookupStore.setItem(key, value);
        // } else if (storeType == StoreType.COMPLETIONS) {
        //   result = await this.completionStore.setItem(key, value);
        // }
        if (StoreNameByType[storeType]) {
          result = await this[StoreNameByType[storeType]].setItem(key, value);
        }
        if (result) {
          success = true;
        } else {
          this.loggerService.error('cache$: insert: fail');
        }
      } else {
        this.loggerService.warn('cache$: insert: invalid input params');
      }
    } catch (error) {
      this.errorHandler.handleError(error);
      this.loggerService.error('cache$: insert: function error', error);
    }

    return success;
  }

  // get table from store
  async query(storeType, key) {
    let queryResult: any = {};
    try {
      if (key) {
        let result = null;
        if (storeType == StoreType.COMPLETIONS) {
          result = await this.completionStore.getItem(key);
        } else if (StoreNameByType[storeType]) {
          result = await this[StoreNameByType[storeType]].getItem(key);
        }

        if (result) {
          // {name: tableName, columns: [{}], data: [{}]}
          queryResult = result;
        } else {
          this.loggerService.warn('cache$: query: fail');
          queryResult = null;
        }
      } else {
        this.loggerService.warn('cache$: query: invalid input params');
        queryResult = null;
      }
    } catch (error) {
      this.loggerService.error('cache$: query: function error: ', error);
      queryResult = null;
    }

    return queryResult;
  }

  async remove(storeType, key) {
    try {
      if (StoreNameByType[storeType]) {
        await this[StoreNameByType[storeType]].removeItem(key);
      }
    } catch (error) {
      this.loggerService.error('cache$: removeItem: function error: ', error);
    }

    return true;
  }

  async removeItem(storeType, key, index) {
    try {
      if (key && index !== undefined) {
        let result = null;

        if (StoreNameByType[storeType]) {
          const existingData = await this[StoreNameByType[storeType]].getItem(key);

          if (existingData) {
            existingData.splice(index, 1);
            result = await this[StoreNameByType[storeType]].setItem(key, existingData);
          } else {
            this.loggerService.warn('cache$: remove: invalid input params');
          }
        }

        if (result) {
          return true;
        } else {
          this.loggerService.error('cache$: remove: fail');
        }
      } else {
        this.loggerService.warn('cache$: remove: invalid input params');
      }
    } catch (error) {
      this.loggerService.error('cache$: remove: function error: ', error);
    }

    return false;
  }

  // clear all keys in the adminStore
  async clear(storeType) {
    let success: boolean = false;
    try {
      let result = null;
      // if (storeType == StoreType.ADMIN) {
      //   result = await this.lookupStore.clear();
      // } else if (storeType == StoreType.COMPLETIONS) {
      //   result = await this.completionStore.clear();
      // }

      if (StoreNameByType[storeType]) {
        result = await this[StoreNameByType[storeType]].clear();
      }

      if (result) {
        success = true;
      } else {
        this.loggerService.error('cache$: clear: fail');
      }
    } catch (error) {
      this.loggerService.error('cache$: clear: function error: ', error);
    }
    return success;
  }

  clearKey(storeType, key): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        if (StoreNameByType[storeType]) {
          this[StoreNameByType[storeType]]
            .removeItem(key)
            .then(() => {
              resolve(true);
            })
            .catch((err) => {
              this.loggerService.error(err);
              resolve(false);
            });
        }
      } catch (error) {
        this.loggerService.error('cache$: clear: function error: ', error);
        resolve(false);
      }
    });
  }

  clearKeys(storeType, keys: []): Promise<boolean> {
    return new Promise((resolve) => {
      try {
        const promiseArray = [];
        if (StoreNameByType[storeType]) {
          keys.forEach((key) => {
            promiseArray.push(this[StoreNameByType[storeType]].removeItem(key));
          });
        }
        if (promiseArray.length > 0) {
          Promise.all(promiseArray).then(() => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } catch (error) {
        this.loggerService.error('cache$: clear: function error: ', error);
        resolve(false);
      }
    });
  }

  // clear all in all stores
  async clearAll() {
    let success: boolean = true;

    try {
      const promiseArr = [];
      //loop and clear each store
      for (let i = 0; i < StoreNameByType.length; i++) {
        promiseArr.push(this[StoreNameByType[i]].clear());
      }
      await Promise.all(promiseArr);
    } catch (error) {
      this.loggerService.error('cache$: clear: function error: ', error);
      success = false;
    }

    return success;
  }

  // delete instance of localforage - no more transactions
  async drop(storeType) {
    let success: boolean = false;

    try {
      let result = null;
      if (StoreNameByType[storeType]) {
        result = await this[StoreNameByType[storeType]].dropInstance();
      }
      if (result) {
        success = true;
      } else {
        this.loggerService.error('cache$: drop: fail');
      }
    } catch (error) {
      this.loggerService.error('cache$: drop: function error: ', error);
    }

    return success;
  }

  // get all tables from
  async queryAll(storeType) {
    let tables = [];

    try {
      const promiseArr = [];

      const keys = await this.getKeys(storeType);
      if (keys && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          promiseArr.push(this.query(storeType, keys[i]));
        }

        const result = await Promise.all(promiseArr);
        tables = result;
      } else {
        this.loggerService.warn('cache$: queryAll: store contains no keys');
        tables = null;
      }
    } catch (error) {
      this.loggerService.error('cache$: queryAll: function error: ', error);
      tables = null;
    }

    return tables;
  }

  // get array of keys from store
  async getKeys(storeType) {
    let keys = null;

    try {
      let result = null;
      // if (storeType == StoreType.ADMIN) {
      //   result = await this.lookupStore.keys();
      // } else if (storeType == StoreType.COMPLETIONS) {
      //   result = await this.completionStore.keys();
      // }

      if (StoreNameByType[storeType]) {
        result = await this[StoreNameByType[storeType]].keys();
      }

      if (result) {
        keys = result;
      }
    } catch (error) {
      this.loggerService.error('cache$: getKeys: function error: ', error);
    }

    return keys;
  }
}
