import JSZip from "jszip";

export function zipFiles(fileNames, bufferDataSets) {
  return new Promise(function (resolve, reject) {
    try {
      if (
        fileNames != null &&
        bufferDataSets != null &&
        fileNames.length == bufferDataSets.length
      ) {
        var zip = new JSZip();
        for (var i = 0; i < fileNames.length; i++) {
          zip.file(fileNames[i], bufferDataSets[i]);
        }
        zip
          .generateAsync({ type: "blob" })
          .then(function (zippedData) {
            resolve(zippedData);
          })
          .catch(function (ex) {
            reject(ex);
          });
      } else {
        reject("failed to zipFile: fileNames or bufferDataSets are null");
      }
    } catch (ex) {
      reject(ex);
    }
  });
}

export function zipFile<T>(
  fileName: string,
  bufferData: ZipType,
  returnType: ZipTypes = ZipTypes.base64
): Promise<T> {
  return new Promise(function (resolve, reject) {
    try {
      if (bufferData != null) {
        const zip = new JSZip();
        const options = {};
        if (returnType === ZipTypes.base64) {
          options['base64'] = true;
        }
        zip.file(fileName, bufferData);
        zip
          .generateAsync({
            type: returnType,
            compression: 'DEFLATE',
            compressionOptions: { level: 9 },
          })
          .then(function (zippedData) {
            resolve(zippedData);
          })
          .catch(function (ex) {
            reject(ex);
          });
      } else {
        reject('failed to zipFile: bufferData is null');
      }
    } catch (ex) {
      console.error('error');
      reject(ex);
    }
  });
}

export function unzipFile(zippedData) {
  return new Promise(function (resolve, reject) {
    try {
      if (zippedData != null) {
        JSZip.loadAsync(zippedData)
          .then(function (zip) {
            try {
              zip.generateAsync({ type: "blob" }).then((blob) => resolve(blob));
              // if (
              //   contents != null &&
              //   contents.files != null &&
              //   Object.keys(contents.files).length > 0 &&
              //   Object.keys(contents.files)[0] != null
              // ) {
              //   zip
              //     .file(Object.keys(contents.files)[0])
              //     .async("blob")
              //     .then(function (bufferData) {
              //       resolve(bufferData);
              //     });
            } catch (ex) {
              reject(ex);
            }
          })
          .catch((error) => {
            console.error("unzip loadasync error: ", error);
          });
      } else {
        reject("failed to unzipFile: zippedData is null");
      }
    } catch (ex) {
      reject(ex);
    }
  });
}

export function unzipFileIncludeName(zippedData) {
  return new Promise(function (resolve, reject) {
    try {
      if (zippedData != null) {
        var zip = new JSZip();
        zip.loadAsync(zippedData).then(function (contents) {
          try {
            if (
              contents != null &&
              contents.files != null &&
              Object.keys(contents.files).length > 0 &&
              Object.keys(contents.files)[0] != null
            ) {
              if (zip.file(Object.keys(contents.files)[0]) != null) {
                zip
                  .file(Object.keys(contents.files)[0])
                  .async("uint8array")
                  .then(function (bufferData) {
                    resolve({
                      filename: Object.keys(zip.files)[0],
                      data: bufferData,
                    });
                  });
              } else {
                resolve({ filename: Object.keys(zip.files)[0], data: null });
              }
            } else {
              reject("failed to unzipFile: contents is null");
            }
          } catch (ex) {
            reject(ex);
          }
        });
      } else {
        reject("failed to unzipFile: zippedData is null");
      }
    } catch (ex) {
      reject(ex);
    }
  });
}

export type ZipType = string | ArrayBuffer | Uint8Array | Buffer | Blob;

export enum ZipTypes {
  base64 = 'base64',
  text = 'text',
  binarystring = 'binarystring',
  array = 'array',
  uint8array = 'uint8array',
  arraybuffer = 'arraybuffer',
  blob = 'blob',
}
