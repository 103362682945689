import { Component, effect, Input, OnDestroy, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Component({
  selector: 'app-competers-date-picker',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatIconModule, MatDatepickerModule, MatInputModule, NgxMaterialTimepickerModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CompetersDatePickerComponent,
    },
  ],

  template: `
<div
  class="box-border flex flex-col {{
    isSelected ? 'pb-3' : ''
  }} gap-3 justify-start items-start bg-transparent w-[300px]">
  <div class="flex flex-row gap-3">
    <!-- Date picker section -->
    <div class="flex flex-col justify-start items-center">
      <div
        class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
        <button
          (click)="startDatePicker.open()"
          class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
          <mat-icon style="width:20px; height:20px;" svgIcon="calendar"></mat-icon>
        </button>
        <p class="text-md text-gray-600 font-rajdhani font-semibold">
          {{ (startDate.value | date: 'dd-MM-YYYY') ?? 'DD-MM-YYYY' }}
        </p>
      </div>
      <div class="overflow-hidden h-0 w-36">
        <mat-datepicker #startDatePicker></mat-datepicker>
        <input
          #startDate
          matInput
          [matDatepicker]="startDatePicker"
          [ngModel]="date$$()"
          (ngModelChange)="setDate($event)" />
      </div>
    </div>

    <!-- Time picker section -->
    <div class="flex flex-col justify-start items-center">
      <div
        class="flex flex-row gap-3 pl-1.5 justify-start items-center box-border border-solid border-1 border-[#D1D3D4] rounded-md w-36 h-8 bg-white">
        <button
          (click)="picker.open()"
          class="flex justify-end items-center appearance-none border-none bg-transparent cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
          <mat-icon style="width:20px; height:20px;" svgIcon="stopwatch"></mat-icon>
        </button>
        <p class="text-md text-gray-600 font-rajdhani font-semibold">
          {{ timeValue ? timeValue : 'HH:MM' }}
        </p>
      </div>
      <ngx-material-timepicker
        #picker
        [theme]="timePickerTheme"
        (timeSet)="setTime($event)"
        [format]="24"
        [minutesGap]="5">
      </ngx-material-timepicker>
    </div>
  </div>
</div>

  `,
})
export class CompetersDatePickerComponent implements OnDestroy, ControlValueAccessor {
  @Input() disabled = false;
  @Input() showTimeToo = false;

  // Observables and signals
  private destroy$ = new Subject<void>();
  protected date$$ = signal<Date | null>(null); // Signal to store the combined date and time
  protected timeValue = '';
  protected val: Date = new Date();
  protected touched = false;
  protected isSelected: boolean = false;
  protected defaultDate: Date = new Date();

  timePickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#008AFF',
    },
    dial: {
      dialBackgroundColor: '#008AFF',
    },
    clockFace: {
      clockFaceBackgroundColor: '#eee',
      clockHandColor: '#008AFF',
      clockFaceTimeInactiveColor: '#222',
    },
  };

  constructor() {
    effect(() => {
      if (this.onChange !== undefined && this.date$$()) {
        this.onChange(this.date$$());
        this.val = this.date$$();
        this.isSelected = this.val !== null;
      }
    });
  }

  onChange: (data: Date | null) => void = () => { };

  onTouched: () => void = () => { };

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(data: Date | null) {
    if (!isEqual(this.val, data)) {
      this.date$$.set(data ?? null);
    }
    if (data === null) {
      this.isSelected = false;
    }
  }

  registerOnChange(onChange: (data: Date | null) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
    if (!this.isSelected) {
      this.date$$.set(null);
    }
  }

  setDate(event: Date) {
    const current = this.date$$() ?? new Date(); // If date is not set, default to today
    current.setFullYear(event.getFullYear(), event.getMonth(), event.getDate());
    this.date$$.set(current); // Update with the new date
  }

  setTime(event: string) {
    const current = this.date$$() ?? new Date(); // If date is not set, default to today

    // Extract hours and minutes from the time string
    const [hours, minutes] = event.split(':').map(Number);

    // Set the hours and minutes
    current.setHours(hours, minutes);
    this.date$$.set(current); // Update with the new time
    this.timeValue = event;
  }
}


export type DateRange = { upper: Date; lower: Date };
