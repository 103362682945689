import { ApplicationRef, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { Workbox } from 'workbox-window';
import { environment } from '../../../../environments/environment';
import { catchError, filter, first, takeUntil, tap } from 'rxjs/operators';
import { concat, firstValueFrom, from, interval, of, Subject, switchMap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { LoggerService } from './logger/logger.service';

interface UpdateServiceConfig {
  checkInterval: number;
  newVersionMessage: string;
  activatedMessage: string;
}

enum UpdateServiceState {
  IDLE = 'idle',
  INITIALIZING = 'initializing',
  WAITING_FOR_RESPONSE = 'waiting_for_response',
  CHECKING_FOR_UPDATE = 'checking_for_update',
  UPDATING = 'updating',
  RELOADING = 'reloading',
  FATAL_ERROR = 'fatal_error',
}

@Injectable({
  providedIn: 'root',
})
export class UpdateService implements OnDestroy {
  // services
  private loggerService = inject(LoggerService);
  private appRef = inject(ApplicationRef);
  private appRouter = inject(Router);

  // members
  private config: UpdateServiceConfig = {
    checkInterval: environment.production ? 300000 : 10000,
    newVersionMessage: 'A new version of the site is available. Install now?',
    activatedMessage: 'New version installed. Refresh to get the newest version?',
  };
  private registration: ServiceWorkerRegistration;
  private wb: Workbox;
  private allowedRoutes: string[] = ['/app', '/fieldside'];
  private isControlled = false;

  // observables
  private everyInterval$ = interval(this.config.checkInterval);
  private _cleanup$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  // signals
  private updateServiceState$$ = signal<UpdateServiceState>(UpdateServiceState.IDLE);
  private updateDenied$$ = signal(false);

  constructor() {
    if ('serviceWorker' in navigator) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this._cleanup$.next();
    this._cleanup$.complete();
  }

  private init() {
    this.appRouter.events
      .pipe(
        filter(() => this.updateServiceState$$() !== UpdateServiceState.FATAL_ERROR),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const path = event.url.split('?')[0];
          // check if the path starts with allowed route
          if (this.allowedRoutes.some((route) => path.startsWith(route))) {
            this.loggerService.log('allowed: ', path);
            if (!this.wb) {
              this.setupUpdateService();
            }
          } else {
            this._cleanup$.next();
          }
        }
      });

    this._cleanup$
      .pipe(
        tap(() => this.loggerService.log('Update Service: cleaning up...')),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.wb = undefined;
        this.unregisterAll().then(() => this.loggerService.log('Service worker unregistered'));
      });
  }

  public setupUpdateService() {
    this.updateServiceState$$.set(UpdateServiceState.INITIALIZING);
    this.initializeServiceWorker()
      .then((res) => {
        if (res instanceof ServiceWorkerRegistration) {
          return this.setupUpdateCheck();
        }
        throw new Error('Service worker registration failed');
      })
      .then(() => {
        this.updateServiceState$$.set(UpdateServiceState.IDLE);
      })
      .catch((e) => {
        this.loggerService.warn('Caught: Service worker setup failed:', e);
        this.updateServiceState$$.set(UpdateServiceState.FATAL_ERROR);
      });
  }

  private async initializeServiceWorker(): Promise<ServiceWorkerRegistration> {
    try {
      if (window.self !== window.top) {
        return this.unregisterAll();
      } else {
        // The application is not running inside an iframe
        return this.registerNewWorker('/service-worker.js');
      }
    } catch (e) {
      return this.unregisterAll();
    }
  }

  private async registerNewWorker(path: string) {
    this.wb = new Workbox(path);
    return this.wb
      .register()
      .then((registration) => {
        this.registration = registration;
        if (registration.active && registration.waiting) {
          this.wb.messageSkipWaiting();
        }
        return registration;
      })
      .catch((e) => {
        this.loggerService.warn('ServiceWorker registration failed:', e);
        return this.unregisterAll();
      });
  }

  private async unregisterAll() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach((registration) => registration.unregister());
    return null;
  }

  private async setupUpdateCheck(): Promise<void> {
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable));
    const everyIntervalOnceAppIsStable$ = concat(appIsStable$, this.everyInterval$);

    this.setupWorkboxListeners(this.wb);

    everyIntervalOnceAppIsStable$
      .pipe(
        filter(() => this.updateServiceState$$() === UpdateServiceState.IDLE),
        tap(() => this.updateServiceState$$.set(UpdateServiceState.CHECKING_FOR_UPDATE)),
        switchMap(() =>
          from(this.wb.update()).pipe(
            catchError((error: unknown) => {
              if (error instanceof Error) {
                this.loggerService.warn(`Update check failed: ${error.message}`);
              } else {
                this.loggerService.warn('Update check failed with an unknown error');
              }
              // Log the error for debugging purposes
              this.loggerService.error('Full error details:', error);
              // Return an observable that emits a value to continue the stream
              return of('error');
            })
          )
        ),
        catchError((error: unknown) => {
          this.loggerService.error('Unexpected error in update check process:', error);
          // Return an observable to continue the stream
          return of('unexpected error');
        }),
        takeUntil(this._cleanup$)
      )
      .subscribe({
        next: (result) => {
          if (result === 'error' || result === 'unexpected error') {
            // Handle the error case if needed
            this.loggerService.log('Update check encountered an error');
          } else {
            this.loggerService.log('Update check completed');
          }
          this.updateServiceState$$.set(UpdateServiceState.IDLE);
        },
        error: (err) => {
          // This should not be reached due to our error handling, but just in case
          this.loggerService.error('Unhandled error in subscription:', err);
        },
        complete: () => {
          this.loggerService.log('Update check process completed');
        },
      });
  }

  private setupWorkboxListeners(wb: Workbox) {
    const controllingHandler = (event) => {
      this.loggerService.log('Update Service "Controlling" event: ', event.isUpdate);
      if (event.isUpdate || this.isControlled) {
        this.showRefreshPrompt();
      }
      this.isControlled = true;
    };

    wb.addEventListener('controlling', controllingHandler);

    firstValueFrom(this._cleanup$).then(() => {
      wb.removeEventListener('controlling', controllingHandler);
    });
  }

  private async showRefreshPrompt() {
    const callback = () => {
      this.updateServiceState$$.set(UpdateServiceState.RELOADING);
      window.location.reload();
    };
    const updateAccepted = await this.promptForUpdate();
    if (updateAccepted) {
      callback();
    } else {
      this.updateDenied$$.set(true);
    }
    return updateAccepted;
  }

  private promptForUpdate(): Promise<boolean> {
    return new Promise((resolve) => {
      if (confirm(this.config.newVersionMessage)) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  getSWEnabledAsBool(): boolean {
    return 'serviceWorker' in navigator && !!navigator.serviceWorker.controller;
  }

  async activateUpdate(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.getRegistration();
        if (registration) {
          await registration.update();
        }
      } catch (error) {
        this.loggerService.error('Error activating update:', error);
      }
    }
  }

  public resetErrorState() {
    this.updateServiceState$$.set(UpdateServiceState.IDLE);
  }
}
