import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseInt',
  standalone: true,
})
export class ParseIntPipe implements PipeTransform {
  transform(value: string, base: number = 10): number {
    return parseInt(value, base);
  }
}
