import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule} from "@angular/common";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MaterialModule} from "../../material.module";
import {SnackbarService} from "../../snackbar/snackbar.service";
import {SnackbarType} from "../../snackbar/snackbar/snackbar";
import {BrowserModule, DomSanitizer} from "@angular/platform-browser";
import {SettingID} from "src/app/modules/core/services/user/setting";
import {UserService} from "src/app/modules/core/services/user/user.service";
import {safeAwait} from "src/app/modules/core/validators/validator";
import {ADMIN_TABLE_NAMES} from "src/app/modules/core/admin/tables";
import {UtilocateAdminCacheService} from "src/app/modules/core/cache/utilocate-admin.service";
import {CompletedLocateStatusIDs} from "../../ticket-details/ticket-details.module";
import { CreateTicketComponentService, OptionsFillID } from "src/app/modules/create-ticket/create-ticket-component.service";
import { TicketService } from "../ticket.service";

@Component({
  selector: "app-form-input-template",
  templateUrl: "./form-input-template.component.html",
  styleUrls: ["./form-input-template.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    ScrollingModule,
    NgxMaterialTimepickerModule,
    MaterialModule,
    BrowserModule
  ],
})
export class FormInputTemplateComponent implements OnInit {
  @Input() view: any;
  @Input() views: any;
  @Input() navLinks: any;
  @Input() isPrimary: boolean;
  @Input() callType: any;
  @Output() EmailChange = new EventEmitter<any>();
  @Output() InputChange = new EventEmitter<any>();
  @Output() checkboxChange = new EventEmitter<any>();

  isLoaded = false;
  url: any;
  sanitizedUrl: any;
  unlocatable: any
  locateStatus: string;
  locateStatusID?: string;
  ticketIsCompleted: boolean = false;
  selected: any
  ticketTagsSetting: any
  checkboxInputTypeIDs = ['1', '5'];
  callTypeDesc: any

  allowedToUpdateIfPrivateTicket = [
    'LocateCrossStreet',
    'LocateSecondCrossStreet',
    'DispatcherRemarks',
    'ExtentOfWork',
    'CallerName',
    'CustomerID', // huh
    'TypeOfWork',
    'bSiteMeetRequired',
    'bRevisionDenied',
    'UnitLot',
    'MapReference',
    'Remarks',
    'email2',
    'MeetLocation'
  ];

  allowToUpdateAdditionalFields = [
    'RegionID',
    'Remarks',
    'SubRegionID',
    'LocateSubRegionName',
    'LocateSubDivision',
    'Township',
    'StartHouseNumber',
    'EndHouseNumber',
    'LocateAddress',
    'PONum',
    'JobNumber',
    'WorkDoneFor',
    'AlternateContact',
    'AlternativeNumber',
    'PhoneNumber',
    'email',
    'DispatcherRemarks',
    'email2',
    'MeetLocation',
    'ProjectNum'
  ];

  alwaysAllowedtoEdit = [
    'DispatcherRemarks',
    'Remarks',
    'email2',
    'MeetLocation'
  ];

  constructor(
    private snackBarService: SnackbarService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private utilocateAdminService: UtilocateAdminCacheService,
    private createticketService: CreateTicketComponentService,
    private ticketService: TicketService
  ) { }

  async ngOnInit() {
    this.isLoaded = true;
    this.locateStatus = this.view.formGroup.get("LocateStatusID")?.value.toString();
    this.locateStatusID = await this.getLocateStatusID();
    this.callTypeDesc = await this.getCallTypeDesc();
    this.view.formGroup.patchValue({CallTypeID: this.callTypeDesc})

    await this.ticketTags()
    if (this.locateStatusID != null && CompletedLocateStatusIDs.includes(parseInt(this.locateStatusID))) {
      this.ticketIsCompleted = true;
    }
    this.ticketTagsSetting = this.userService.isSettingActive(SettingID.TICKET_TAGS);
    this.disableFields(this.view);
    if (this.view.header == "Ticket Info" && this.view) {
      const remarks = this.view.formGroup.get("Remarks").value;
      if (remarks) {
        const urlPattern = /(https?:\/\/[^\s]+)/;
        const matches = remarks.match(urlPattern);
        if (matches && matches.length > 0) {
          this.url = matches[0];
          this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(matches[0]);
        }
      }
    }
  }


  /**
   * Disables fields dynamically, if criteria are met 
   * @param views 
   * @returns 
   */
  disableFields(views) {
    let template: string[] = [];

    const editCompletedTicket = this.userService.isSettingActive(SettingID.EDIT_COMPLETED);
    let editablePrivate = this.userService.isSettingActive(SettingID.EDIT_PRIVATE_TICKET);
    let editable = this.userService.isSettingActive(SettingID.EDIT_TICKET_ADDITIONAL);
    template = this.alwaysAllowedtoEdit;

    // if the ticket is completed, 
    // we can only edit the ticket if the user has the setting to edit the completed ticket
    // otherwise the ticket is uncomplete so they can edit it like normal 
    if (this.ticketIsCompleted) {
      editablePrivate = editablePrivate && editCompletedTicket;
      editable = editable && editCompletedTicket;
      template = []
    }

    if (editablePrivate && this.callType == 9) {
      template = this.allowedToUpdateIfPrivateTicket;
    } 
    if (editable) {
      template = this.allowToUpdateAdditionalFields;
    } 
    if(editablePrivate && this.callType == 9 && editable){
      template = this.allowedToUpdateIfPrivateTicket.concat(this.allowToUpdateAdditionalFields);
    }

    const isTicketInfoOrTracking = ['ticketInfo', 'tracking'].includes(views.key.toString());
    const isTicketDetailsHeader = views.header.toString().toLowerCase() === 'ticket details';

    if (isTicketInfoOrTracking || isTicketDetailsHeader) {
      for (const i of Object.keys(views.groups)) {
        this.disableCheckboxesInGroup(template, views.groups[i]);
      }
    }
  }

  disableCheckboxesInGroup(template: string[], group) {
    for (const x of Object.keys(group.fields)) {
      const field = group.fields[x].key;

      //if it is a checkbox, and the field is read only
      if (this.checkboxInputTypeIDs.includes(group.fields[x].inputTypeID.toString()) && group.fields[x].isReadOnly) {
        this.view.formGroup.get(field.toString()).disable({emitEvent: false});
      }

      //if the field is in the template, set the field to be editable 
      if (template.includes(field)) {
        if (template.includes(field)) group.fields[x].isReadOnly = false;
        this.view.formGroup.get(field.toString()).enable({emitEvent: false});
      }
    }
  }

  async ticketTags(){    
    const AssignmentID = sessionStorage.getItem("AssignmentID");
    let tbCompletions_AssignmentToTags = await this.ticketService.getAssignmentToTags(AssignmentID)
    if(tbCompletions_AssignmentToTags){
      let tagIDs = tbCompletions_AssignmentToTags
      .filter(object => object.bFieldRemoved !== 1)
      .map(object => object.TagID);    
      if(this.view.groups.workDetails){
        const allOptions = this.view.groups.workDetails.fields.TicketTags.options;
        const selectedOptions = allOptions.filter(option => tagIDs.includes(option.value));    
        this.view.formGroup.get("TicketTags").setValue(selectedOptions);
      
        this.selected = selectedOptions
      }
      
    }
  }


  onInputBlur(event: any, field: any) {    
    if (event.target.id == "email2" &&
      !this.view.formGroup.get("email2").pristine) {
      if (
        (this.view.formGroup.get("email2").value &&
          this.view.formGroup.get("email2").status == "VALID") ||
        this.view.formGroup.get("email2").value == ""
      ) {
        this.EmailChange.emit(this.view.formGroup.get("email2").value);
      }
    } else {
      if (!this.view.formGroup.get(field).pristine) {
        if (
          (this.view.formGroup.get(field).value &&
            this.view.formGroup.get(field).status == "VALID") ||
          this.view.formGroup.get(field).value == ""
        ) {
          const change = {};
          change[field] = this.view.formGroup.get(field).value
          this.InputChange.emit(change);
        }
      }
    }
  }



  async getLocateStatusID() {
    if (!this.locateStatus) return null;
    try {
      const [tbAdmin_LocateStatus, error] = await safeAwait(
        this.utilocateAdminService.queryTable(
          ADMIN_TABLE_NAMES.tbAdmin_LocateStatus
        )
      );
      return tbAdmin_LocateStatus.find(
        (row) => row["LocateStatusDesc"] == this.locateStatus
      )["LocateStatusID"];
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async getCallTypeDesc() {
    const AssignmentID = sessionStorage.getItem("AssignmentID");
    const CallTypeDesc = await this.ticketService.getTicketCallTypeDesc(AssignmentID);
    return CallTypeDesc
  }


  emitChecked(checked: boolean, label) {
    if (label === 'Unlocatable') {
      const unlocatable = ["unlocatable", checked]
      this.checkboxChange.emit(unlocatable);
    }
    if (label === 'Unlocatable Verified') {
      const unlocatableVerified = ["unlocatableVerified", checked]
      this.checkboxChange.emit(unlocatableVerified);

    }

  }

  trackByFn(index, item) {    
    return index;
  }

  showErrorMessage(message) {
    this.snackBarService.openSnackbar(message, SnackbarType.error, "OK");
  }
}
