import { ADMIN_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import { U2_USER_CATEGORY_ID } from 'src/app/modules/core/services/user/user';

const LOCATOR_ADMIN_TABLES: string[] = [
  ADMIN_TABLE_NAMES.tbAdmin_TicketTags,
  ADMIN_TABLE_NAMES.tbAdmin_AutologDesc,
  ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailsCategories,
  ADMIN_TABLE_NAMES.tbAdmin_PrimaryDetailFields,
  ADMIN_TABLE_NAMES.tbAdmin_Utilities,
  ADMIN_TABLE_NAMES.tbAdmin_UtilityToCallType,
  ADMIN_TABLE_NAMES.tbAdmin_UtilityBillingCategories,
  ADMIN_TABLE_NAMES.tbAdmin_UtilityBillingDetails,
  ADMIN_TABLE_NAMES.tbLogin_Users,
  ADMIN_TABLE_NAMES.tbAdmin_LocateType,
  ADMIN_TABLE_NAMES.tbAdmin_LocateStatus,
  ADMIN_TABLE_NAMES.tbAdmin_BillingRules,
  ADMIN_TABLE_NAMES.tbAdmin_CallType,
  ADMIN_TABLE_NAMES.tbAdmin_Excavators,
  ADMIN_TABLE_NAMES.tbLogin_UserCategories,
  ADMIN_TABLE_NAMES.tbDigsite_GPS,
  ADMIN_TABLE_NAMES.tbDigsite_Shapes,
  ADMIN_TABLE_NAMES.tbMap_Routes,
  ADMIN_TABLE_NAMES.tbAdmin_RegionList,
];

export function getAdminTablesFromUserCategoryID(UserCategoryID: string): string[] {
  if (UserCategoryID == U2_USER_CATEGORY_ID.Locator.toString()) {
    return LOCATOR_ADMIN_TABLES;
  } else {
    return LOCATOR_ADMIN_TABLES;
  }
}
