import { Injectable } from '@angular/core';
import { LoggerService } from '../services/logger/logger.service';
import { CacheService, StoreType } from '../cache/cache.service';
import { api, apiKeys } from '../../../ENDPOINTS';
import { localStorageKeys } from '../../../LOCAL_STORAGE';
import { ApiService, UtilocateApiRequest } from '../api/baseapi.service';

@Injectable({
  providedIn: 'root',
})
export class AdminLookupService {
  constructor(
    private loggerService: LoggerService,
    private cacheService: CacheService,
    private utilocateApiService: ApiService
  ) {
    // super(http$, logger$, mainError$);
    this.updateAndRefreshCacheData().then(() => {});
  }

  private async updateAndRefreshCacheData(tableNames = []) {
    const lastUpdate = sessionStorage.getItem(localStorageKeys.CACHE_DATE_ADMIN_LOOKUP_KEY);
    const keys = await this.cacheService.getKeys(StoreType.ADMIN);

    if (tableNames.length === 1 && tableNames[0] === 'tbAdmin_Settings') {
      return true;
    }

    if (tableNames.some((table) => !keys.includes(table))) {
      return true;
    }

    if (lastUpdate) {
      const lastUpdateTime = parseInt(lastUpdate, 10);
      const oneHourAgo = new Date().setHours(new Date().getHours() - 1);
      return lastUpdateTime < oneHourAgo;
    }
    return false;
  }

  // get requested tables
  // [] to get all tables
  async getAdminTables(tableNames: string[], options: unknown = {}) {
    let tables = [];
    try {
      const check = await this.queryCache(StoreType.ADMIN, tableNames);

      // if any table is not found in cache, it will be null in the check array. fetch from server in that case...
      if (!check.includes(null)) {
        this.refreshAdminTables(tableNames, options).then(() => {
          this.loggerService.log('AdminLookup$: getLookupTable: cache refreshed');
        });
        return check;
      } else {
        if (await this.updateAndRefreshCacheData(tableNames)) {
          if (await this.refreshAdminTables(tableNames, options)) {
            tables = await this.queryCache(StoreType.ADMIN, tableNames);
          } else {
            this.loggerService.warn('AdminLookup$: getLookupTable: cannot refresh admin tables');
          }
        }
      }
      return tables;
    } catch (error) {
      this.loggerService.error('AdminLookup$: getLookupTable: function error: ', error);
      return [];
    }
  }

  async getAdminTablesFromServer(tableNames: string[], options: unknown = {}) {
    let tables = [];

    try {
      // get requested tables or all
      if (await this.refreshAdminTables(tableNames, options)) {
        tables = await this.queryCache(StoreType.ADMIN, tableNames);
      } else {
        this.loggerService.warn('AdminLookup$: getLookupTable: cannot refresh admin tables');
      }
    } catch (error) {
      this.loggerService.error('AdminLookup$: getLookupTable: function error: ', error);
    }

    return tables;
  }

  // get table column info
  async getTableColumnDetails(store: StoreType, tableNames: string[]) {
    let result = null;

    try {
      let queryResult = null;

      if (tableNames && tableNames.length > 0) {
        queryResult = await Promise.all(tableNames.map(async (name) => await this.cacheService.query(store, name)));
      } else {
        // get all
        queryResult = await this.cacheService.queryAll(store);
      }

      if (queryResult) {
        // iterate through each table
        result = queryResult.map((tableObj) => {
          const queryResultColumns = tableObj['Columns'];

          // iterate through each column
          const Columns = queryResultColumns.reduce((total, columnObj) => {
            // create {columnName: columnValue}
            for (const key in columnObj) {
              total[key] = this.getReadableColumnType(columnObj[key]);
            }
            return total;
          }, {});

          // return {name: tableName, columns: { columnName: columnValue} }
          return { Columns, name: tableObj['name'] };
        });
      } else {
        this.loggerService.warn('AdminLookup$: getTableColumnDetails: fail');
      }
    } catch (error) {
      this.loggerService.error('AdminLookup$: getTableColumnDetails: function error: ', error);
    }
    return result;
  }

  // get value of [columns] from row id
  // async getLookupTableValues(tableName: string, rowID: number, columns: string[]) {}

  // get id of row from column value
  async getLookupTableRows(tableNames: string[], params: Record<string, unknown>) {
    const tableData: any = [];

    try {
      const tables = await this.getAdminTables(tableNames);
      if (tables && tables.length > 0) {
        // iterate table data rows
        for (let i = 0; i < tables.length; i++) {
          const filterResult = tables[i]['Data'].filter((row) => {
            // iterate WHERE clause
            for (const [key, value] of Object.entries(params)) {
              if (row[key] == null) {
                //null
                return false;
              } else {
                if (Array.isArray(value)) {
                  // !in (numbers)
                  if (!value.includes(row[key])) {
                    return false;
                  }
                } else {
                  // != value
                  if (row[key].toString().toUpperCase() != value.toString().toUpperCase()) {
                    return false;
                  }
                }
              }
            }
            return true;
          });

          if (filterResult) {
            const result = {
              name: tables[i]['name'],
              rows: filterResult,
            };
            tableData.push(result);
          }
        }
      } else {
        this.loggerService.warn('AdminLookup$: getLookupTableRows: cannot get table');
      }
    } catch (error) {
      this.loggerService.warn('AdminLookup$: getLookupTableRows: function failed');
    }
    return tableData;
  }

  async getAdminTableDescFromID(tableName: string, params: Record<string, unknown>) {
    try {
      const rowsToReturn = [];

      const adminTableResult = await this.getAdminTables([tableName]);
      if (adminTableResult && adminTableResult[0]) {
        const adminTableRows = adminTableResult[0]['Data'];
        for (let i = 0; i < adminTableRows.length; i++) {
          const curRow = adminTableRows[i];
          for (const [key, value] of Object.entries(params)) {
            if (curRow && curRow[key] != null) {
              if (Array.isArray(value) && value.includes(curRow[key])) {
                rowsToReturn.push(curRow);
              } else if (curRow[key] == value) {
                rowsToReturn.push(curRow);
              }
            }
          }
        }

        return rowsToReturn;
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async refreshAdminTables(tableNames: string[], options: unknown = {}) {
    let success: boolean = false;

    try {
      const url = apiKeys.u2.refreshAdminTables;
      const type = api[url].type;
      let apiBody = {};

      if (tableNames) {
        apiBody = {
          tablenames: tableNames,
          options: options,
        };
      }

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKeys.u2.refreshAdminTables,
        API_TYPE: type,
        API_BODY: apiBody,
      };

      // update cache using api (done via interceptor)
      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);

      if (result && result.ok) {
        await this.updateAndRefreshCacheData();
        success = true;
      } else {
        this.loggerService.warn('AdminLookup$: refreshLookupTables: fail');
      }
    } catch (error) {
      console.error(error);
      this.loggerService.error('AdminLookup$: refreshLookupTables: function error');
    }

    return success;
  }

  // query cache for requested tables
  private async queryCache(store: StoreType, tableNames: string[]) {
    try {
      let result: unknown[];

      if (tableNames && tableNames.length > 0) {
        // create + execute a promise array of all requested tables
        result = await Promise.all(tableNames.map(async (name) => await this.cacheService.query(store, name)));
      } else {
        // get all
        this.loggerService.warn('AdminLookup$: getLookupTable: getting all tables');
        result = await this.cacheService.queryAll(store);
      }

      if (result) {
        return result;
      } else {
        this.loggerService.warn('AdminLookup$: getLookupTable: fail');
        return null;
      }
    } catch (error) {
      this.loggerService.error('AdminLookup$: getLookupTable: function error: ', error);
    }
  }

  // get column data types
  private getReadableColumnType(columnType: number) {
    let type = null;

    // map between string / number
    switch (columnType) {
      case 1:
        type = 'number';
        break;
      case 3:
        type = 'string';
        break;
      default:
        break;
    }

    return type;
  }
}
