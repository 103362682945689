export type JsonForm = {
  $schema?: string;
  controls: JsonFormControl[];
  id: number | string;
  name: string;
  meta: Meta;
};

export type JsonFormControl = {
  type: InputType;
  name: string;
  validations: Validations;
  placeholder?: string;
  label?: string;
  options?: Options;
  source?: string;
  radioOptions?: Array<JsonFormControl>
  tooltip?: string;
  dataSource?: Array<{ name: string; value: unknown }>;
};

export type Validations = Record<string, unknown>;

export type Options = {
  multiple: boolean;
};

export type Meta = Record<string, unknown>;

export type JsonFormData = Array<JsonForm>;

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  DATE = 'date',
  FILE = 'file',
  TIME = 'time',
  DATETIME_LOCAL = 'datetime-local',
  URL = 'url',
  WEEK = 'week',
  MONTH = 'month',
  COLOR = 'color',
  RANGE = 'range',
  SEARCH = 'search',
  TEL = 'tel',
}
