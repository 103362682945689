import { Injectable, OnDestroy } from '@angular/core';
import { U2_USER_CATEGORY_ID, User } from './user';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Registration } from '../../../core/services/token/token.service';
import {
  BaseCompletionCacheService,
  BaseDocumentCacheService,
  BaseDocumentQueueCacheService,
  BaseS3DocumentCacheService,
  CacheWhereClauseType,
  manifestCacheService,
} from '../../cache/cache.interface';
import { UtilocateSettingService } from '../../cache/utilocate-settings.service';

import { LoggerService } from '../logger/logger.service';
import { UtilocateAdminCacheService } from '../../cache/utilocate-admin.service';
import { AdminLookupService } from '../../admin/admin-lookup.service';
import { Setting, SettingID } from './setting';
import { ADMIN_TABLE_NAMES } from '../../admin/tables';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilocateTokenPaths, UtilocateTokenService } from '../token/token.service';
import { UtilocateApiRequest } from '../../api/baseapi.service';
import { UtilocateApiService } from '../../api/utilocateapi.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { CacheService, StoreType } from '../../cache/cache.service';
import { api, apiKeys } from '../../../../ENDPOINTS';
import { localStorageKeys } from '../../../../LOCAL_STORAGE';
import { environment } from 'src/environments/environment';
import axios from 'axios';

const UTILITY_USER_SESSION_EXPIRY_MINUTES = 15;
const USER_SESSION_INTERVAL_MS = 90000;

const enum U3_USER_CATEGORY_ID {
  SystemAdmin = 1,
  Manager,
  Dispatcher,
  Utility,
  Auditor,
  Excavator,
  Leadhand,
  Locator,
  Supervisor,
  RestrictedUtility,
}

export class ReadableUserData {
  Username?: string = '';
  UserCategoryDesc?: string = '';
}

const defaultUser: User = {
  id: '-1',
  firstName: '',
  lastName: '',
  category: '',
  categoryID: -1,
};

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  CurrentNavigationSnapshot = {};
  private userSessionInterval;
  private userAuthorizedSubject: BehaviorSubject<boolean>;

  private subject = new BehaviorSubject<User>(defaultUser);
  currentUser$: Observable<User> = this.subject.asObservable();
  user: User = defaultUser;
  checkFirst: boolean;

  constructor(
    private logger: LoggerService,
    private utilocateAdminCacheService: UtilocateAdminCacheService,
    private adminLookup: AdminLookupService,
    private route: ActivatedRoute,
    private router: Router,
    private utilocateSettingService: UtilocateSettingService,
    private tokenService: UtilocateTokenService,
    private authenticationService: AuthenticationService,
    private utilocateApiService: UtilocateApiService,
    private idb: CacheService,
    private baseCompletionsCacheService: BaseCompletionCacheService,
    private baseDocumentCacheService: BaseDocumentCacheService,
    private baseS3DocumentCacheService: BaseS3DocumentCacheService,
    private ManifestCacheService: manifestCacheService,
    private baseDocumentQueueCacheService: BaseDocumentQueueCacheService
  ) {
    if (this.authenticationService.getToken()) {
      this.refreshUser();
    }

    this.userAuthorizedSubject = new BehaviorSubject<boolean>(this.tokenService.isAuthorized());
  }

  isRegistered(): boolean {
    return this.tokenService.isRegistered();
  }

  registerUser(registration: Registration) {
    this.tokenService.setRegistration(registration);
  }

  getRegistration(): Registration {
    const result: Registration = JSON.parse(this.tokenService.getRegistration());
    return result;
  }

  setToken(token: string) {
    this.tokenService.setToken(token);
  }

  /**
   * Sets current user and emits change
   * @param user User
   */
  setCurrentUser(user: User) {
    try {
      this.user = user;
      this.subject.next(user);
    } catch (error) {
      this.logger.error(error);
    }
  }

  logoutUserAndDeregister() {
    this.logoutUser();
    this.idb.clearAll();
    this.baseCompletionsCacheService.clear();
    this.tokenService.destroyRegistration();
  }

  //Checks if its the users first login
  firstLogin(first?) {
    this.checkFirst = first !== undefined ? first : this.checkFirst;
    return this.checkFirst;
  }

  async getSettings(settingIDs: number[], userID?: string) {
    if (
      !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbAdmin_Settings)) ||
      !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbUser_SettingOverrides))
    ) {
      await this.adminLookup.refreshAdminTables([
        ADMIN_TABLE_NAMES.tbAdmin_Settings,
        ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
      ]);
    }

    return await this.utilocateSettingService.getSettings(settingIDs, userID);
  }

  isAuthorized(checkRegister = true): boolean {
    if (this.tokenService.isAuthorized(checkRegister)) {
      return true;
    }
    return false;
  }

  async getReadableUserInfo(userID: string = this.getUserID()): Promise<ReadableUserData> {
    const readableUserData: ReadableUserData = {};

    try {
      if (
        !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbLogin_Users)) ||
        !(await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbLogin_UserCategories))
      ) {
        await this.adminLookup.refreshAdminTables([
          ADMIN_TABLE_NAMES.tbLogin_Users,
          ADMIN_TABLE_NAMES.tbLogin_UserCategories,
        ]);
      }
      const userResult: object[] | Error = await this.utilocateAdminCacheService.queryTable(
        ADMIN_TABLE_NAMES.tbLogin_Users,
        [
          {
            Column: 'UserID',
            Value: userID,
            ValueType: CacheWhereClauseType.NUMBER,
          },
        ]
      );
      const userCatIDResult: object[] | Error = await this.utilocateAdminCacheService.queryTable(
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
        [{ Column: 'UserCategoyID', Value: this.getUserCategoryID() }]
      );

      if (!(userResult instanceof Error) && !(userCatIDResult instanceof Error)) {
        const user = userResult[0];
        const userCatID = userCatIDResult[0];

        const name = ''.concat(user['FirstName']).concat(' ').concat(user['LastName']);
        const userCatIDDesc = userCatID['Description'];

        readableUserData.Username = name;
        readableUserData.UserCategoryDesc = userCatIDDesc;

        return readableUserData;
      } else {
        throw userResult instanceof Error ? userResult : userCatIDResult;
      }
    } catch (error) {
      return error;
    }
  }

  ngOnDestroy() {
    this.cleanIntervals();
  }

  /**
   * authenticate and set the current user based on submitted login
   * @param username email associated with user
   * @param password password field
   * @returns true if user is succesfully authenticated and set
   */
  async login(username: string, password: string): Promise<boolean> {
    try {
      await this.logout();
      const authenticated = await this.authenticationService.authenticate(username, password);
      if (authenticated) {
        await this.refreshUser();
      } else {
        return false;
      }
    } catch (error) {
      this.logger.error(error);
    }
    return true;
  }

  async loginWithToken(token: string): Promise<boolean> {
    try {
      //old stuff
      this.tokenService.setToken(token);
      await this.gatherUserTables();
      this.setupUser();
      this.sendUserAuthorizedChangesNoRegistration();

      //new
      await this.refreshUser();
    } catch (error) {
      this.logger.error(error);
      return false;
    }
    return true;
  }

  /**
   * create a user with readable info, add settings and call setCurrentUse
   */
  async refreshUser() {
    try {
      const user: User = await this.authenticationService.getReadableUserInfo();
      user.settings = await this.getUserSettings();
      this.setCurrentUser(user);
    } catch (error) {
      this.logger.error(error);
    }
  }

  /**
   * call api to get settings and insert to an array
   * @returns array of Settings
   */
  async getUserSettings() {
    const settings: Setting[] = [];
    const url = apiKeys.u2.getUserSettings;
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_TYPE: type,
    };

    try {
      const apiResult = await from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).toPromise();
      const apiResultValue = JSON.parse(apiResult.body.value);
      const ids = Object.keys(apiResultValue);

      for (let i = 0; i < ids.length; i++) {
        const settingID: number = parseInt(ids[i]);
        const setting: Setting = new Setting(
          settingID,
          apiResultValue[settingID].Active,
          apiResultValue[settingID].Value
        );
        settings[settingID] = setting;
      }
      return settings;
    } catch (e) {
      return [];
    }
  }

  //TODO
  register() {}

  /**
   * get value of specified setting
   * @param settingID setting enum type
   * @returns string value of setting
   */
  getSettingValue(settingID: SettingID): string {
    try {
      if (this.user.settings[settingID]) {
        return this.user.settings[settingID].getValue();
      } else {
        return null;
      }
    } catch (error) {
      this.logger.error(error);
      throw new Error(error.message);
    }
  }

  /**
   * check whether a setting is active
   * @param settingID setting enum type
   * @returns boolean - active value of setting
   */
  isSettingActive(settingID: SettingID): boolean {
    try {
      if (this.user.settings && this.user.settings[settingID]) {
        return this.user.settings[settingID].isActive();
      } else {
        return false;
      }
    } catch (error) {
      this.logger.error(error);
      throw new Error(error.message);
    }
  }

  /**
   * destroy token and clear web storage via authentication service
   */
  async logout() {
    if (this.isCentralized()) {
      await this.revokeCognitoToken();
      await this.logoutCognito();
      this.destroyCentralization();
    }
    await this.baseCompletionsCacheService.clear();
    this.authenticationService.logout();
    this.idb.clear(StoreType.FORM_TEMPLATES);
  }

  //OLD functions - copied over in order to make can-activate-auth guard work for now

  getUserID() {
    return this.getUserValueFromToken(UtilocateTokenPaths.USERID);
  }

  getUserCategoryID() {
    return this.getUserValueFromToken(UtilocateTokenPaths.USERCATEGORYID);
  }

  getU3UserCategoryID() {
    return this.getUserValueFromToken(UtilocateTokenPaths.U3USERCATEGORYID);
  }

  getUserValueFromToken(key: string): string {
    return this.tokenService.getValueFromToken(key);
  }

  /**
   *
   * @param settingID setting to check
   * @param userID optional - userID for overridden settings
   * @returns a Setting or Error object
   */
  async getSettingValueForUser(settingID: string, userID?: string): Promise<Setting | Error> {
    try {
      const setting: Setting = null;
      // if (!await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbAdmin_Settings)
      //   || !await this.utilocateAdminCacheService.tableCached(ADMIN_TABLE_NAMES.tbUser_SettingOverrides)
      // ) {
      //   await this.adminLookup.refreshLookupTables([
      //     ADMIN_TABLE_NAMES.tbAdmin_Settings,
      //     ADMIN_TABLE_NAMES.tbUser_SettingOverrides
      //   ]);
      // }
      // let setting: Setting | Error = await this.utilocateSettingService.getSetting(settingID, userID);
      // if (!(setting instanceof Error)) {
      //   return setting;
      // } else {
      //   throw setting;
      // }
    } catch (error) {
      return error;
    }
  }

  /**
   * sets token, refreshes table, sets up parts of this service
   * @param token auth token to save
   */
  async loginUserWithToken(token: string) {
    this.tokenService.setToken(token);
    await this.gatherUserTables();
    this.setupUser();
    this.sendUserAuthorizedChangesNoRegistration();
  }

  /**
   * calls refreshLookupTables for relevant tables
   */
  async gatherUserTables() {
    await this.adminLookup.getAdminTables([
      ADMIN_TABLE_NAMES.tbLogin_Users,
      ADMIN_TABLE_NAMES.tbLogin_UserCategories,
      ADMIN_TABLE_NAMES.tbAdmin_Settings,
      ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
    ]);
  }

  /**
   * sets local CurrentNavigationSnapshot variable, sets up user session time and session checker
   */
  setupUser() {
    try {
      this.CurrentNavigationSnapshot = this.route.snapshot.queryParams;
      if (
        parseInt(this.tokenService.getValueFromToken(UtilocateTokenPaths.U3USERCATEGORYID)) ==
        U3_USER_CATEGORY_ID.Utility
      ) {
        this.setUserSesssionTime();
        this.setupUserSessionChecker();
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  //only for utilty user for now
  /**
   * sets User session time in localStorage
   */
  setUserSesssionTime() {
    if (
      parseInt(this.tokenService.getValueFromToken(UtilocateTokenPaths.U3USERCATEGORYID)) == U3_USER_CATEGORY_ID.Utility
    ) {
      localStorage.setItem(localStorageKeys.USER_SESSION, new Date().getTime().toString());
    }
  }

  /**
   * starts interval to check whether session has expired
   */
  private setupUserSessionChecker() {
    this.userSessionInterval = setInterval(() => {
      if (this.isUserSessionExpired()) {
        this.handleExpiredUserSession();
      }
    }, USER_SESSION_INTERVAL_MS);
  }

  /**
   * compares session time and current time for expiry
   * @returns true if user session is over 15 minutes old
   */
  private isUserSessionExpired(): boolean {
    let isExpired = false;
    try {
      const localUserSesssionTime = this.getUserSessionTime();
      if (localUserSesssionTime) {
        const currentTime = new Date().getTime().toString();
        const minutesDiff = Math.abs((parseInt(currentTime) - parseInt(localUserSesssionTime)) / 1000 / 60);
        if (minutesDiff > UTILITY_USER_SESSION_EXPIRY_MINUTES) {
          isExpired = true;
        }
      } else {
        isExpired = true;
      }
    } catch (error) {
      console.error(error.message);
    }
    return isExpired;
  }

  /**
   * alert user and cleanup, return to login
   */
  private handleExpiredUserSession() {
    window.alert('Session Expired - Please Login again.');
    this.cleanIntervals();
    this.logoutUser();
    this.router.navigate(['login'], {
      queryParams: this.CurrentNavigationSnapshot,
    });
  }

  /**
   *
   * @returns session time from local storage
   */
  getUserSessionTime(): any {
    return localStorage.getItem(localStorageKeys.USER_SESSION);
  }

  /**
   * ends repeated intervals (ie for checking session expiry)
   */
  cleanIntervals() {
    try {
      if (this.userSessionInterval) {
        clearInterval(this.userSessionInterval);
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  /**
   * destroy token, save changes, clear cache
   */
  async logoutUser() {
    this.tokenService.destroyToken();
    this.sendUserAuthorizedChanges();
    await this.baseCompletionsCacheService.clear();
    await this.baseDocumentCacheService.clear();
    await this.baseS3DocumentCacheService.clear()
    await this.utilocateAdminCacheService.clear();
    await this.ManifestCacheService.clear();
    await this.baseDocumentQueueCacheService.clear();

    this.idb.clear(StoreType.FORM_TEMPLATES)
  }

  sendUserAuthorizedChanges() {
    this.userAuthorizedSubject.next(this.tokenService.isAuthorized());
  }

  sendUserAuthorizedChangesNoRegistration() {
    this.userAuthorizedSubject.next(this.tokenService.isAuthorized(false));
  }

  canUserCategoryIDCompleteTicket(U2UserCategoryID: number): boolean {
    const canComplete = [U2_USER_CATEGORY_ID.Manager, U2_USER_CATEGORY_ID.SystemAdmin];
    return canComplete.indexOf(U2UserCategoryID) > -1 ? true : false;
  }

  clearCompletionsAdminCacheService() {
    this.baseCompletionsCacheService.clear();
    this.utilocateAdminCacheService.clear();
  }

  clearIDBCache() {
    this.idb.clearAll();
  }

  /**
   * Gets the client bucket from settings. If setting is turned off, it
   * defaults to utilocate.client.docs
   */
  public getClientBucketFromSettings() {
    let bucket = 'utilocate.client.docs';
    try {
      if (this.isSettingActive(SettingID.S3_BUCKET_NAME) == true) {
        const val = this.getSettingValue(SettingID.S3_BUCKET_NAME);
        if (val != null) {
          bucket = val;
        }
      }
    } catch (error) {
      console.error('Failed to get bucket, defaulting to CA bucket');
    }
    return bucket;
  }

  async checkEmail(email: string) {
    const apiKey = apiKeys.competers.checkEmail;
    const url = apiKeys.competers[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_URL:
        'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u4/checkEmail/{email}',
      API_TYPE: type,
      API_URL_DATA_PARAMS: {
        email: email,
      },
      API_URL_TOKEN_PARAMS: {},
    };

    const apiResult = await from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).toPromise();
    const apiResultValue = JSON.parse(apiResult.body.value);

    return apiResultValue;
  }

  storeCognitoTokens(tokens: any) {
    const userToken = tokens.access_token;
    const refreshToken = tokens.refresh_token;
    const idToken = tokens.id_token;

    localStorage.setItem('cognito_user_token', userToken);
    localStorage.setItem('cognito_refresh_token', refreshToken);
    localStorage.setItem('cognito_id_token', idToken);

    sessionStorage.setItem('cognito_user_token', userToken);
    sessionStorage.setItem('cognito_refresh_token', refreshToken);
    sessionStorage.setItem('cognito_id_token', idToken);
  }

  setUserCentralization() {
    sessionStorage.setItem('isCentralized', 'true');
    localStorage.setItem('isCentralized', 'true');
  }

  /**
   * checks session and local storage for centralization
   * @returns true if centralized, false otherwise
   */
  isCentralized(): boolean {
    if (sessionStorage.getItem('isCentralized')) {
      return true;
    }
    if (localStorage.getItem('isCentralized')) {
      return true;
    }
    return false;
  }

  /**
   *
   * @returns Centralization info from localStorage ONLY
   */
  getCentralization() {
    return localStorage.getItem('isCentralized');
  }

  /**
   * deletes saved Centralization info from session and local storage
   */
  destroyCentralization() {
    sessionStorage.removeItem('isCentralized');
    localStorage.removeItem('isCentralized');

    localStorage.removeItem('client_id');

    sessionStorage.removeItem('cognito_user_token');
    sessionStorage.removeItem('cognito_refresh_token');
    sessionStorage.removeItem('cognito_id_token');

    localStorage.removeItem('cognito_user_token');
    localStorage.removeItem('cognito_refresh_token');
    localStorage.removeItem('cognito_id_token');
  }

  async gatherAccounts(token: string) {
    const apiKey = apiKeys.competers.gatherAccounts;
    const url = apiKeys.competers[apiKey];
    const type = api[url].type;

    const utilocateApiRequest: UtilocateApiRequest = {
      API_KEY: url,
      API_URL:
        'https://sphso382q8.execute-api.ca-central-1.amazonaws.com/{stage}/cognitoController/u4/gatherAccounts/{token}',
      API_TYPE: type,
      API_URL_DATA_PARAMS: {
        token: token,
      },
      API_URL_TOKEN_PARAMS: {},
    };

    const apiResult = await from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).toPromise();
    const apiResultValue = JSON.parse(apiResult.body.value);

    return apiResultValue;
  }

  async loginWithCognito(user: any) {
    const token = await this.utilocateApiService.generateTokenU4Cognito(user);
    if (token.includes('Failed')) {
      return null;
    } else {
      return token;
    }
  }

  async revokeCognitoToken() {
    try {
      const refreshToken = localStorage.getItem('cognito_refresh_token');
      const clientID = localStorage.getItem('client_id');

      const cognitoInfo = environment.cognito[clientID];
      const authToken = cognitoInfo.authToken;
      const revokeURL = `${cognitoInfo.domain}/oauth2/revoke`;

      const body = {
        token: refreshToken,
      };

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${authToken}`,
      };

      const result = await axios.post(revokeURL, body, { headers: headers });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Logs out the user from SSO by opening a logout URL in a new window.
   */
  async logoutCognito() {
    try {
      const cognitoClientID = localStorage.getItem('client_id');

      const cognitoInfo = environment.cognito[cognitoClientID];
      const logoutURL = `${cognitoInfo.domain}/logout?`;

      const redirectURI = this.getRedirectURI();

      window.open(
        `${logoutURL}client_id=${cognitoClientID}&logout_uri=${redirectURI}`,
        'myWindow',
        'width=500,height=500'
      );
    } catch (error) {
      console.error(error);
    }
  }

  getRedirectURI() {
    return window.location.href.split('app')[0];
  }
}
