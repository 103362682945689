// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const Tokens = {
  GEO_LOCATOR: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjo3LCJDTElFTlRJRCI6IjU5IiwiVVNFUkNBVEVHT1JZSUQiOjEsIlUzVVNFUiI6eyJVU0VSSUQiOjEzNTYsIlVTRVJDQVRFR09SWUlEIjo4fSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDIwMzA0NDQsImlhdCI6MTYyOTIzMDQ0M30.1sas5n3svFKCArz0Wj7w2eMA_GdInw7uUKs18fqQbmI',
  GEO_MANAGER: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6NSwiVVNFUklEIjoxLCJDTElFTlRJRCI6NTksIlVTRVJDQVRFR09SWUlEIjo2LCJVM1VTRVIiOnsiVVNFUklEIjoyNjMsIlVTRVJDQVRFR09SWUlEIjoyfSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDU3MzY1NTYsImlhdCI6MTYzMjkzNjU1NX0.hjlmhtyKr4Edw6h9h17QRrK5Q3Us5tOQ-kItTG68IY4',
  OTS_LOCATOR: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjozLCJDTElFTlRJRCI6IjI3IiwiVVNFUkNBVEVHT1JZSUQiOjEsIlUzVVNFUiI6eyJVU0VSSUQiOjIzNSwiVVNFUkNBVEVHT1JZSUQiOjh9LCJVWFVTRVIiOmZhbHNlLCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMDc0OSwiaWF0IjoxNjI5MjMwNzQ4fQ.M7JSE5a_DIdP_3On0z3lv76VDCJm8Vyo-PVuHebrWe0',
  OTS_DISPATCH: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoyLCJDTElFTlRJRCI6IjI3IiwiVVNFUkNBVEVHT1JZSUQiOjQsIlUzVVNFUiI6eyJVU0VSSUQiOjI2MSwiVVNFUkNBVEVHT1JZSUQiOjN9LCJVWFVTRVIiOmZhbHNlLCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMDgyNiwiaWF0IjoxNjI5MjMwODI1fQ.1CBqTQuOEuYBBIX6qofT8DMMib4ECxrlu0w8zwDNu6M',
  OTS_MANAGER: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoxLCJDTElFTlRJRCI6IjI3IiwiVVNFUkNBVEVHT1JZSUQiOjYsIlUzVVNFUiI6eyJVU0VSSUQiOjIxOCwiVVNFUkNBVEVHT1JZSUQiOjJ9LCJVWFVTRVIiOmZhbHNlLCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMDk4NCwiaWF0IjoxNjI5MjMwOTgzfQ.m3Iyjxh9guAkYUIbf4dyNmLewwShX7f_wBSNv09BFgY',
  GTEL_DISPATCH: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoxNjQ3MywiQ0xJRU5USUQiOiIxOCIsIlVTRVJDQVRFR09SWUlEIjo0LCJVM1VTRVIiOnsiVVNFUklEIjoyNjIsIlVTRVJDQVRFR09SWUlEIjo5fSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDIwMzEyMjksImlhdCI6MTYyOTIzMTIyOH0.kFQpPejgVjpcXX3nqEj5LIW_2x_TeFpFONYIKru5BMI',
  GTEL_LOCATOR: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjo1NSwiQ0xJRU5USUQiOiIxOCIsIlVTRVJDQVRFR09SWUlEIjoxLCJVM1VTRVIiOnsiVVNFUklEIjoxMzU3LCJVU0VSQ0FURUdPUllJRCI6OH0sIlVYVVNFUiI6ZmFsc2UsIlZFUlNJT04iOiIxLjIuMC4wIiwiYWNjZXNzIjoiYWxsb3cifSwiZXhwIjoxODAyMDMxNDE0LCJpYXQiOjE2MjkyMzE0MTN9.BRHkUz0kr4o9fYfzTbolCR-BgOLPqxWkRNJSlR-yRFA',
  GTEL_MANAGER: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoxLCJDTElFTlRJRCI6IjE4IiwiVVNFUkNBVEVHT1JZSUQiOjYsIlUzVVNFUiI6eyJVU0VSSUQiOjEzNTgsIlVTRVJDQVRFR09SWUlEIjoyfSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDIwMzE0MzUsImlhdCI6MTYyOTIzMTQzNH0.z4slzYF1MmX_7ZDiRIkbtkW8Z-rpfamMQKk8EKGXmTM',
  MULTIVIEW_MANAGER:  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoxLCJDTElFTlRJRCI6IjIzIiwiVVNFUkNBVEVHT1JZSUQiOjYsIlUzVVNFUiI6eyJVU0VSSUQiOjI1NywiVVNFUkNBVEVHT1JZSUQiOjJ9LCJVWFVTRVIiOmZhbHNlLCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMjQxMiwiaWF0IjoxNjI5MjMyNDExfQ.FGKZH3UHeANJcgnk7lFaFZWNJcDYjtcc1fuAWzS_8us',
  MULTIVIEW_DISPATCH: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoyLCJDTElFTlRJRCI6IjIzIiwiVVNFUkNBVEVHT1JZSUQiOjQsIlUzVVNFUiI6eyJVU0VSSUQiOjI2MCwiVVNFUkNBVEVHT1JZSUQiOjN9LCJVWFVTRVIiOmZhbHNlLCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMjQ2MSwiaWF0IjoxNjI5MjMyNDYwfQ.S01PnokieWrXBt4oD-m_VrlxvRHe8n6VMgdCIPAX4Qw',
  MULTIVIEW_LOCATOR: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjozLCJDTElFTlRJRCI6IjIzIiwiVVNFUkNBVEVHT1JZSUQiOjEsIlUzVVNFUiI6eyJVU0VSSUQiOjEzNTksIlVTRVJDQVRFR09SWUlEIjo4fSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDIwMzI1NzIsImlhdCI6MTYyOTIzMjU3MX0.AXiv-gJ0JwZuBSfr3fOvKr5Pincn69awXr8MKG4jrgQ',
  PREMIER_LOCATOR:  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjo3LCJDTElFTlRJRCI6IjUyIiwiVVNFUkNBVEVHT1JZSUQiOjEsIlUzVVNFUiI6eyJVU0VSSUQiOjIwOSwiVVNFUkNBVEVHT1JZSUQiOjZ9LCJVWFVTRVIiOnsiVVNFUklEIjoxMDV9LCJWRVJTSU9OIjoiMS4yLjAuMCIsImFjY2VzcyI6ImFsbG93In0sImV4cCI6MTgwMjAzMDQ3NywiaWF0IjoxNjI5MjMwNDc2fQ.BT4CNsYlxzYUYOh4SKJWvty9KdcadhjrsKyhsY3PGCY',
  TRANSNORTHERN: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MSwiVVNFUklEIjoxLCJDTElFTlRJRCI6IjU3IiwiVVNFUkNBVEVHT1JZSUQiOjYsIlUzVVNFUiI6eyJVU0VSSUQiOjEzNTAsIlVTRVJDQVRFR09SWUlEIjoyfSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDIwMzIyOTAsImlhdCI6MTYyOTIzMjI4OX0.1b6ldxuSHBu-SumPWj6dZaGicnlUyJkfnSECrTUIASo',
  MORNIGTON: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MCwiVVNFUklEIjoxLCJDTElFTlRJRCI6IjYyIiwiVVNFUkNBVEVHT1JZSUQiOjYsIlUzVVNFUiI6ZmFsc2UsIlVYVVNFUiI6ZmFsc2UsIlZFUlNJT04iOiIxLjIuMC4wIiwiYWNjZXNzIjoiYWxsb3cifSwiZXhwIjoxODA0NzA4Mjk2LCJpYXQiOjE2MzE5MDgyOTV9.lPHRYDClDp6wCnFiAGXhgLhBjd-cVd5_ipaQY9Ynijg',
  ORO_MEDONTE: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7IkFQUCI6MCwiVVNFUklEIjo4NDg4MTMsIkNMSUVOVElEIjoiNzgiLCJVU0VSQ0FURUdPUllJRCI6NCwiVTNVU0VSIjpmYWxzZSwiVVhVU0VSIjpmYWxzZSwiVkVSU0lPTiI6IjEuMi4wLjAiLCJhY2Nlc3MiOiJhbGxvdyJ9LCJleHAiOjE4MDQ0MzA4NTksImlhdCI6MTYzMTYzMDg1OH0.sMRwc4pCDtEDU9r8_p_p2cUP3yqXD06Ra4y2w_q-dPI',
}

export const environment = {
  production: false,
  enableDebug: true,
  localhost: false,
  url: null,
  appVersion: require('../../package.json').version + '-dev',
  cognito: {
    'o9ml9hu48ekj1rhjdmf9pvnkg': { //ca-central-1 dev
      authToken: 'bzltbDlodTQ4ZWtqMXJoamRtZjlwdm5rZzpndDQ1cGQ0bHNjNmhjYzg3YmhiMTJqdDRrMzF1ZGwyNGQ5YnMzc3RpOW1kc2dmc3ZkZG4=',
      domain: 'https://utilocate-user-pool-ca-central-test.auth.ca-central-1.amazoncognito.com'
    },
    '6kvreor05lun5p63phl0msvq7g': { //ca-central-1 live
      authToken: 'Nmt2cmVvcjA1bHVuNXA2M3BobDBtc3ZxN2c6YmU0bWU3ZDBkY2d2ampjZXA4cmhvOHFzOTBwYmJmajBmcHVxZjFzMWwwYWFwcm5rYTJ0',
      domain: 'https://utilocate.auth.ca-central-1.amazoncognito.com'
    },
    '34d3jk27loere7i20nn0u5eg31': { //us-east-2 dev
      authToken: 'MzRkM2prMjdsb2VyZTdpMjBubjB1NWVnMzE6YWlzZW44OWlidG1nM3FvbmlyazFqdDQ5YXVrZzFkbjhoNHQ5ODY3ZTFjaGMyZDZldG5j',
      domain:'https://utilocate-user-pool-us-east-2-test.auth.us-east-2.amazoncognito.com'
    },
    '47n03bu0ne1nrji1etgdlasf6t': { //us-east-2 live
      authToken: 'NDduMDNidTBuZTFucmppMWV0Z2RsYXNmNnQ6MWI1a3Fvb25uMGNiY3VlMTBtbXRsZ3JlaGk3OW5vcXVmcWpxMDRkbzlna24xajc5NHBsNg==',
      domain: 'https://utilocate.auth.us-east-2.amazoncognito.com'
    }
  }

  // url: null,
  // url: 'error-403'
  // url: 'error-404'
  // url: 'security-troubleshoot'

  // GEO
  // url: 'scheduler?Authorization=' + Tokens.GEO_MANAGER
  // url: 'create-ticket?Authorization=' + Tokens.GEO_MANAGER
  // url: 'ticket-details?AssignmentID=726&Authorization=' + Tokens.GEO_MANAGER

  // Transnorthern
  // url: 'document-viewer?DocumentID=203&AssignmentID=2&DocumentToAngularID=131&Authorization=' + Tokens.TRANSNORTHERN
  
  // Mornington
  // url: 'document-viewer?DocumentID=5&AssignmentID=13&DocumentToAngularID=2&Authorization=' + Tokens.MORNIGTON

  // OTS
  // url: 'fieldside?Authorization=' + Tokens.OTS_LOCATOR,
  // url: 'esri-viewer?AssignmentID=110503&Authorization=' + Tokens.OTS_LOCATOR
  // url: 'esri-digsite?AssignmentID=726&Authorization=' + Tokens.GEO_MANAGER
  
  //ORO_MEDONTE
  // url: 'esri-viewer?AssignmentID=26&Authorization=' + Tokens.ORO_MEDONTE
};

