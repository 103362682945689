import { Injectable } from '@angular/core';

export interface Bufferable {
  arrayBuffer(): Promise<ArrayBuffer>;
}

@Injectable({
  providedIn: 'root',
})
export class CryptographyService {
  constructor() {}

  async calculateFileSHA256(file: File | Blob): Promise<string> {
    // Read the file as an ArrayBuffer
    const fileBuffer = await file.arrayBuffer();

    // Use the subtle crypto API to create a SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);

    // Convert the hash to a Uint8Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // Convert the Uint8Array to a hex string
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    // Convert the hex string to a Uint8Array for base64 encoding
    const hashUint8 = new Uint8Array(hashHex.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16)));

    // Use btoa to base64 encode the Uint8Array
    return btoa(String.fromCharCode.apply(null, hashUint8));
  }
}
