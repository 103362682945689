import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { from, Observable } from "rxjs";
import { api, apiKeys } from "src/app/ENDPOINTS";
import { AdminLookupService } from "../../core/admin/admin-lookup.service";
import {
  ADMIN_TABLE_NAMES,
  COMPLETION_TABLE_NAMES,
} from "../../core/admin/tables";
import {
  ApiService,
  UtilocateApiRequest,
} from "../../core/api/baseapi.service";
import {
  CacheWhereClause,
  CacheWhereClauseType,
} from "../../core/cache/cache.interface";
import { CacheService, StoreType } from "../../core/cache/cache.service";
import { UtilocateAdminCacheService } from "../../core/cache/utilocate-admin.service";
import { DatetimeService } from "../../core/services/datetime/datetime.service";
import { LoggerService } from "../../core/services/logger/logger.service";
import { NotificationLoadingType } from "../../core/services/logger/Notification";
import { NotificationService } from "../../core/services/logger/notification.service";
import { AutologID } from "../../core/services/user/setting";
import { OptionsFillID } from "../../create-ticket/create-ticket-component.service";
import { FormBuilderService } from "../forms/form-builder.service";
import { DEFAULT_FORM_TEMPLATE_OBJECT } from "../forms/form-input-template/defaultFormTemplate";
import {
  FormTemplateField,
  FormTemplateGroup,
  FormTemplateView,
  FormTemplateViews,
} from "../forms/form-input-template/form-input-template.component";
import { FormHelperService } from "../forms/forn-helper.service";
import {
  InputTypes,
  PreCompletionsDetailRow,
  RecursiveForm,
  RecursiveFormField,
  RecursiveFormGroup,
} from "../forms/recursiveFormOptions";
import {
  TicketTag,
  TicketTagBuilderService,
} from "../ticket-tags/ticket-list-tags/ticket-tags-builder.service";
import {
  LocateStatusID,
  sortObjectArray,
  TicketProtectionActionTypeID,
} from "./ticket-details.module";
import { AutologRow } from "./ticket-details/autologs/ticket-autolog";

@Injectable({
  providedIn: "root",
})
export class TicketDetailsService {
  private nextPreCompletionDetailID: number;
  private nextPreCompletionCategoryID: number;

  private preCompletionCatgories: {};
  private preCompletionFields: {};
  private preCompletionFieldOptions: {};

  constructor(
    private logger$: LoggerService,
    private formBuilder$: FormBuilderService, // custom form builder service
    private formHelper$: FormHelperService,
    private idb: CacheService,
    private admin$: AdminLookupService,
    private utilocateApiService: ApiService,
    private datetime: DatetimeService,
    private notif$: NotificationService,
    private utilocateAdminCacheService: UtilocateAdminCacheService
  ) {}

  async getTicketLatLong(assignmentID) {
    const result = await this.getTicketData(assignmentID);
    let lat = 0.0;
    let lng = 0.0;
    if (
      result &&
      result.tbCompletions_Assignments &&
      result.tbCompletions_Assignments.Data &&
      result.tbCompletions_Assignments.Data.length == 1 &&
      result.tbCompletions_Assignments.Data[0].Latitude != '0.0' &&
      result.tbCompletions_Assignments.Data[0].Longitude != '0.0'
    ) {
      lat = result.tbCompletions_Assignments.Data[0].Latitude;
      lng = result.tbCompletions_Assignments.Data[0].Longitude;
    }
    return {
      lat: lat,
      lng: lng,
    };
  }

  async getTicketAddress(assignmentID) {
    const result = await this.getTicketData(assignmentID);
    let address = '';
    if (
      result &&
      result.tbCompletions_Assignments &&
      result.tbCompletions_Assignments.Data &&
      result.tbCompletions_Assignments.Data.length == 1
    ) {
      const a = result.tbCompletions_Assignments.Data[0];
      address = a.StartHouseNumber + ' ' + a.LocateAddress;
      if (a.LocateCrossStreet) {
        address = address + ', ' + a.LocateCrossStreet;
      }
      address = address + ', ' + a.LocateSubRegionName;
    }
    return address;
  }

  async getAssignmentRow(assignmentID) {
    const result = await this.getTicketData(assignmentID);
    let assignmentRow = null;
    if (
      result &&
      result.tbCompletions_Assignments &&
      result.tbCompletions_Assignments.Data &&
      result.tbCompletions_Assignments.Data.length == 1
    ) {
      assignmentRow = result.tbCompletions_Assignments.Data[0];
    }

    return assignmentRow;
  }

  async getTicketFormGroup(assignmentID, templateTypeToLoad, primaryID = null) {
    try {
      try {
        var ticketResult = await this.downloadTicket(assignmentID, primaryID);
      } catch (error) {
        console.error(error);
      }

      if (ticketResult) {
        // downloadTickets api result
        const ticketTables = ticketResult;
        const assignmentID = ticketTables.tbCompletions_Assignments.Data[0]["AssignmentID"];
        const callTypeID = ticketTables.tbCompletions_Assignments.Data[0]["CallTypeID"];


        const templateResult = await this.getTicketTemplate(templateTypeToLoad, callTypeID).toPromise()
        if (templateResult) {
          // insert into idb
          if (await this.insertTicketDataToIDB(assignmentID, ticketTables)) {
            // apply ticket data data to forms
            this.applyDataToForms(
              {
                ...ticketTables.tbCompletions_Assignments.Data[0],
                ...ticketTables.tbCompletions_Primary.Data[0],
              },
              templateResult,
            );
          } else {
          console.error('Failed to do simple task of insertToIdb');
          }

          return [templateResult, ticketResult];
        } else {
          console.error("Did not load all the form data");
          return [];
        }
      } else {
        console.error("Did not load all the ticket data");
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getTicketFormGroupByRequestNum(requestNumber, templateTypeToLoad) {
    try {
      try {
        var ticketResult = await this.downloadTicketByRequestNum(requestNumber)
      } catch (error) {
        console.error(error);
      }
      if (
        ticketResult == "Failed: Invalid authorization"
      ) {
        //improper utility permissions for request num
        return [ticketResult];
      }
      if (ticketResult) {
        // downloadTickets api result
        const ticketTables = ticketResult;
        const assignmentID = ticketTables.tbCompletions_Assignments.Data[0]["AssignmentID"];
        const callTypeID = ticketTables.tbCompletions_Assignments.Data[0]["CallTypeID"];

        const templateResult = await this.getTicketTemplate(templateTypeToLoad, callTypeID).toPromise()
        if (templateResult) {
          // insert into idb
          if (await this.insertTicketDataToIDB(assignmentID, ticketTables)) {
            // apply ticket data data to forms
            this.applyDataToForms(
              {
                ...ticketTables.tbCompletions_Assignments.Data[0],
                ...ticketTables.tbCompletions_Primary.Data[0],
              },
              templateResult,
            );
          } else {
          console.error('Failed to do simple task of insertToIdb');
          }

          return [templateResult, ticketResult];
        } else {
          console.error("Did not load all the form data");
          return [];
        }
      } else {
        console.error('Did not load all teh data');
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  async updateTicketProtection(assignmentID, ActionTypeID, UserID) {
    const actionResult = {
      result: false,
    };
    try {
      const apiKey = apiKeys.u2.TicketProtection;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: {
          ActionTypeID: ActionTypeID,
          TicketProtectionData: {
            AssignmentID: assignmentID,
            UserID: UserID,
          },
        },
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);

      const resultObj = JSON.parse(result.body.value);

      if (ActionTypeID == TicketProtectionActionTypeID.CHECK_TICKET_PROTECTION) {
        actionResult['result'] = resultObj['inTicketProtection'];
        actionResult['date'] = resultObj['date'];
        actionResult['userID'] = resultObj['userID'];
        actionResult['userFirstName'] = resultObj['userFirstName'];
        actionResult['userLastName'] = resultObj['userLastName'];
      } else if (ActionTypeID == TicketProtectionActionTypeID.ADD_TICKET_PROTECTION) {
        actionResult['result'] = resultObj['addedToTicketProtection'];
      } else if (ActionTypeID == TicketProtectionActionTypeID.REMOVE_TICKET_PROTECTION) {
        actionResult['result'] = resultObj['removedFromTicketProtection'];
      }

      if (resultObj['error']) {
        console.error(resultObj['error']);
      }
    } catch (error) {
      console.error(error);
    }
    return actionResult;
  }

  quickTicketActions_OfficeCompleteTicket(apiValue) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKeys.u2.officeCompleteTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.officeCompleteTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn('Scheduler$: invokeScheduler: failed: ', error);
        subscriber.next(false);
        // this.alert$.stop();
        subscriber.complete();
      }
    });
  }

  quickTicketActions_OfficeOngoingTicket(apiValue) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKeys.u2.officeOngoingTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.officeOngoingTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn('Scheduler$: invokeOngoing: failed: ', error);
        subscriber.next(false);
        // this.alert$.stop();
        subscriber.complete();
      }
    });
  }

  async completeTicketAs(locateStatusID: number, assignmentID: string, userID: string) {
    try {
      const allTables = await this.idb.query(StoreType.COMPLETIONS, assignmentID);
      const completionsAssignments = allTables[COMPLETION_TABLE_NAMES.tbCompletions_Assignments]['Data'][0];
      const completionsPrimary =
        allTables[COMPLETION_TABLE_NAMES.tbCompletions_Primary]['Data'][
          allTables[COMPLETION_TABLE_NAMES.tbCompletions_Primary]['Data'].length - 1
        ];

      completionsAssignments['LocateStatusID'] = locateStatusID;
      await this.updateTicketDetailsData(assignmentID, COMPLETION_TABLE_NAMES.tbCompletions_Assignments, {
        Data: [completionsAssignments],
      });

      if (locateStatusID != LocateStatusID.OFFICE_CANCELLED) {
        completionsPrimary['CompletingLocatorID'] = userID;
        completionsPrimary['DateComplete'] = this.datetime.localDateToDBDateStr(new Date());
        completionsPrimary['TimeIn'] = this.datetime.localDateToDBDateStr(new Date());
        completionsPrimary['TimeOut'] = this.datetime.localDateToDBDateStr(new Date());
        completionsPrimary['Archived'] = 1;
        await this.updateTicketDetailsData(assignmentID, COMPLETION_TABLE_NAMES.tbCompletions_Primary, {
          Data: [completionsPrimary],
        });

        const autologExplaination: string = 'Ticket completed using Utilocate Web App';
        const autologDescID: any = AutologID.MarkedAsCompleted;
        const autolog = new AutologRow(
          assignmentID,
          userID,
          autologDescID,
          autologExplaination,
          this.datetime.localDateToDBDateStr(new Date())
        );
        await this.addNewAutolog(assignmentID, autolog);
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  cancelTicket(apiValue): Observable<any> {
    return new Observable((subscriber) => {
      try {
        // this.alert$.start();
        const url = apiKeys.u2[apiKeys.u2.cancelTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.cancelTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
          } else {
            subscriber.next(false);
          }
          // this.alert$.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn('Scheduler$: invokeScheduler: failed: ', error);
        subscriber.next(false);
        // this.alert$.stop();
        subscriber.complete();
      }
    });
  }

  unCompleteTicket(apiValue) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2[apiKeys.u2.uncompleteTicketAction];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.uncompleteTicketAction,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        // from is observable
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
          if (response.ok) {
            subscriber.next(JSON.parse(response.body.value));
          } else {
            subscriber.next(false);
          }
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn('Scheduler$: invokeScheduler: failed: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  async downloadTicket(AssignmentID, PrimaryID = null) {
    try {
      const apiKey = apiKeys.u2.UtilocateDownloadTicketAll;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_URL_DATA_PARAMS: {
          AssignmentID,
        },
      };

      sessionStorage.setItem('assignmentid', AssignmentID);
      if (PrimaryID != null) {
        utilocateApiRequest.API_KEY = apiKeys.u2.UtilocateDownloadTicketPrimary;
        utilocateApiRequest.API_URL_DATA_PARAMS['PrimaryID'] = PrimaryID;
      }

      try {
        const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
        const resultObj = JSON.parse(result.body.value);

        return resultObj;
      } catch (error) {
        console.error(error);
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async downloadTicketByRequestNum(requestNumber) {
    try {
      const apiKey = apiKeys.u2.UtilocateDownloadTicketRequestNum;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
      };
      try {
        const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
        const resultObj = JSON.parse(result.body.value);

        return resultObj;
      } catch (error) {
        console.error(error);
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async assignTicketToUser(PrimaryID, userIdToAssign) {
    try {
      const ActionID = 4;
      const Action = 'CurrentLocatorID';

      const apiKey = apiKeys.u2.reassignTicketAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      value[PrimaryID] = { Value: userIdToAssign };

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const resultObj = JSON.parse(result.body.value);

      return resultObj;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async reassignTicketToUser(PrimaryID, userIdToAssign) {
    try {
      const ActionID = 1;
      const Action = 'CurrentLocatorID';

      const apiKey = apiKeys.u2.reassignTicketAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      value[PrimaryID] = { Value: userIdToAssign };

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const resultObj = JSON.parse(result.body.value);

      return resultObj;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  getTicketTemplate(formInputTemplateTypeID: number, callTypeID: any): Observable<any> {
    return new Observable((subscriber) => {
      //get clientID
      //collect each promise.
      var views = {};
      var viewPromiseArr = [];
      try {
        this.getTicketTemplateRow(formInputTemplateTypeID, callTypeID)
          .then((template) => {
            if (template && template.views) {
              for (const viewKey in template.views) {
                const view: FormTemplateView = template.views[viewKey];
                viewPromiseArr.push(this.formBuilder$.generateFormGroup(view));
              }
            } else {
              for (const viewKey in DEFAULT_FORM_TEMPLATE_OBJECT.views) {
                const view: FormTemplateView = DEFAULT_FORM_TEMPLATE_OBJECT.views[viewKey];
                viewPromiseArr.push(this.formBuilder$.generateFormGroup(view));
              }
            }
            Promise.all(viewPromiseArr)
              .then((results) => {
                if (results && results.length > 0) {
                  for (const i in results) {
                    views[results[i]['key']] = results[i];
                  }
                }
                subscriber.next(views);
                subscriber.complete();
              })
              .catch((ex) => {
                subscriber.next(false);
                subscriber.complete();
              });
          })
          .catch((error) => {
            this.logger$.error(error.message);
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        this.logger$.error('getTicketTemplate' + error.message);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  async getTicketTemplateRow(formInputTemplateTypeID: number, callTypeID: any) {
    let template: any = false;
    try {
      const apiKey = apiKeys.u2.createTicketController;

      const apiValue = {
        action: 3,
        FormInputTemplateTypeID: formInputTemplateTypeID,
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };

      const apiResult = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const result = apiResult["body"];
      if (result && result.value) {
        template = result.value[0];
        let templates = result.value;
        let callTypeTemplate = templates.find((template: any) => template.callTypeID == callTypeID);
        let defaultTemplate = templates.find((template: any) => template.callTypeID == -1);
        if (callTypeTemplate != null) {
          template = callTypeTemplate;
        } else if (defaultTemplate != null) {
          template = defaultTemplate;
        }
      }

      return template;
    } catch (error) {
      this.logger$.error(error);
      return false;
    }
  }

  async insertTicketDataToIDB(assignmentID, data) {
    try {
      const storeType: StoreType = StoreType.COMPLETIONS;

      const result = await this.idb.insert(storeType, assignmentID, data);
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async addNewAutolog(assignmentID, newAutolog) {
    try {
      const tableName = 'tbCompletions_Autolog';
      const storeType: StoreType = StoreType.COMPLETIONS;

      // get existing data
      const existingData = await this.idb.query(storeType, assignmentID);
      const tableToUpdate = existingData[tableName];
      const dataToUpdate = existingData[tableName]['Data'];

      // update
      dataToUpdate.push(newAutolog);
      tableToUpdate['Data'] = dataToUpdate;

      let metadata = existingData['metadata'];
      if (!metadata) {
        metadata = {};
      }

      metadata = {
        ...metadata,
        bUpdated: true, // tickets with this field will be uploaded
      };

      // set in idb
      const result = await this.idb.insert(storeType, assignmentID, {
        ...existingData,
        metadata,
        [tableName]: tableToUpdate,
      });
      if (result) {
        return tableToUpdate;
      } else {
        throw new Error('Failed to update Autologs table');
      }
      // return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async updateTicketTags(assignmentID, ticketTags) {
    const tableName = 'tbCompletions_AssignmentToTags';
    let existingData = null;
    try {
      existingData = await this.idb.query(StoreType.COMPLETIONS, assignmentID);
    } catch (error) {
      console.error(error);
      return null;
    }

    if (existingData && existingData[tableName] && existingData[tableName]['Data']) {
      let newTableData = existingData[tableName];
      newTableData = {
        ...newTableData,
        Data: ticketTags['Data'],
      };

      // update metadata info to the ticket
      let metadata = existingData['metadata'];
      if (!metadata) {
        metadata = {};
      }

      metadata = {
        ...metadata,
        bUpdated: true, // tickets with this field will be uploaded
      };

      const result = await this.idb.insert(StoreType.COMPLETIONS, assignmentID, {
        ...existingData,
        metadata,
        [tableName]: { ...newTableData },
      });
      return result;
    } else {
      return null;
    }
  }

  async getTicketTags(assignment: string): Promise<TicketTag[] | any> {
    const whereClause: CacheWhereClause = {
      Column: 'AssignmentID',
      Value: assignment,
    };

    const tbCompletions_Assignments: any[] | Error = await this.utilocateAdminCacheService.queryTable(
      COMPLETION_TABLE_NAMES.tbCompletions_Assignments,
      [whereClause]
    );
    const tbCompletions_AssignmentToTags: any[] | Error = await this.utilocateAdminCacheService.queryTable(
      COMPLETION_TABLE_NAMES.tbCompletions_AssignmentToTags,
      [whereClause]
    );

    // console.log(tbCompletions_Assignments);
    // console.log(tbCompletions_AssignmentToTags);

    if (
      !(tbCompletions_AssignmentToTags instanceof Error) &&
      !(tbCompletions_Assignments instanceof Error) &&
      tbCompletions_Assignments.length > 0
    ) {
      const LocateStatusID = tbCompletions_Assignments[0]['LocateStatusID'];
      const CallTypeID = tbCompletions_Assignments[0]['CallTypeID'];
      const TagIDs = tbCompletions_AssignmentToTags.reduce((total, value, key) => {
        if (value && value['TagID'] != null) {
          total.push(value['TagID']);
        }
        return total;
      }, []);

      const whereClause1: CacheWhereClause = {
        Column: 'TagID',
        Value: TagIDs,
        ValueType: CacheWhereClauseType.ARRAY,
      };
      const tbAdmin_TicketTags: any[] | Error = await this.utilocateAdminCacheService.queryTable(
        ADMIN_TABLE_NAMES.tbAdmin_TicketTags,
        [whereClause1]
      );
      if (!(tbAdmin_TicketTags instanceof Error)) {
        const ticketTagsByCallType: TicketTag[] = new TicketTagBuilderService().createTagsFromCallTypeID(CallTypeID);
        const ticketTagsByTable: TicketTag[] = new TicketTagBuilderService().createTicketTagsFromTable(
          tbAdmin_TicketTags,
          TagIDs
        );
        const ticketTagsByLocateStatus: TicketTag[] = new TicketTagBuilderService().createTagsFromLocateStatusID(
          LocateStatusID
        );

        console.log([...ticketTagsByCallType, ...ticketTagsByTable, ...ticketTagsByLocateStatus]);
        return [...ticketTagsByCallType, ...ticketTagsByTable, ...ticketTagsByLocateStatus];
      }
    } else {
      return tbCompletions_AssignmentToTags;
    }
  }

  async updateSavedCompletionsDataToLocalDb(view, table, key, tableName, assignmentID) {
    let result = [];
    const updatePromiseArr = [];
    try {
      const curTable = {
        Data: [],
      };

      if (
        view.formGroup &&
        view.formGroup.value &&
        Object.keys(view.formGroup.value).length > 0 &&
        table &&
        table.length > 0
      ) {
        for (var tableIndex in table) {
          if (table[tableIndex][key]) {
            let value = view.formGroup.value[table[tableIndex][key]];

            if (!(value === undefined)) {
              if (value) {
                if (value === true) {
                  value = 1;
                }
              } else if (value === false) {
                value = 0;
              }

              if (/^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/.test(value)) {
                value = this.reverseFormatTime(value);
              }

              table[tableIndex]['FieldValue'] = value;
              table[tableIndex]['bFieldAdded'] = 1;
            }
          }
        }
      }

      curTable.Data = table;
      // console.group(curTable);
      updatePromiseArr.push(this.updateTicketDetailsData(assignmentID, tableName, curTable));
      result = await Promise.all(updatePromiseArr);
    } catch (error) {
      this.logger$.error(error);
    }
    return result;
  }

  reverseFormatTime(time: string) {
    let formattedTime = time;
    const timeParts = time.split(/:|\s/);
    if (timeParts.length == 3) {
      let hour = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);
      const timeOfDay = timeParts[2].toLocaleLowerCase();

      if (hour >= 0 && hour <= 12 && minutes >= 0 && minutes <= 59 && (timeOfDay == 'am' || timeOfDay == 'pm')) {
        if (timeOfDay == 'pm' && hour != 12) {
          hour = hour + 12;
        } else if (timeOfDay == 'am' && hour == 12) {
          hour = 0;
        }

        let hourStr = hour.toString();
        let minStr = minutes.toString();
        if (hour < 10) {
          hourStr = '0' + hourStr;
        }
        if (minutes < 10) {
          minStr = '0' + minStr;
        }
        formattedTime = hourStr + ':' + minStr;
      }
    }

    return formattedTime;
  }

  async updateSavedDataToLocalDb(assignmentID, formGroup, formTemplateGroups) {
    try {
      // group fields by tables
      const groupedFields = this.getFieldsGroupedByDatabaseTables(formTemplateGroups);

      const formValues = formGroup.getRawValue();
      const updatePromiseArr = [];

      // iterate through tables and update their values
      const tableNames = Object.keys(groupedFields);
      for (let i = 0; i < tableNames.length; i++) {
        const curTable = groupedFields[tableNames[i]];

        // get keys that belong to this table
        const tableKeys = Object.keys(curTable);

        // get values from form-data
        for (let j = 0; j < tableKeys.length; j++) {
          const column = tableKeys[j];
          const valueFromForm = formValues[column];
          curTable[column] = valueFromForm; // update table
        }

        // push non-admin table changes
        if (tableNames[i].indexOf('tbAdmin') == -1) {
          updatePromiseArr.push(this.updateTicketData(assignmentID, tableNames[i], curTable));
        } else {
          this.logger$.log('skipping: ', tableNames[i]);
        }
      }

      const result = await Promise.all(updatePromiseArr);
      return result;
    } catch (error) {
      this.logger$.error(error);
      return [];
    }
  }

  async updateTicketDetailsData(assignmentID, tableName, tableData) {
    try {
      const storeType: StoreType = StoreType.COMPLETIONS;
      const existingData = await this.idb.query(storeType, assignmentID);
      const tableToUpdate = existingData[tableName];

      // update / add metadata info to the ticket
      let metadata = existingData['metadata'];
      if (!metadata) {
        metadata = {};
      }

      metadata = {
        ...metadata,
        bUpdated: true, // tickets with this field will be uploaded
      };

      let newData = {};
      newData = tableData['Data'];
      const updatedTable = {
        ...tableToUpdate,
        Data: newData,
      };

      this.logger$.log({
        ...existingData,
        metadata,
        [tableName]: { ...updatedTable },
      });

      const result = await this.idb.insert(storeType, assignmentID, {
        ...existingData,
        metadata,
        [tableName]: { ...updatedTable },
      });
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // updates data in IDB
  // giving the full table overwrites the existing table
  // giving the table's data merges with existing data
  async updateTicketData(assignmentID, tableName, tableData) {
    try {
      console.log(tableData);
      const storeType: StoreType = StoreType.COMPLETIONS;

      // first get existing data then merge new data
      const existingData = await this.idb.query(storeType, assignmentID);
      const tableToUpdate = existingData[tableName];

      // update / add metadata info to the ticket
      let metadata = existingData['metadata'];
      if (!metadata) {
        metadata = {};
      }

      metadata = {
        ...metadata,
        bUpdated: true, // tickets with this field will be uploaded
      };

      let newData = {};
      // if full table is given (columns and data)
      if (tableData['Data'] && tableData['Columns']) {
        newData = tableData['Data'];
      } else {
        // a json to replace the tableName.Data with
        newData = {
          ...tableToUpdate['Data'][0],
          ...tableData,
        };
      }

      // update actual table
      const updatedTable = {
        ...tableToUpdate,
        Data: [newData],
      };
      console.log(tableData);
      console.log(storeType, assignmentID, {
        ...existingData,
        [tableName]: { ...updatedTable },
        metadata,
      });
      const result = await this.idb.insert(storeType, assignmentID, {
        ...existingData,
        [tableName]: { ...updatedTable },
        metadata,
      });
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getTicketData(assignmentID) {
    try {
      const storeType: StoreType = StoreType.COMPLETIONS;

      const result = await this.idb.query(storeType, assignmentID);
      return result;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  applyDataToForms(data, forms) {
    try {
      this.formBuilder$.applyDataToForms(data, forms);
    } catch (error) {
      console.error(error);
    }
  }

  getFieldsGroupedByDatabaseTables(views) {
    return this.formHelper$.getFieldsGroupedByDatabaseTables(views);
  }

  async prepareToUpload(AssignmentID, UserID, IOwnTicket) {
    let successfulSync = true;

    // get ticket protection
    this.notif$.setSyncUploadFlat('0_0_idb_query', 'Applying Ticket Protection', NotificationLoadingType.loading);
    if (IOwnTicket == false) {
      let checkTPResult = null;
      try {
        checkTPResult = await this.updateTicketProtection(
          AssignmentID,
          TicketProtectionActionTypeID.CHECK_TICKET_PROTECTION,
          UserID
        );
        if (checkTPResult != null && checkTPResult.result != null && checkTPResult.result == false) {
          // no one else has ownership of the ticket
          const updateTPResult = await this.updateTicketProtection(
            AssignmentID,
            TicketProtectionActionTypeID.ADD_TICKET_PROTECTION,
            UserID
          );
          this.notif$.setSyncUploadFlat('0_0_idb_query', 'Applying Ticket Protection', NotificationLoadingType.success);
        } else {
          this.notif$.setSyncUploadFlat('0_0_idb_query', 'Applying Ticket Protection', NotificationLoadingType.fail);

          successfulSync = false;
          return successfulSync;
        }
      } catch (error) {
        console.error(error);
        successfulSync = false;
        return successfulSync;
      }
    } else {
      this.notif$.setSyncUploadFlat('0_0_idb_query', 'Applying Ticket Protection', NotificationLoadingType.success);
    }

    this.notif$.setSyncUploadFlat('0_idb_query', 'Gather local tickets', NotificationLoadingType.loading);
    let completionTableKeys = null;
    try {
      completionTableKeys = await this.idb.queryAll(StoreType.COMPLETIONS);
      if (completionTableKeys) {
        this.notif$.setSyncUploadFlat('0_idb_query', 'Gather local tickets', NotificationLoadingType.success);
      } else {
        this.notif$.setSyncUploadFlat('0_idb_query', 'Gather local tickets', NotificationLoadingType.fail);

        successfulSync = false;
        return successfulSync;
      }
    } catch (error) {
      console.error(error);

      successfulSync = false;
      return successfulSync;
    }

    this.notif$.setSyncUploadFlat('1_data_process', 'Seperate tickets to upload', NotificationLoadingType.loading);
    const ticketsToUpload: any = this.prepareTicketObjToUpload(completionTableKeys);
    this.notif$.setSyncUploadFlat('1_data_process', 'Seperate tickets to upload', NotificationLoadingType.success);

    this.notif$.setSyncUploadFlat(
      '2_upload',
      ['Uploaded... (0/', ticketsToUpload.length, ')'].join(''),
      NotificationLoadingType.loading
    );
    const toUploadPromiseArr = [];
    let toUploadPromiseArrResult = null;
    for (let i = 0; i < ticketsToUpload.length; i++) {
      if (ticketsToUpload[i]) {
        toUploadPromiseArr.push(this.uploadTicketToServer(ticketsToUpload[0]));
      }
    }
    try {
      toUploadPromiseArrResult = await Promise.all(toUploadPromiseArr);
      if (toUploadPromiseArrResult) {
        let ticketFailedToUpload = false;
        for (let i = 0; i < toUploadPromiseArrResult.length; i++) {
          if (toUploadPromiseArrResult[i]) {
            // if successfully uploaded
            this.notif$.setSyncUploadFlat(
              '2_upload',
              ['Uploaded... (', i + 1, '/', ticketsToUpload.length, ')'].join(''),
              ticketFailedToUpload ? NotificationLoadingType.fail : NotificationLoadingType.success
            );
          } else if (toUploadPromiseArrResult[i] == false) {
            successfulSync = false;
            ticketFailedToUpload = true;
            this.notif$.setSyncUploadFlat(
              '2_upload',
              ['Uploaded... (', i + 1, '/', ticketsToUpload.length, ')'].join(''),
              NotificationLoadingType.fail
            );
          }
        }
      }
    } catch (error) {
      console.error(error);

      successfulSync = false;
      return successfulSync;
    }

    this.notif$.setSyncUploadFlat('3_idb_clean', 'Clean local database', 0);
    try {
      await this.idb.clear(StoreType.COMPLETIONS);
    } catch (error) {
      console.error(error);

      successfulSync = false;
      return successfulSync;
    }

    this.notif$.setSyncUploadFlat('3_idb_clean', 'Clean local database', 1);

    return successfulSync;
  }

  async uploadTicketToServer(ticket) {
    try {
      // let idbResult = await this.idb.query(StoreType.COMPLETIONS, assignmentID);
      const apiKey = apiKeys.u2.uploadTicket;

      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: ticket,
      };

      const apiResult = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const result = apiResult.body;

      if (result && result.value) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private prepareTicketObjToUpload(ticketsObj) {
    // tbCompletions_S3Documents is updated by the UploadDocumentS3 lambda so
    // u4 should NOT upload it. U4 should only pull from it.
    const omitTables = ['tbCompletions_S3Documents', 'tbCompletions_Documents'];
    try {
      const ticketsToUpload = [];

      // iterate through tickets to find tickets that have been updated
      const ticketsArr = Object.values(ticketsObj);
      for (let i = 0; i < ticketsArr.length; i++) {
        if (ticketsArr[i] && ticketsArr[i]['metadata'] && ticketsArr[i]['metadata']['bUpdated']) {
          // Remove tbCompletions_S3Documents and tbCompletions_Documents
          // as the lambda is responsible for inserting into these tables
          for (let j = 0; j < omitTables.length; j++) {
            if (ticketsArr[i][omitTables[j]]) {
              delete ticketsArr[i][omitTables[j]];
            }
          }

          //TODO Refactor this. Will be removed.
          try {
            if (ticketsArr[i]['tbCompletions_Assignments']['Data'][0]['AppointmentDate']) {
              ticketsArr[i]['tbCompletions_Assignments']['Data'][0]['AppointmentDate'] =
                this.datetime.localDateToDBDateStr(
                  ticketsArr[i]['tbCompletions_Assignments']['Data'][0]['AppointmentDate']
                );
            } else {
              ticketsArr[i]['tbCompletions_Assignments']['Data'][0]['AppointmentDate '] = null;
            }
          } catch (error) {}

          ticketsToUpload.push(ticketsArr[i]);
        }
      }
      // console.log(ticketsToUpload);
      // throw new Error("Test");
      return ticketsToUpload;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getUtilityIDsFromAux(auxData, PrimaryID) {
    const response = [];
    try {
      if (auxData && auxData.length > 0) {
        auxData.forEach((auxRow) => {
          if (auxRow['PrimaryID'] == PrimaryID) {
            response.push(auxRow.UtilityID);
          }
        });
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return response;
  }

  async getPrimaryID(primaries) {
    let primaryID = null;
    try {
      if (primaries && primaries.length > 0) {
        // get first one that is not archvied.
        for (var i in primaries) {
          if (primaries[i].Archived == 0 || primaries[i].Archived == '0') {
            primaryID = primaries[i].PrimaryID;
            break;
          }
        }
        if (!primaryID) {
          //if all archived sort and get last one
          primaries.sort(sortObjectArray('-PrimaryID'));
          primaryID = primaries[0].PrimaryID;
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return primaryID;
  }

  //Primary Details

  //need to get UtilityID but return utility type because that is what links to primaryCat
  async getUtilityInfo(utilityID) {
    const where = { UtilityID: utilityID };

    let tbAdmin_UtilityResult = null;
    try {
      tbAdmin_UtilityResult = await this.admin$.getLookupTableRows(['tbAdmin_Utilities'], where);
    } catch (error) {
      console.error(error);
      return false;
    }

    if (tbAdmin_UtilityResult && tbAdmin_UtilityResult[0] && tbAdmin_UtilityResult[0]['rows']) {
      return tbAdmin_UtilityResult[0]['rows'];
    } else {
      return false;
    }
  }

  async getPrimaryDetailCategories(utilityRows: any[]) {
    const utilityTypes = [0];
    const primaryCatRows = {
      Primary: [],
      Common: [],
    };

    try {
      utilityRows.forEach((row) => {
        utilityTypes.push(row['UtilityType']);
      });

      const where = { UtilityType: utilityTypes };
      const primaryCatResult = await this.admin$.getLookupTableRows(['tbAdmin_PrimaryDetailsCategories'], where);

      if (primaryCatResult && primaryCatResult[0] && primaryCatResult[0]['rows']) {
        const rows = primaryCatResult[0]['rows'];

        rows.forEach((row) => {
          if (row.UtilityType == 0) {
            primaryCatRows.Common.push(row);
          } else {
            primaryCatRows.Primary.push(row);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
    return primaryCatRows;
  }

  async getPrimaryDetailFields() {
    const primaryFieldDict = {};
    try {
      const primaryDetails = await this.admin$.getAdminTables(['tbAdmin_PrimaryDetailFields']);
      if (primaryDetails && primaryDetails[0] && primaryDetails[0].Data) {
        primaryDetails[0].Data.forEach((row) => {
          if (!primaryFieldDict[row.PrimaryDetailCategoryID]) {
            primaryFieldDict[row.PrimaryDetailCategoryID] = [];
          }
          primaryFieldDict[row.PrimaryDetailCategoryID].push(row);
        });
      }
    } catch (error) {
      console.error(error);
    }
    return primaryFieldDict;
  }

  async getPrimaryDetailFieldRules(primaryCategories: any[]) {
    const result = {};
    try {
      if (primaryCategories && primaryCategories.length > 0) {
        const apiKey = apiKeys.u2.createTicketController;
        const apiValue = {
          action: 1,
          fillOptionID: OptionsFillID.primaryDetailFieldRules,
          PrimaryDetailCategoryID: primaryCategories,
        };
        const url = apiKeys.u2[apiKey];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKey,
          API_TYPE: type,
          API_BODY: apiValue,
        };

        const apiResult = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);

        if (apiResult['body'] && apiResult['body'].value && apiResult['body'].value.length > 0) {
          const val = apiResult['body'].value;
          const len = val.length;
          for (var i = 0; i < len; i++) {
            result[val[i]['PrimaryDetailsFieldID']] = val[i];
          }
        }
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  async getPrimaryFieldOptions() {
    const result = {};
    try {
      const primaryFieldOptions = await this.admin$.getAdminTables(['tbAdmin_PrimaryFieldOptions']);
      if (primaryFieldOptions && primaryFieldOptions[0] && primaryFieldOptions[0].Data) {
        primaryFieldOptions[0].Data.forEach((row) => {
          if (!result[row.PrimaryDetailsFieldID]) {
            result[row.PrimaryDetailsFieldID] = [];
          }
          result[row.PrimaryDetailsFieldID].push(row.OptionValue);
        });
      }
    } catch (error) {
      this.logger$.error(error.message);
    }
    return result;
  }

  convertUtilityToFormTemplate(
    utilityRows,
    primaryCatRows,
    primaryFieldRows,
    fieldRules,
    tbCompletions_PrimaryDetails,
    tableName,
    primaryFieldOptions
  ) {
    const utilityViews: FormTemplateViews = {
      views: {},
    };

    try {
      if (utilityRows && utilityRows.length > 0) {
        utilityRows.forEach((utilityRow) => {
          const currentView: FormTemplateView = {
            header: utilityRow['UtilityName'],
            key: utilityRow['UtilityID'],
            groups: {
              defaultGroup: {
                key: 'noGroupsDefault',
                header: 'Nothing to show',
                groupOrder: 1,
                fields: {
                  defaultField: {
                    key: 'noFieldDefault',
                    appearance: 'fill',
                    label: ' ',
                    placeholder: ' ',
                    inputTypeID: 0,
                    fieldOrder: 1,
                    matches: '',
                    isReadOnly: false,
                    isRequired: false,
                    width_xs: '280px',
                    width_sm: '280px',
                    width_md: '280px',
                    width_lg: '280px',
                    width_xl: '280px',
                    isVisible: true,
                    tableName: tableName,
                  },
                },
              },
            },
          };

          if (primaryCatRows && primaryCatRows.length > 0) {
            //has categories

            primaryCatRows.forEach((cateogryRow) => {
              if (cateogryRow['UtilityType'] == currentView.key) {
                if (currentView.groups['defaultGroup']) {
                  delete currentView.groups['defaultGroup'];
                }

                const groupView: FormTemplateGroup = {
                  key: cateogryRow['PrimaryDetailCategoryID'].toString(),
                  header: cateogryRow['Title'],
                  groupOrder: cateogryRow['PrimaryDetailCategoryID'],
                  hasHiddenValues: false,
                  fields: {
                    defaultField: {
                      key: 'noFieldDefault',
                      appearance: 'fill',
                      label: ' ',
                      placeholder: ' ',
                      inputTypeID: 0,
                      fieldOrder: 1,
                      matches: '',
                      isReadOnly: false,
                      isRequired: false,
                      width_xs: '280px',
                      width_sm: '280px',
                      width_md: '280px',
                      width_lg: '280px',
                      width_xl: '280px',
                      isVisible: true,
                      tableName: tableName,
                    },
                  },
                };

                currentView.groups[cateogryRow['PrimaryDetailCategoryID']] = groupView;

                if (primaryFieldRows && primaryFieldRows.length > 0) {
                  // if true, then it contains hidden fields
                  let hasHiddenValues = false;
                  primaryFieldRows.forEach((fieldRow) => {
                    if (fieldRow['PrimaryDetailCategoryID'] == groupView.key) {
                      let isVisible = false;
                      let isHideShow = false;

                      if (groupView.fields['defaultField']) {
                        delete groupView.fields['defaultField'];
                      }

                      if (fieldRules && fieldRules[fieldRow['PrimaryDetailsFieldID']]) {
                        // should it be in the modal
                        if (fieldRules[fieldRow['PrimaryDetailsFieldID']]['bHideShow']) {
                          // should it be checked off
                          const curCompletionsPrimaryDetails = tbCompletions_PrimaryDetails.find((cur) => {
                            return fieldRow['PrimaryDetailsFieldID'] == cur['PrimaryDetailsFieldID'];
                          });

                          if (
                            curCompletionsPrimaryDetails &&
                            (curCompletionsPrimaryDetails['isUpdated'] || curCompletionsPrimaryDetails['FieldValue'])
                          ) {
                            isVisible = true;
                          }

                          hasHiddenValues = true;
                          isHideShow = true;
                        } else {
                          isVisible = true;
                          isHideShow = false;
                        }
                      } else {
                        isVisible = true;
                      }

                      const fieldView: FormTemplateField = {
                        key: fieldRow['PrimaryDetailsFieldID'].toString(),
                        appearance: 'outline',
                        label: fieldRow['DisplayText'],
                        placeholder: fieldRow['DisplayText'],
                        inputTypeID: fieldRow['FieldTypeID'],
                        fieldOrder: fieldRow['FieldOrder'],
                        matches: '',
                        isReadOnly: false,
                        isRequired: false,
                        width_xs: '100',
                        width_sm: '100',
                        width_md: '50',
                        width_lg: '50',
                        width_xl: '50',
                        isHideShow: isHideShow,
                        isVisible: isVisible,
                        tableName: tableName,
                      };

                      if (primaryFieldOptions[fieldRow['PrimaryDetailsFieldID']]) {
                        fieldView.selectOptions = primaryFieldOptions[fieldRow['PrimaryDetailsFieldID']];
                      }

                      groupView.fields[fieldRow['PrimaryDetailsFieldID']] = fieldView;
                    }
                  });
                  if (hasHiddenValues) {
                    groupView['hasHiddenValues'] = hasHiddenValues;
                  }
                }
              }
            });
          } else {
            //Current view is per utility
            //no primary fields or categories
            if (currentView.groups['defaultGroup']) {
              currentView.groups['defaultGroup']['isEmpty'] = true;
            }
          }
          utilityViews.views[currentView.key] = currentView;
        });
      }
    } catch (error) {
      this.logger$.error('convertUtilityToFormTemplate: ' + error.message);
    }
    return utilityViews;
  }

  async generateFormGroup(view) {
    let generatedView = false;
    try {
      generatedView = await this.formBuilder$.generateFormGroup(view);
    } catch (error) {
      this.logger$.error(error);
      return false;
    }
    return generatedView;
  }

  async getUtilityBilingCategories(billingCatIDs) {
    const where = { UtilityBillingCatID: billingCatIDs };

    let tbAdmin_UtilityBillingCategoriesResult = null;
    try {
      tbAdmin_UtilityBillingCategoriesResult = await this.admin$.getLookupTableRows(
        ['tbAdmin_UtilityBillingCategories'],
        where
      );
    } catch (error) {
      console.error(error);
      return false;
    }

    if (
      tbAdmin_UtilityBillingCategoriesResult &&
      tbAdmin_UtilityBillingCategoriesResult[0] &&
      tbAdmin_UtilityBillingCategoriesResult[0]['rows']
    ) {
      const obj = {};
      tbAdmin_UtilityBillingCategoriesResult[0]['rows'].forEach((row) => {
        obj[row['UtilityBillingCatID']] = row;
      });

      return obj;
    } else {
      return false;
    }
  }

  convertBillingToFormTemplate(utilityRows, fieldObj, auxRows, billingRows, PrimaryID) {
    const utilityViews: FormTemplateViews = {
      views: {},
    };
    const billingName = 'Tracking and Billing';
    try {
      const currentView: FormTemplateView = {
        header: billingName,
        key: billingName,
        groups: {
          defaultGroup: {
            key: 'noGroupsDefault',
            header: 'Nothing to show',
            groupOrder: 1,
            fields: {
              defaultField: {
                key: 'noFieldDefault',
                appearance: 'fill',
                label: ' ',
                placeholder: ' ',
                inputTypeID: 0,
                fieldOrder: 1,
                matches: '',
                isReadOnly: false,
                isRequired: false,
                width_xs: '100',
                width_sm: '100',
                width_md: '50',
                width_lg: '50',
                width_xl: '50',
                isVisible: true,
              },
            },
          },
        },
      };
      if (auxRows && auxRows.length > 0) {
        //has categories
        auxRows.forEach((cateogryRow) => {
          if (cateogryRow['PrimaryID'] == PrimaryID) {
            if (currentView.groups['defaultGroup']) {
              delete currentView.groups['defaultGroup'];
            }
            //get utilityName
            let utilityName = '';
            for (var i in utilityRows) {
              if (utilityRows[i]['UtilityID'] == cateogryRow['UtilityID']) {
                utilityName = utilityRows[i]['UtilityName'];
                break;
              }
            }

            const groupView: FormTemplateGroup = {
              key: cateogryRow['AuxiliaryDetailID'].toString(),
              header: utilityName,
              groupOrder: 1, //TODO needs design.
              hasHiddenValues: false,
              fields: {
                defaultField: {
                  key: 'noFieldDefault',
                  appearance: 'fill',
                  label: ' ',
                  placeholder: ' ',
                  inputTypeID: 0,
                  fieldOrder: 1,
                  matches: '',
                  isReadOnly: false,
                  isRequired: false,
                  width_xs: '100',
                  width_sm: '100',
                  width_md: '50',
                  width_lg: '50',
                  width_xl: '50',
                  isVisible: false,
                },
              },
            };
            currentView.groups[cateogryRow['AuxiliaryDetailID']] = groupView;
            if (billingRows && billingRows.length > 0) {
              // has fields
              const hasHiddenValues = false;
              billingRows.forEach((fieldRow) => {
                if (fieldRow['AuxiliaryDetailID'] == groupView.key) {
                  const isVisible = true;
                  const isHideShow = false;
                  if (groupView.fields['defaultField']) {
                    delete groupView.fields['defaultField'];
                  }

                  let inputTypeID = 1;

                  switch (fieldObj[fieldRow['UtilityBillingCatID']]['DataType']) {
                    case 'boolean':
                      inputTypeID = 1;
                      break;
                    case 'integer':
                      inputTypeID = 3;
                      break;
                    case 'decimal':
                      inputTypeID = 4;
                      break;
                  }
                  const fieldView: FormTemplateField = {
                    key: fieldRow['BillingID'].toString(),
                    appearance: 'outline',
                    label: fieldObj[fieldRow['UtilityBillingCatID']]['FieldTypeCat'],
                    placeholder: fieldObj[fieldRow['UtilityBillingCatID']]['FieldTypeCat'],
                    inputTypeID: inputTypeID,
                    fieldOrder: fieldObj[fieldRow['UtilityBillingCatID']]['FieldOrder'],
                    matches: '',
                    isReadOnly: false,
                    isRequired: false,
                    width_xs: '100',
                    width_sm: '100',
                    width_md: '50',
                    width_lg: '50',
                    width_xl: '50',
                    isHideShow: isHideShow,
                    isVisible: isVisible,
                  };
                  groupView.fields[fieldRow['BillingID']] = fieldView;
                }
              });
              if (hasHiddenValues) {
                groupView['hasHiddenValues'] = hasHiddenValues;
              }
            }
          }
        });
      }
      utilityViews.views[currentView.key] = currentView;
    } catch (error) {
      this.logger$.error('convertUtilityToFormTemplate: ' + error.message);
    }

    // console.log(utilityViews)
    return utilityViews;
  }

  // Pre Completion Functions

  async getPreCompletionsCategoryTable() {
    const tables = await this.admin$.getAdminTables([
      'tbAdmin_PreCompletionCategories',
      'tbAdmin_PreCompletionFields',
      'tbAdmin_PreCompletionFieldOptions',
    ]);
    const categoryRows = tables[0].Data;
    const fieldRows = tables[1].Data;
    const fieldOptionRows = tables[2].Data;

    this.preCompletionCatgories = {};
    this.preCompletionFieldOptions = {};
    this.preCompletionFields = {};

    for (const i in categoryRows) {
      const row = categoryRows[i];
      let id = 0;
      if (row.ParentCategoryID) {
        id = row.ParentCategoryID;
      }

      if (!this.preCompletionCatgories[id]) {
        this.preCompletionCatgories[id] = [];
      }
      this.preCompletionCatgories[id].push(row);
    }

    for (const i in fieldRows) {
      const row = fieldRows[i];
      const id = row.PreCompletionCategoryID;
      if (!this.preCompletionFields[id]) {
        this.preCompletionFields[id] = [];
      }
      this.preCompletionFields[id].push(row);
    }

    for (const i in fieldOptionRows) {
      const row = fieldOptionRows[i];
      const id = row.PreCompletionFieldID;
      if (!this.preCompletionFieldOptions[id]) {
        this.preCompletionFieldOptions[id] = [];
      }
      this.preCompletionFieldOptions[id].push(row);
    }
  }

  async getPreCompletionFields(preCompletionCategoryIDs: any[]): Promise<object> {
    let fields = {};
    let fieldResults = [];

    try {
      for (const i in preCompletionCategoryIDs) {
        const preCompletionCategoryID = preCompletionCategoryIDs[i];

        if (this.preCompletionFields[preCompletionCategoryID]) {
          fieldResults = [...fieldResults, ...this.preCompletionFields[preCompletionCategoryID]];
        }
      }

      if (fieldResults && fieldResults.length > 0) {
        fields = {};
        for (const i in fieldResults) {
          fields[fieldResults[i]['PreCompletionFieldID']] = fieldResults[i];
        }
      }
    } catch (error) {
      console.error(error);
    }

    if (fields) {
      return fields;
    } else {
      return null;
    }
  }

  getParentCategories(categoryIDs) {
    const categories = [];
    for (const i in this.preCompletionCatgories[0]) {
      const row = this.preCompletionCatgories[0][i];
      if (categoryIDs.includes(row.PreCompletionCategoryID)) {
        categories.push(row);
      }
    }
    return categories;
  }

  async getPreCompletionCategories(callTypeID: number): Promise<object> {
    await this.getPreCompletionsCategoryTable();

    let categories = {};

    try {
      // get the top level categoryIDs for the ticket
      const calltypeToCategoryResult = await this.admin$.getLookupTableRows(
        ['tbAdmin_CallTypeToPreCompletionCategory'],
        { CallTypeID: callTypeID }
      );

      if (
        calltypeToCategoryResult &&
        calltypeToCategoryResult[0] &&
        calltypeToCategoryResult[0].rows &&
        calltypeToCategoryResult[0].rows.length > 0
      ) {
        const categoryIDs = [];
        for (const i in calltypeToCategoryResult[0]['rows']) {
          categoryIDs.push(calltypeToCategoryResult[0]['rows'][i]['PreCompletionCategoryID']);
        }

        // get the rows for the top level categories
        const categoryResults = this.getParentCategories(categoryIDs);
        categories = {};

        // add the top level categories tot he dict of categories
        if (categoryResults && categoryResults.length > 0) {
          for (const i in categoryResults) {
            categories[categoryResults[i]['PreCompletionCategoryID']] = categoryResults[i];
          }
        }

        // get all of the sub categories of the top level categories and add them to the categories dict
        categories = {
          ...categories,
          ...(await this.getSubCategoryIDs(categoryIDs)),
        };
      }
    } catch (error) {
      console.error(error);
    }

    if (categories) {
      return categories;
    } else {
      return null;
    }
  }

  async getSubCategoryIDs(categoryIDs: any[]): Promise<object> {
    let subCategoryIDs: object = {};
    try {
      let categoryResult = [];
      for (const i in categoryIDs) {
        const parentPreCompletionCategoryID = categoryIDs[i];

        if (this.preCompletionCatgories[parentPreCompletionCategoryID]) {
          categoryResult = [...categoryResult, ...this.preCompletionCatgories[parentPreCompletionCategoryID]];
        }
      }

      if (categoryResult && categoryResult.length > 0) {
        for (const i in categoryResult) {
          subCategoryIDs[categoryResult[i]['PreCompletionCategoryID']] = categoryResult[i];
        }

        subCategoryIDs = {
          ...subCategoryIDs,
          ...(await this.getSubCategoryIDs(Object.keys(subCategoryIDs).map(Number))),
        };
      }
    } catch (error) {
      console.error(error);
    }

    return subCategoryIDs;
  }

  async convertPreCompletionsToFormTemplate(categoryObj, tbCompletions_PreCompletionDetails): Promise<RecursiveForm> {
    try {
      const preCompletionObj: RecursiveForm = {
        groups: {},
        formGroups: {},
      };

      for (const key in categoryObj) {
        const getCategoriesResult = await this.createCategoryView(categoryObj[key], tbCompletions_PreCompletionDetails);
        preCompletionObj.groups[key] = getCategoriesResult['group'];
        preCompletionObj.formGroups = {
          ...preCompletionObj.formGroups,
          ...getCategoriesResult['formGroups'],
        };
      }

      return preCompletionObj;
    } catch (error) {
      console.error(error);
    }
  }

  async createCategoryView(category: object, tbCompletions_PreCompletionDetails): Promise<Object> {
    let group: RecursiveFormGroup = null;
    let formGroups: { [key: string]: FormGroup } = {};
    try {
      group = {
        key: category['PreCompletionCategoryInstanceID'].toString(),
        categoryID: category['PreCompletionCategoryID'],
        header: category['CategoryName'],
        groupOrder: category['FieldOrder'],
        hasHiddenValues: false,
        hasBeenRemoved: category['bFieldRemoved'] == 1,
        formGroup: null,
        minInstances: category['MinInstances'],
        maxInstances: category['MaxInstances'],
        numInstances: 1,
      };

      let hasHiddenValues: boolean = false;
      let hasCategoriesToAdd: boolean = false;
      const catInstanceCount = {};
      const catRows = await this.getPossibleSubCategories(group.categoryID);

      if (category['Fields'] && Object.keys(category['Fields']).length > 0) {
        group.fields = {};
        for (const fieldKey in category['Fields']) {
          const field = category['Fields'][fieldKey];
          let isHideShow: boolean = false;
          let isVisible: boolean = true;

          if (field['bHideShow']) {
            // should it be checked off
            const cuPreCompletionDetails = tbCompletions_PreCompletionDetails.find((cur) => {
              return field['PreCompletionFieldID'] == cur['PreCompletionFieldID'];
            });

            if (
              cuPreCompletionDetails &&
              cuPreCompletionDetails['bFieldHidden'] != 1 &&
              !cuPreCompletionDetails['FieldValue']
            ) {
              isVisible = false;
            }

            hasHiddenValues = true;
            isHideShow = true;
          }

          const fieldView: RecursiveFormField = {
            appearance: 'outline',
            inputTypeID: field['FieldTypeID'],
            label: field['DisplayText'],
            key: fieldKey.toString(),
            placeholder: field['DisplayText'],
            isReadOnly: false,
            isRequired: false,
            width_xs: '100',
            width_sm: '100',
            width_md: '50',
            width_lg: '50',
            width_xl: '50',
            fieldOrder: field['FieldOrder'],
            isVisible: isVisible,
            isHideShow: isHideShow,
          };

          if (fieldView.inputTypeID == InputTypes.Singleselect || fieldView.inputTypeID == InputTypes.Multiselect) {
            const options = await this.getFieldOptions(field['PreCompletionFieldID']);
            fieldView.selectOptions = options;
          }

          group.fields[fieldKey] = fieldView;
        }
        group.hasHiddenValues = hasHiddenValues;
      }

      if (category['SubCategories'] && Object.keys(category['SubCategories']).length > 0) {
        group.groups = {};

        for (const catKey in category['SubCategories']) {
          const subCatReturn = await this.createCategoryView(
            category['SubCategories'][catKey],
            tbCompletions_PreCompletionDetails
          );

          const curCategory: RecursiveFormGroup = subCatReturn['group'];
          if (!curCategory.hasBeenRemoved) {
            if (!catInstanceCount[curCategory.categoryID]) {
              catInstanceCount[curCategory.categoryID] = 1;
            } else {
              catInstanceCount[curCategory.categoryID]++;
            }
          }

          group.groups[catKey] = subCatReturn['group'];
          formGroups = { ...formGroups, ...subCatReturn['formGroups'] };
        }

        // set num instnaces
        for (const key in group.groups) {
          group.groups[key].numInstances = catInstanceCount[group.groups[key].categoryID];
        }
      }

      // check if there are categories that can be added
      for (const key in catRows) {
        const row = catRows[key];

        if (catInstanceCount[key] == null) {
          catInstanceCount[key] = 0;
        }

        if (
          row['MaxInstances'] == 0 ||
          (row['MaxInstances'] > row['MinInstances'] && row['MaxInstances'] > catInstanceCount[key])
        ) {
          hasCategoriesToAdd = true;
          break;
        }
      }

      if (!group.hasHiddenValues) {
        group.hasHiddenValues = hasCategoriesToAdd;
      }

      // make the form group
      group = await this.formBuilder$.generateFormGroupPreCompletions(group);
      if (group.formGroup) {
        formGroups[group.key] = group.formGroup;
      }
    } catch (error) {
      console.error(error);
    }
    return {
      group: group,
      formGroups: formGroups,
    };
  }

  async getFieldOptions(fieldID) {
    const options = [];
    try {
      const result = this.preCompletionFieldOptions[fieldID];

      if (result && result.length > 0) {
        for (const i in result) {
          options.push(result[i]['OptionValue']);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return options;
  }

  async getPossibleSubCategories(categoryID) {
    try {
      const categoryResults = this.preCompletionCatgories[categoryID];
      const categories = {};

      // add the top level categories tot he dict of categories
      if (categoryResults && categoryResults.length > 0) {
        for (const i in categoryResults) {
          if (categoryResults[i]['Archived'] == 0) {
            categories[categoryResults[i]['PreCompletionCategoryID']] = categoryResults[i];
          }
        }
      }
      return categories;
    } catch (error) {
      console.error(error);
    }
  }

  async getAddableCategories(category: RecursiveFormGroup) {
    let categories = {};
    try {
      categories = await this.getPossibleSubCategories(category.categoryID);
      if (category.groups) {
        for (const key in category.groups) {
          const subCategory = category.groups[key];
          if (subCategory.numInstances == subCategory.maxInstances) {
            if (categories[subCategory.categoryID]) {
              delete categories[subCategory.categoryID];
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return categories;
  }

  async addPreCompletionCategory(
    primaryID,
    parentInstanceID,
    rows,
    tbCompletions_PreCompletionDetails,
    tbCompletions_PreCompletionCategories
  ) {
    try {
      this.nextPreCompletionDetailID = this.getNextHighestPreCompletionDetailID(tbCompletions_PreCompletionDetails);
      this.nextPreCompletionCategoryID = this.getNextHighestPreCompletionCategoryID(
        tbCompletions_PreCompletionCategories
      );

      let newRows = {
        tbCompletions_PreCompletionDetails: [],
        tbCompletions_PreCompletionCategories: [],
      };

      for (const i in rows) {
        newRows = {
          ...newRows,
          ...(await this.createPreCompletionsCategoryRows(primaryID, parentInstanceID, rows[i], newRows)),
        };
      }

      return newRows;
    } catch (error) {
      console.error(error);
    }
  }

  async createPreCompletionsCategoryRows(priamryID, parentInstanceID, categoryRow, allRowsObj): Promise<any> {
    try {
      const preCompletionCategoryRow = {
        PreCompletionCategoryInstanceID: this.nextPreCompletionCategoryID,
        PrimaryID: priamryID,
        ParentCategoryInstanceID: parentInstanceID,
        PreCompletionCategoryID: categoryRow.PreCompletionCategoryID,
        bFieldAdded: 1,
        bFieldRemoved: 0,
      };

      allRowsObj.tbCompletions_PreCompletionCategories.push(preCompletionCategoryRow);

      this.nextPreCompletionCategoryID++;

      const subCategoryResults = await this.admin$.getLookupTableRows(['tbAdmin_PreCompletionCategories'], {
        ParentCategoryID: [categoryRow.PreCompletionCategoryID],
      });

      const fieldResults = await this.admin$.getLookupTableRows(['tbAdmin_PreCompletionFields'], {
        PreCompletionCategoryID: [categoryRow.PreCompletionCategoryID],
      });

      if (
        subCategoryResults &&
        subCategoryResults[0] &&
        subCategoryResults[0].rows &&
        subCategoryResults[0].rows.length > 0
      ) {
        for (const i in subCategoryResults[0].rows) {
          const row = subCategoryResults[0].rows[i];
          for (let ii = 0; ii < row.MinInstances; ii++) {
            await this.createPreCompletionsCategoryRows(
              priamryID,
              preCompletionCategoryRow.PreCompletionCategoryInstanceID,
              row,
              allRowsObj
            );
          }
        }
      }

      if (fieldResults && fieldResults[0] && fieldResults[0].rows && fieldResults[0].rows.length > 0) {
        for (const i in fieldResults[0].rows) {
          const row = fieldResults[0].rows[i];
          const preCompletionDetailRow = this.createPreCompletionsDetailRow(
            priamryID,
            row.PreCompletionFieldID,
            preCompletionCategoryRow.PreCompletionCategoryInstanceID
          );
          allRowsObj.tbCompletions_PreCompletionDetails.push(preCompletionDetailRow);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return allRowsObj;
  }

  createPreCompletionsDetailRow(priamryID, preCompletionFieldID, categoryInstanceID): PreCompletionsDetailRow {
    const preCompletionDetailRow: PreCompletionsDetailRow = {
      PreCompletionDetailID: this.nextPreCompletionDetailID,
      PrimaryID: priamryID,
      PreCompletionFieldID: preCompletionFieldID,
      PreCompletionCategoryInstanceID: categoryInstanceID,
      FieldValue: '',
      bFieldHidden: 0,
      bFieldAdded: 1,
      bFieldRemoved: 0,
      bFieldModified: 0,
    };
    this.nextPreCompletionDetailID++;
    return preCompletionDetailRow;
  }

  getNextHighestPreCompletionDetailID(tbCompletions_PreCompletionDetails) {
    let highestID = 0;
    try {
      tbCompletions_PreCompletionDetails.forEach((row) => {
        if (highestID < row.PreCompletionDetailID) {
          highestID = row.PreCompletionDetailID;
        }
      });
    } catch (error) {
      console.error(error);
    }
    return highestID + 1;
  }

  getNextHighestPreCompletionCategoryID(tbCompletions_PreCompletionCategories) {
    let highestID = 0;
    try {
      tbCompletions_PreCompletionCategories.forEach((row) => {
        if (highestID < row.PreCompletionCategoryInstanceID) {
          highestID = row.PreCompletionCategoryInstanceID;
        }
      });
    } catch (error) {
      console.error(error);
    }
    return highestID + 1;
  }

  async updateSavedPreCompletionsDataToLocalDB(
    assignmentID,
    formGroups,
    tbCompletions_PreCompletionDetails,
    tbCompletions_PreCompletionCategories
  ) {
    try {
      const detailsTable = {
        Data: [],
      };

      const categoriesTable = {
        Data: tbCompletions_PreCompletionCategories,
      };

      // update the Field Values
      if (
        formGroups &&
        Object.keys(formGroups).length > 0 &&
        tbCompletions_PreCompletionDetails &&
        tbCompletions_PreCompletionDetails.length > 0
      ) {
        for (var tableIndex in tbCompletions_PreCompletionDetails) {
          if (tbCompletions_PreCompletionDetails[tableIndex]['PreCompletionDetailID']) {
            // the value will be in the form group that is linked to the PreCompletionCategoryInstanceID
            const formGroup =
              formGroups[tbCompletions_PreCompletionDetails[tableIndex]['PreCompletionCategoryInstanceID']];

            let value = formGroup.value[tbCompletions_PreCompletionDetails[tableIndex]['PreCompletionDetailID']];

            if (!(value === undefined)) {
              if (value) {
                if (value === true) {
                  value = 1;
                } else if (Array.isArray(value)) {
                  let tmpValue = '';
                  value.forEach((option) => {
                    if (tmpValue.length > 0) {
                      tmpValue += ', ';
                    }
                    tmpValue += option;
                  });
                  value = tmpValue;
                }
              } else if (value === false) {
                value = 0;
              }

              tbCompletions_PreCompletionDetails[tableIndex]['FieldValue'] = value;
              tbCompletions_PreCompletionDetails[tableIndex]['bFieldModified'] = 1;
            }
          }
        }
      }

      detailsTable.Data = tbCompletions_PreCompletionDetails;
      await this.updateTicketDetailsData(assignmentID, 'tbCompletions_PreCompletionDetails', detailsTable);
      await this.updateTicketDetailsData(assignmentID, 'tbCompletions_PreCompletionCategories', categoriesTable);
    } catch (error) {
      this.logger$.error(error);
    }
  }

  // Clear Options

  callClearTicket(clearTypeID: number, primaryID: number | string) {
    return new Observable((subscriber) => {
      try {
        // this.alert$.start();
        const url = apiKeys.u2[apiKeys.u2.clearTicket];
        const type = api[url].type;

        const utilocateApiRequest: UtilocateApiRequest = {
          API_KEY: apiKeys.u2.clearTicket,
          API_TYPE: type,
          API_URL_DATA_PARAMS: {
            PrimaryID: primaryID,
            ClearTypeID: clearTypeID,
          },
        };

        // from is observable
        from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
          if (response.ok) {
            subscriber.next(response.body);
          } else {
            subscriber.next(false);
          }
          // this.alert$.stop();
          subscriber.complete();
        });
      } catch (error) {
        this.logger$.warn('TicketDetails$: callClearTicket: failed: ', error);
        subscriber.next(false);
        // this.alert$.stop();
        subscriber.complete();
      }
    });
  }

  async getClearOptions(userCategoryID: number | string, utilityIDs: number[]) {
    const clearOptions = [];
    try {
      const tables = await this.admin$.getAdminTables(['tbAdmin_ClearOptions']);

      if (tables && tables.length == 1) {
        const tbAdmin_ClearOptions = tables[0].Data;
        for (const i in tbAdmin_ClearOptions) {
          const clearOption = tbAdmin_ClearOptions[i];

          // Check if the clear option can be used on any of the tickets utilities
          const allowedUtilityIDs = clearOption.UtilityIDs.split(',');
          let utilityAllowed = false;
          for (const i in utilityIDs) {
            if (allowedUtilityIDs.includes(utilityIDs[i].toString())) {
              utilityAllowed = true;
              break;
            }
          }

          // Add permitted clear options to list
          if (clearOption.UserCategoryID == userCategoryID && utilityAllowed) {
            clearOptions.push({
              ClearTypeID: clearOption.ClearTypeID,
              Description: clearOption.Description,
            });
          }
        }
      }
    } catch (error) {
      this.logger$.error(error);
    }
    return clearOptions;
  }

  isU2TicketComplete(locateStatusID: string | number) {
    try {
      var input = locateStatusID;
      if (typeof locateStatusID == 'string') {
        input = parseInt(locateStatusID, 10);
      }

      if (
        locateStatusID == LocateStatusID.LOCATE_COMPLETED ||
        locateStatusID == LocateStatusID.OFFICE_CANCELLED ||
        locateStatusID == LocateStatusID.ARCHIVED
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getLSPs(assignmentID, primaryID: unknown, aux: unknown) {
    let lsps = {};
    try {
      const utilityIDs = this.getUtilityIDsFromAux(aux, primaryID);
      const apiKey = apiKeys.u2.LSPController;
      const apiValue = {
        query: {
          AssignmentID: assignmentID,
          PrimaryID: primaryID,
          utilityIDs: utilityIDs,
        },
      };
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: apiValue,
      };
      // lsps = { "utilityLSPs": [{ 'LSPID': '1', 'Name': 'PVS' }, { 'LSPID': '2', 'Name': 'UnderPressure' }] }
      const apiResult = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      if (apiResult['body'] && apiResult['body']['result']) {
        lsps = apiResult['body']['result'];
      }
    } catch (error) {
      console.error(error);
    }
    return lsps;
  }

  async sendToLSP(PrimaryID, lspID) {
    try {
      const apiKey = apiKeys.u2.sendToLSPAction;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const value = {};
      value[PrimaryID] = { lspID: lspID, CompletionActionID: 1 };

      const utilocateApiRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: value,
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest);
      const resultObj = JSON.parse(result.body.value);

      return resultObj;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
