import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { TicketService } from '../../../../modules/shared/ticket/ticket.service';
import { UserService } from '../../../../modules/core/services/user/user.service';
import { NavBarService } from '../../../../modules/shared/ticket/services/router/nav-bar.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { environment } from 'src/environments/environment';
import { TicketDocumentsService } from 'src/app/modules/shared/ticket/ticket-documents/ticket-documents.service';
import { CompletionQueueService } from '../../../../modules/shared/ticket/services/completionQueue/completion-queue.service';

export class NavBarConfig {
  Title: string;
  returnUrl: string;
  showSyncBadge: boolean;
  onSyncClick: Subject<any>;
  onCreateAuditClick: Subject<any>;
  showFilter: boolean;
  showRouting: boolean;

  constructor(
    title: string,
    returnUrl: string,
    showSyncBadge: boolean,
    onSyncClick?: Subject<any>,
    onCreateAuditClick?: Subject<any>,
    showFilter?: boolean,
    showRouting?: boolean
  ) {
    this.Title = title;
    this.returnUrl = returnUrl;
    this.showSyncBadge = showSyncBadge;
    this.onSyncClick = onSyncClick;
    this.onCreateAuditClick = onCreateAuditClick;
    this.showFilter = showFilter;
    this.showRouting = showRouting;
  }
}

@Component({
  selector: "app-fieldside-navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"],
})
export class NavigationBarComponent implements OnInit {
  @Output() toggleSidenav = new EventEmitter<void>();

  // services
  private completionsQueueService = inject(CompletionQueueService);

  public doesHaveReturnUrl = false;
  public returnUrl = "fieldside/";
  public appToolbarTitle = "TICKET SUMMARY";
  public showFilter = true;
  public showRouting = false;

  hasMenuItems = true;
  showSyncBadge: boolean;
  onSyncClick: Subject<any>;
  onCreateAuditClick: Subject<any>;
  onResetDBClick: Subject<any>;
  onSignOutClick: Subject<any>;
  listFilter = "type";
  routeFilter = "route";
  allowCreateAudit: any;
  ticketModalOpen: boolean = false
  name$: Observable<string>;
  userCategory$: Observable<string>;
  initials$: Observable<string>;
  appVersion: string = environment.appVersion;

  protected _syncBusy$ = new BehaviorSubject(true);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private navBarChanges: NavBarService,
    private route: ActivatedRoute,
    private ticketService: TicketService,
    private logger: LoggerService,
    protected ticketDocumentService: TicketDocumentsService
  ) {}

  async ngOnInit() {
    this.completionsQueueService.isProcessing$.subscribe((isProcessing) => {
      this._syncBusy$.next(isProcessing);
    })

    this.navBarChanges.getNavBarChanges().subscribe((nextVal: NavBarConfig) => {
      this.onSyncClick = nextVal.onSyncClick;
      if(this.route.snapshot.firstChild.routeConfig.path != 'ticket-summary' || this.doesHaveReturnUrl){
        this.appToolbarTitle = nextVal.Title;
      }
      this.showFilter = nextVal.showFilter;
      this.showRouting = nextVal.showRouting;

      if (nextVal.returnUrl && this.route.snapshot.firstChild.routeConfig.path != 'ticket-summary') {
        this.returnUrl = nextVal.returnUrl;
        this.doesHaveReturnUrl = true;
      } else {
        this.doesHaveReturnUrl = false;
      }

      if (nextVal.onCreateAuditClick != null) {
        this.hasMenuItems = true;
      } else {
        this.hasMenuItems = false;
      }

      this.onCreateAuditClick = nextVal.onCreateAuditClick;

      this.showSyncBadge = nextVal.showSyncBadge;

      if (this.appToolbarTitle == "PUNCH IN/OUT") {
        this.toggleSidenav.emit();
      }
    });
    this.allowCreateAudit = this.userService.isSettingActive(
      SettingID.ALLOW_CREATE_AUDIT_TICKETS,
    );

    this.name$ = this.userService.currentUser$.pipe(
      map((val) => this.getName(val))
    );
    this.userCategory$ = this.userService.currentUser$.pipe(
      map((val) => this.getUserCategory(val))
    );
    this.initials$ = this.userService.currentUser$.pipe(
      map((val) => this.getInitials(val))
    );
  }

  createTicket() {
    this.ticketService.createAuditTicketModal(this.showFilter);
  }

  public resetLocalDatabase() {
    this.userService.logoutUserAndDeregister();
    this.router.navigate(["login"]);
  }


  openSelfAssign = () => {
    this.router.navigate(["fieldside/self-assign"]);
  };


  getName(user) {
    let name = "";
    try {
      if (user && user.firstName) {
        name = `${user.firstName} ${user.lastName}`;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return name;
  }

  getUserCategory(user) {
    let category = "";
    try {
      if (user && user.category) {
        category = user.category;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return category;
  }

  getInitials(user) {
    let initials = "";
    try {
      if (user && user.firstName) {
        initials = `${user.firstName.substring(0, 1)}${user.lastName.substring(
          0,
          1
        )}`;
      }
    } catch (error) {
      this.logger.error(error.message);
    }
    return initials;
  }

  public logout() {
    this.userService.logoutUser();
    this.router.navigate(["login"]);
  }

  public onFilter(order) {
    this.navBarChanges.filterBtnClickSubject.next(order);
  }

  public onSync() {
    this.onSyncClick.next(null);
  }

  public onCreateAudit() {
    this.onCreateAuditClick.next(null);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
