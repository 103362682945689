import { inject, Injectable, OnDestroy } from '@angular/core';
import { CacheService } from '../../../../core/cache/cache.service';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { SnackbarType } from '../../../snackbar/snackbar/snackbar';
import { TicketSyncService } from '../ticket-sync/ticket-sync.service';
import { Mutex } from '../../../../../shared/classes/Mutex';
import { LoggerService } from '../logger/logger.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseCompletionCacheService } from '../../../../core/cache/cache.interface';
import { TicketDocumentsService } from '../../ticket-documents/ticket-documents.service';

@Injectable({
  providedIn: 'root',
})
export class CompletionQueueService implements OnDestroy {
  // constants
  private readonly DB_NAME = 'completionQueue';
  private readonly DB_QUEUE_TABLE_NAME = 'queue';
  private readonly DB_ERROR_TABLE_NAME = 'errors';

  // services
  private loggerService = inject(LoggerService);
  private cacheService = inject(CacheService);
  private snackbarService = inject(SnackbarService);
  private ticketSyncService = inject(TicketSyncService);
  private ticketDocumentService = inject(TicketDocumentsService);
  private fieldSideAssignmentService = inject(BaseCompletionCacheService);

  // members
  private queues: Record<string, Array<CompletionQueueItem>>;

  private mu = new Mutex();
  private _isProcessing$ = new BehaviorSubject<boolean>(false);
  private _processedTickets = new Subject<Array<string>>();
  private _isPaused = false;
  // flag to indicate if the queue has potentially been modified
  private _isTainted = false;

  private dbRecord: Record<string, LocalForage>;
  private initialized = false;

  //observables
  private destroy$ = new Subject<void>();

  constructor() {
    this.initialize()
      .then(() => {
        this.loggerService.log('Completion queue initialized');
        this.initialized = true;
        return this.requeueErrors();
      })
      .then(() => {
        this.startCheckInterval();
      })
      .catch(() => {
        this.warnUser(
          'Error initializing completion queue. Please refresh the page and contact support if the issue persists.'
        );
      });

    this.ticketSyncService.isSyncing$.pipe(takeUntil(this.destroy$)).subscribe((isSyncing) => {
      if (isSyncing) {
        this._isTainted = true;
        this.pause();
      } else {
        this.resume();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async initialize(): Promise<void> {
    const db = this.initIndexedDB();
    if (!db) {
      throw new Error('Error initializing IndexedDB');
    }
    this.dbRecord = db;
    await this.waitForDBReady([this.dbRecord[this.DB_QUEUE_TABLE_NAME], this.dbRecord[this.DB_ERROR_TABLE_NAME]]);
    this.queues = {
      [this.DB_QUEUE_TABLE_NAME]: (await this.getDBQueueData(this.DB_QUEUE_TABLE_NAME)) ?? [],
      [this.DB_ERROR_TABLE_NAME]: (await this.getDBQueueData(this.DB_ERROR_TABLE_NAME)) ?? [],
    };
  }

  private initIndexedDB() {
    try {
      return this.cacheService.newStore(this.DB_NAME, [this.DB_QUEUE_TABLE_NAME, this.DB_ERROR_TABLE_NAME]);
    } catch (e) {
      this.loggerService.error('Caught error initializing IndexedDB: "', e + '"');
    }
  }

  async getDBQueueData(tableName: string): Promise<CompletionQueueItem[]> {
    const item: CompletionQueueItem[] = (await this.dbRecord[tableName].getItem(tableName)) ?? [];
    this.loggerService.log(`${tableName} item:`, item);
    return item;
  }

  /**
   * Enqueue a completion for upload
   */
  public async enqueue(assignmentID: string | number) {
    await this.mu.acquire();
    try {
      return await this._enqueue(this.newQueueItem(assignmentID), this.DB_QUEUE_TABLE_NAME);
    } finally {
      this.mu.release();
    }
  }

  /**
   * Add a completion to the queue
   */
  private async _enqueue(queueItem: CompletionQueueItem, targetTable: string) {
    if (this.initialized) {
      this.queues[targetTable].push(queueItem);
      return this.dbRecord[targetTable].setItem(targetTable, this.queues[targetTable]);
    } else {
      this.warnUser('Error enqueuing completion');
    }
  }

  /**
   * Remove the first completion in the queue
   */
  async dequeue(): Promise<CompletionQueueItem | undefined> {
    await this.mu.acquire();
    try {
      return this._dequeue(this.DB_QUEUE_TABLE_NAME);
    } finally {
      this.mu.release();
    }
  }

  /**
   * Remove the first completion in the queue
   */
  private async _dequeue(targetTable: string): Promise<CompletionQueueItem | undefined> {
    const item = this.queues[targetTable].shift();
    if (item) {
      await this.dbRecord[this.DB_QUEUE_TABLE_NAME].setItem(this.DB_QUEUE_TABLE_NAME, this.queues[targetTable]);
    }
    return item;
  }

  /**
   * Return the first completion in the queue
   */
  public async peek(): Promise<CompletionQueueItem | undefined> {
    await this.mu.acquire();
    try {
      return this._peek(this.DB_QUEUE_TABLE_NAME);
    } finally {
      this.mu.release();
    }
  }

  /**
   * Return the first completion in the queue
   */
  private _peek(targetTable: string): CompletionQueueItem {
    return this.queues[targetTable][0];
  }

  /**
   * Check if the queue is empty
   */
  public async isEmpty(): Promise<boolean> {
    await this.mu.acquire();
    const isEmpty = this._isEmpty(this.DB_QUEUE_TABLE_NAME);
    this.mu.release();
    return isEmpty;
  }

  public _isEmpty(targetTable: string): boolean {
    return this.queues[targetTable].length === 0;
  }

  /**
   * Return the size of the queue
   */
  async size() {
    await this.mu.acquire();
    const size = this._size(this.DB_QUEUE_TABLE_NAME);
    this.mu.release();
    return size;
  }

  /**
   * Return the size of the queue
   */
  _size(targetTable: string): number {
    return this.queues[targetTable].length;
  }

  /**
   * Clear the queue
   */
  private async clear(targetTable: string) {
    await this.mu.acquire();
    this.queues[targetTable] = [];
    await this.dbRecord[targetTable].setItem(targetTable, this.queues[targetTable]);
    this.mu.release();
  }

  /**
   * Convert the queue to an array
   */
  async toArray() {
    await this.mu.acquire();
    const queue = this._toArray(this.DB_QUEUE_TABLE_NAME);
    this.mu.release();
    return queue;
  }

  /**
   * Convert the queue to an array
   */
  _toArray(targetTable: string): CompletionQueueItem[] {
    return [...this.queues[targetTable]];
  }

  /**
   * Warn the user that there was an error adding a completion to the queue
   */
  private warnUser(message: string) {
    this.snackbarService.openSnackbar(message, SnackbarType.error);
  }

  /**
   * Start the interval to check for completions to upload
   */
  private startCheckInterval() {
    setInterval(async () => {
      // if we are already processing or offline, do not attempt to process
      if (
        this.ticketDocumentService.documentsAreWaitingUpload() ||
        this._isPaused ||
        this._isProcessing$.value ||
        !navigator.onLine
      ) {
        return;
      }
      try {
        this._isProcessing$.next(true);
        await this.mu.acquire();

        if (this._isTainted) {
          await this._refreshQueue();
          this._isProcessing$.next(false);
          return;
        }
        const processed = [];

        while (!this._isEmpty(this.DB_QUEUE_TABLE_NAME)) {
          const next = this._peek(this.DB_QUEUE_TABLE_NAME);
          if (!(await this.processItem(next))) {
            break;
          }
          processed.push(next.assignmentID.toString());
        }
        if (processed.length > 0) {
          this._processedTickets.next(processed);
        }
      } finally {
        this.mu.release();
        this._isProcessing$.next(false);
      }
    }, 10000);
  }

  private async processItem(item: CompletionQueueItem): Promise<UploadSuccessStatus> {
    let dequeued = false;
    try {
      await this.attemptUpload(item);
      await this._dequeue(this.DB_QUEUE_TABLE_NAME);
      // used if error occurs after dequeue
      dequeued = true;
      return true;
    } catch (e) {
      item.attempts++;
      item.error = e;
      item.lastAttempt = new Date();
      this.snackbarService.openSnackbar('Error uploading completion', SnackbarType.error);
      this.loggerService.error('Error uploading completion:', e);
      if (item.attempts >= 3) {
        await this._enqueue(item, this.DB_ERROR_TABLE_NAME);
        if (!dequeued) {
          await this._dequeue(this.DB_QUEUE_TABLE_NAME);
        }
      }
      return false;
    }
  }

  /**
   * Attempt to upload the first completion in the queue
   */
  private attemptUpload(next: CompletionQueueItem) {
    return new Promise<void>((resolve, reject) => {
      this.ticketSyncService.startSync(true, true, [next.assignmentID.toString()], true, undefined, false).subscribe({
        complete: resolve,
        error: reject,
      });
    });
  }

  /**
   * Refresh the queue from the indexedDB
   */
  private async _refreshQueue() {
    // gather data to audit
    const queue = this.queues[this.DB_QUEUE_TABLE_NAME];
    const errors = this.queues[this.DB_ERROR_TABLE_NAME];
    if (queue.length === 0 && errors.length === 0) {
      this._isTainted = false;
      return;
    }
    const assignmentIds = await this.fieldSideAssignmentService.store.keys();

    // filter out completions that are no longer valid
    this.queues[this.DB_QUEUE_TABLE_NAME] = queue.filter((item) =>
      assignmentIds.includes(item.assignmentID.toString())
    );
    this.queues[this.DB_ERROR_TABLE_NAME] = errors.filter((item) =>
      assignmentIds.includes(item.assignmentID.toString())
    );

    // save the updated queues
    await this.dbRecord[this.DB_QUEUE_TABLE_NAME].setItem(
      this.DB_QUEUE_TABLE_NAME,
      this.queues[this.DB_QUEUE_TABLE_NAME]
    );
    await this.dbRecord[this.DB_ERROR_TABLE_NAME].setItem(
      this.DB_ERROR_TABLE_NAME,
      this.queues[this.DB_ERROR_TABLE_NAME]
    );
    this._isTainted = false;
    return;
  }

  /**
   * Create a new queue item
   */
  private newQueueItem(assignmentID: string | number): CompletionQueueItem {
    return {
      assignmentID: Number(assignmentID),
      attempts: 0,
      error: null,
      lastAttempt: null,
    };
  }

  /**
   * Wait for the asynchronous driver initialization process to finish
   */
  private async waitForDBReady(dbList: LocalForage[]) {
    await Promise.all(dbList.map((db) => db.ready()));
  }

  private async requeueErrors() {
    const errors = this.queues[this.DB_ERROR_TABLE_NAME];
    for (const error of errors) {
      await this.enqueue(error.assignmentID);
    }
    return this.clear(this.DB_ERROR_TABLE_NAME);
  }

  get isProcessing$() {
    return this._isProcessing$.pipe();
  }

  get isProcessing(): boolean {
    return this._isProcessing$.value;
  }

  get processedTickets$() {
    return this._processedTickets.pipe();
  }

  pause() {
    this._isPaused = true;
  }

  resume() {
    this._isPaused = false;
  }

  markTainted() {
    this._isTainted = true;
  }
}

export type CompletionQueueItem = {
  assignmentID: number;
  attempts: number;
  error: Error;
  lastAttempt: Date;
};

type UploadSuccessStatus = boolean;
