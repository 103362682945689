import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalSetting, UserCategory, UserCategorySettings} from '../user-category-models';
import {UserCategoryEditorService} from '../user-category-editor.service';
import {ConfirmationDialogComponent, DialogData} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SettingID} from '../../core/services/user/setting';
import {ModifyType} from '../../edit-tables/TableModels';
import {UserService} from '../../core/services/user/user.service';
import {MenuOption, SettingGroup} from '../settings-group/edit-settings.component';


@Component({
  selector: 'app-user-category-editor-list-item',
  templateUrl: './user-category-editor-list-item.component.html',
})
export class UserCategoryEditorListItemComponent implements OnInit {
  userCategoryID: number = 0;
  userCategory: UserCategory;
  displayedColumns: string[] = ['Description', 'DefaultValue', 'DefaultActive'];
  settingsToShow: {settingID: number, active: number, value: number | string | string[]}[] = [];
  adminTables: {GlobalSettings: GlobalSetting[], UserCategories: UserCategory[]} = {GlobalSettings: [], UserCategories: []};

  private tempRoutingAlgorithmSettingID: number = 160;
  accessLevel: ModifyType = null;
  ModifyType = ModifyType;
  pendingChanges: boolean = false;
  loading: boolean = false;
  editState: boolean = false;

  menuOptions: MenuOption[] = [];

  constructor(
    private route: ActivatedRoute,
    private userCategoryEditorService: UserCategoryEditorService,
    public dialog: MatDialog,
    private userService: UserService
  ) { }

  async ngOnInit() {
    this.setupAccessLevelEnum();
    this.route.params.subscribe(async params => {
      await this.handleRouteParams(+params['userCategoryID']);
    });

    this.menuOptions.push(this.getApplyToUsersMenuOption());
  }


  getApplyToUsersMenuOption() {
    return {title: 'Apply to Users', function: this.applyToUsers.bind(this), tooltip: 'Apply the current setting values to all users with this category', disabled: false};
  }

  getSaveChangesMenuOption() {
    return {title: 'Apply to Users', function: () => { }, tooltip: 'You must save your changes first', disabled: true};
  }

  /**
   * 
   * @param settingIDs 
   */
  applyToUsers(groupToSet: SettingGroup) {
    //TODO: implement function 

    // TODO: Show confirmation dialog 
    const dialogData: DialogData = {
      title: 'Update user settings',
      message: 'Are you sure you want to update this setting for all users with this category?',
      confirmText: 'Yes',
      confirmColor: 'primary',
      cancelText: "No",
      confirmationAction: () => {} //empty action since it isn't used but required 
    };
    const confirm = this.dialog.open(ConfirmationDialogComponent, {width: '300px', data: dialogData});
    confirm.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        this.userCategoryEditorService.updateSettingGroupForCategory(groupToSet, this.userCategoryID);
      }
    });
  }

  /**
   * Handles route params based on whether there are changes or not 
   * @param userCategoryID 
   */
  private async handleRouteParams(userCategoryID: number): Promise<void> {
    if (this.pendingChanges) {
      await this.handlePendingChanges(userCategoryID);
    } else {
      await this.refreshClassVariablesForNewUserCategory(userCategoryID);
    }
  }

  /**
   * Asks the user if they want to save changes 
   * @param userCategoryID 
   */
  private async handlePendingChanges(userCategoryID: number): Promise<void> {
    this.formatSettingsToSave();
    const confirmUpdate = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Pending Changes',
        message: 'You have pending changes. Would you like to save them?',
        confirmText: 'Yes',
        confirmColor: 'primary',
        cancelText: 'No'
      },
    });

    const result = await confirmUpdate.afterClosed().toPromise();
    this.pendingChanges = false;
    if (result) {
      await this.confirmChangeUserSettings(userCategoryID);
    } else {
      await this.refreshClassVariablesForNewUserCategory(userCategoryID);
    }
  }

  /**
   * Sets class variables based off the new user category id 
   * @param newUserCategoryID 
   */
  async refreshClassVariablesForNewUserCategory(newUserCategoryID = null) {
    this.editState = false; // reset the edit state 
    if (this.pendingChanges) {
      this.menuOptions = [this.getApplyToUsersMenuOption()];
    }

    if (newUserCategoryID) {
      this.userCategoryID = newUserCategoryID;
    }

    this.adminTables = await this.userCategoryEditorService.getTables();

    this.userCategoryEditorService.getUserCategory(this.userCategoryID).then(async userCategory => {
      this.userCategory = userCategory;
      const tmpSettingsList = [];

      for (const setting of this.userCategory.Settings) {
        tmpSettingsList.push({
          settingID: setting.SettingID,
          active: setting.DefaultActive,
          value: setting.DefaultValue?.toString().includes(',') ? setting.DefaultValue.split(',') : setting.DefaultValue,
        });
      }
      this.settingsToShow = [...tmpSettingsList];
    });
  }

  /**
   * Sets up the access level for this user 
   */
  setupAccessLevelEnum() {
    const access = parseInt(this.userService.getSettingValue(SettingID.CATEGORY_MANAGER_ACCESS));
    // if we don't get a value from the setting, access is NaN, so set it to view 
    this.accessLevel = Number.isNaN(access) ? ModifyType.View : access;
  }


  /**
   * Sets the new title of the user category
   * @param newTitle 
   */
  async onTitleUpdate(newTitle) {
    this.pendingChanges = true;
    this.menuOptions = [this.getSaveChangesMenuOption()];
    this.userCategory.Title = newTitle;
  }


  /**
   * Sets the new description of the user category
   * @param newDescription 
   */
  async onDescriptionUpdate(newDescription) {
    this.pendingChanges = true;
    this.menuOptions = [this.getSaveChangesMenuOption()];
    this.userCategory.Description = newDescription;
  }


  /**
   * Sets the new list of settings 
   * @param newSettingsList 
   */
  async onSettingChange(newSettingsList) {
    this.pendingChanges = true;
    this.menuOptions = [this.getSaveChangesMenuOption()];
    this.settingsToShow = [...newSettingsList];
  }


  /**
   * Gest a list of admin settings 
   * @returns {GlobalSettings}
   */
  getAdminSettings() {
    return this.adminTables.GlobalSettings
  }


  /**
   * Formats and saves the category to the DB
   */
  saveCategory() {
    this.formatSettingsToSave();
    this.loading = true;

    const confirmUpdate = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: 'Confirm update',
        message: `Are you sure you want to edit this category? Removing or adding settings from or to this category will cause the removal or addition
        of the setting for the users.`,
        confirmText: `Confirm`,
        confirmColor: 'primary'
      },
    });

    //call update after the dialog closes 
    confirmUpdate.afterClosed().subscribe(async result => {
      if (result) {
        // Update the category, but don't update it's settings 
        const success = await this.userCategoryEditorService.updateUserCategory(this.userCategory, false);
        if (success) { //if we were able to update the category, then the changes we made went through. So,
          this.pendingChanges = false; //reset pending changes
        }
        this.refreshClassVariablesForNewUserCategory();
      }
      this.loading = false;
    });

  }

  /**
   * opens modal asking if they want to override user settings 
   * @param newUserCategoryID 
   * @returns {Promise<void>}
   */
  confirmChangeUserSettings(newUserCategoryID = null) {
    //confirm with the user we want to save the category - this will update the user's settings 
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: 'Change setting values',
        message: `Do you want to override current users setting values?`,
        confirmText: `Yes`,
        cancelText: `No`,
        confirmColor: 'warn'
      },
    });

    //call update after the dialog closes 
    dialogRef.afterClosed().subscribe(async result => {
      await this.userCategoryEditorService.updateUserCategory(this.userCategory, result);
      this.refreshClassVariablesForNewUserCategory(newUserCategoryID);
    });
  }


  /**
   * Formats the settings for this user category for the user category service 
   */
  formatSettingsToSave() {
    const settingsToSave: UserCategorySettings[] = [];

    const routingObject = this.settingsToShow.find(s => s.settingID == this.tempRoutingAlgorithmSettingID); //setting 107 is always the opposite of routing
    let routingActive = 0;

    if (routingObject) {
      routingActive = routingObject.active;
    }

    for (const setting of this.settingsToShow) {
      switch (setting.settingID) {
        case SettingID.ENABLE_LOCATOR_ROUTING:
          setting.active = routingActive == 0 ? 1 : 0; //setting 107 is always the opposite of routing
          break;
      }
      settingsToSave.push(
        new UserCategorySettings(null, this.userCategoryID, setting.settingID, setting.active, setting.value ? setting.value.toString() : null)
      );
    }
    this.userCategory.Settings = [...settingsToSave];
  }
}
