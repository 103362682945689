import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { UtilocateTokenPaths } from 'src/app/modules/core/services/token/token.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { environment } from 'src/environments/environment';
import { DocumentTypeID } from 'src/app/modules/core/services/document/document.service';
import { UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { CryptographyService } from '../../../../../shared/services/cryptography/cryptography.service';
import { zipFile, ZipTypes } from 'src/app/modules/core/services/document/zip-helper';

@Injectable({
  providedIn: 'root',
})
export class NodeDocumentService {
  // services
  private cryptoService = inject(CryptographyService);

  IS_LIVE: boolean = false;
  IS_DEV: boolean = true;
  isLocalHost: boolean;
  presignedUrl: any;

  constructor(
    private userService: UserService,
    private baseApiService: UtilocateApiService,
    private utilocateApiService: UtilocateApiService
  ) {
    this.IS_LIVE = environment.production == true;
    this.IS_DEV = environment.production == false && environment.url == null;
    this.isLocalHost = environment.localhost;
  }

  async getDocumentFromS3(key: string, bucket?: string): Promise<object | Error> {
    let url = '/api/get/image';
    if (!this.isLocalHost) {
      url = '/nodejs/api/get/image';
    }

    //default bucket if the bucket is null
    bucket = bucket ?? this.userService.getClientBucketFromSettings();

    //if using retrieveDocumentS3 (trying to get a tbCompletions_Documents document)
    if (key.startsWith('public')) {
      bucket = 'utilocatecompanion-userfiles-mobilehub-1835283454';
    }

    const s3ImageFile: HttpResponse<any> = await this.baseApiService.invokeApi('PUT', url, { params: { key, bucket } });
    if (s3ImageFile.ok && s3ImageFile.body['image']) {
      return {
        image: s3ImageFile.body['image']['data'],
        filename: s3ImageFile.body['filename'],
      };
    } else {
      console.error(s3ImageFile.body['error'] ? s3ImageFile.body['error'] : null);
      return new Error('Failed to run GET');
    }
  }

  /**
   * Uploads the document to S3. Uploads to mobilhub bucket where
   * the UploadDocumentS3 lambda processes it and places it into the appropriate bucket
   */
  async generatePreSignedUrl(PrimaryID: string, AssignmentID: string, metadata: Array<string>) {
    try {
      console.log(metadata);
      const apiKey = apiKeys.u2.uploadDocNew;
      const url = apiKeys.u2[apiKey];
      const type = api[url].type;

      const presignedUrlRequest: UtilocateApiRequest = {
        API_KEY: apiKey,
        API_TYPE: type,
        API_BODY: {
          operation: 'presigned-post',
          metadata,
        },
        API_URL_DATA_PARAMS: {
          AssignmentID: AssignmentID.toString(),
          PrimaryID: PrimaryID.toString(),
          ClientID: '109',
          UserID: '55',
        },
      };

      const result = await this.utilocateApiService.invokeUtilocateApi(presignedUrlRequest);
      if (!(result instanceof HttpErrorResponse) && result.body) {
        const fields = result.body;
        console.log(fields);
        return fields;
      } else {
        return false;
      }
      // return result;
    } catch (error) {
      console.error(error);
    }
  }

  convertBase64ToBlob(base64: string, contentType: string): Blob {
    const base64String = base64.split(',')[1];
    const sliceSize = 512;
    const byteCharacters = atob(base64String); // Decode base64 string
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async uploadDoc(document, metadata) {
    try {
      const convertedBlob = this.convertBase64ToBlob(document['file'], 'image/jpeg');
      
      const zipFiles = await zipFile<Blob>(metadata['FileName'], convertedBlob, ZipTypes.blob); // zip the file

      const metadataFields = {
        creationdate: metadata['CreationDate'],
        filename: metadata['FileName'],
        assignmentid: metadata['AssignmentID'],
        primaryid: metadata['PrimaryID'],
        issendable: metadata['isSendable'],
        requestnumber: metadata['RequestNumber'],
        creationdatetz: metadata['CreationDateTz'],
        description: metadata['Description'],
        rotateangle: '0',
        documenttypeid: metadata['DocumentTypeID'],
        uuid: metadata['s3UUID'],
        caption: metadata['Caption'] ? metadata['Caption'] : '',
      };

      const { fields, url } = await this.generatePreSignedUrl(
        metadata['PrimaryID'],
        metadata['AssignmentID'],
        Object.keys(metadataFields)
      );

      const formData = new FormData();
      Object.entries(metadataFields).forEach(([key, value]) => {
        formData.append('x-amz-meta-' + key, value);
      });

      const blob: Blob = zipFiles;


      const hash = await this.cryptoService.calculateFileSHA256(blob);

      formData.append('x-amz-checksum-algorithm', 'SHA256');
      formData.append('x-amz-checksum-sha256', hash);
      formData.append('Content-Type', blob.type);
      formData.append('bucket', fields['bucket']);
      formData.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
      formData.append('X-Amz-Credential', fields['X-Amz-Credential']);
      formData.append('X-Amz-Date', fields['X-Amz-Date']);
      formData.append('X-Amz-Security-Token', fields['X-Amz-Security-Token']);
      formData.append('key', fields['key']);
      formData.append('Policy', fields['Policy']);
      formData.append('X-Amz-Signature', fields['X-Amz-Signature']);
      formData.append('file', zipFiles, document['zipName']);

      const result = await this.baseApiService.invokeApi('POST', url, formData);

      if (!(result instanceof HttpErrorResponse)) {
        return true;
      } else {        
        throw new Error('Error uploading document');
      }
    } catch (error) {      
      console.error(error);
      throw new Error('Error uploading document');
    }
  }

  async uploadDocToS3(data, zipname, metadata) {
    // then we upload to s3. otherwise, tbCompletions_Documents
    let url = '/api/locator/upload/image'; // localhost
    if (!this.isLocalHost) {
      url = '/nodejs/api/locator/upload/image';
    }

    //upload to s3 or not
    if (
      metadata.DocumentTypeID == DocumentTypeID.OldDocument ||
      metadata.DocumentTypeID == DocumentTypeID['Auxiliary Image']
    ) {
      url = url + '/S3';
    }

    const ClientID = await this.userService.getUserValueFromToken(UtilocateTokenPaths.CLIENTID);

    const fileData: FormData = new FormData();
    fileData.append('fileToUpload', data);
    fileData.append('zipname', zipname);
    fileData.append('metadata', JSON.stringify(metadata));
    fileData.append('isLive', this.IS_LIVE.toString());
    fileData.append('ClientID', ClientID);

    fileData.append('AssignmentID', metadata['AssignmentID']);
    fileData.append('Description', metadata['Description']);
    fileData.append('DocumentTypeID', metadata['DocumentTypeID']);
    fileData.append('isSendable', metadata['isSendable']);

    const result = await this.baseApiService.invokeApi('PUT', url, fileData);
    return result;
  }

  async uploadAuxImageToS3(data, zipname, metadata) {
    let url = '/api/upload/auxiliary'; // localhost
    const bucket = this.userService.getClientBucketFromSettings();

    const ClientID = await this.userService.getUserValueFromToken(UtilocateTokenPaths.CLIENTID);
    if (!this.isLocalHost) {
      url = '/nodejs/api/upload/auxiliary';
    }

    const fileData: FormData = new FormData();
    fileData.append('fileToUpload', data);
    fileData.append('zipname', zipname);
    fileData.append('metadata', JSON.stringify(metadata));
    fileData.append('isLive', this.IS_LIVE.toString());
    fileData.append('ClientID', ClientID);

    fileData.append('AssignmentID', metadata['AssignmentID']);
    fileData.append('Description', metadata['Description']);
    fileData.append('DocumentTypeID', metadata['DocumentTypeID']);
    fileData.append('isSendable', metadata['isSendable']);
    fileData.append('DocumentLocationID', '1');
    //add client specific bucket. Defaults to utilocate.client.docs if setting is off
    fileData.append('bucket', bucket);

    const result = await this.baseApiService.invokeApi('PUT', url, fileData);
    return result;
  }
}
