import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TicketWorkDetailsComponent } from './work-details/ticket-work-details.component';
import { TicketDigsiteDetailsComponent } from './panels/ticket-digsite-details.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketDocumentDetailsComponent } from './documents/ticket-documents-details.component';
import {
  AddNewAutologModalComponent,
  TicketAutologDetailsComponent,
} from './autologs/ticket-autolog-details.component';
import { TicketSummaryComponent } from './smry-panel/ticket-summary.component';
import { TicketTagsComponent } from './ticket-tags/ticket-tags.component';
import { MatDialog } from '@angular/material/dialog';
import { TicketSyncModalComponent } from './sync-modal/sync-modal.component';
import { TicketCompletionsDetailsComponent } from './completions/ticket-detail-completions.component';
import { MapComponent } from './map/map.component';
import { TicketPreCompletionsDetaisComponent } from './pre-completions/ticket-detail-pre-completions.component';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { take } from 'rxjs/operators';
import { BaseTabsPageComponent } from 'src/app/modules/core/base-set/base-set.component';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { TicketDetailsService } from '../ticket-details.service';
import { AdminLookupService } from 'src/app/modules/core/admin/admin-lookup.service';
import { ProgressBarService } from '../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { ModalService } from '../../modals/modal.service';
import { GeneratePDFService } from 'src/app/modules/core/services/generatePDF/generate-pdf.service';
import { NotificationService } from 'src/app/modules/core/services/logger/notification.service';
import { AuthenticationService } from 'src/app/modules/core/authentication/authentication.service';
import { U2_USER_CATEGORY_ID, U3_USER_CATEGORY_ID } from 'src/app/modules/core/services/user/user';
import { ADMIN_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import { LocateStatusID, TicketProtectionActionTypeID } from '../ticket-details.module';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { SchedulerCancelTicketDialogComponent } from 'src/app/modules/scheduler/dialogs/scheduler-cancel-ticket-dialog/scheduler-cancel-ticket-dialog.component';
import { AutologRow } from './autologs/ticket-autolog';
import { AssignLocatorModalComponent } from '../../ticket-assignment/assignLocatorModal/assign-locator-modal.component';
import { BaseModalModule } from '../../modals/base-modal.module';
import { GoogleMapsSetModule } from '../../google-map/google-maps-set/google-maps-set.component';
import { FloatingActionMenuComponent } from '../../fab/floating-action-menu/floating-action-menu.component';
import { EsriMapSimpleDrawingModule } from '../../esri-map/esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component';
import { RecursiveFormTemplateModule } from '../../forms/recursive-form-templates/recursive-form-template/recursive-form-template.component';
import { DigsiteModule } from 'src/app/modules/digsite/digsite.module';
import { FormInputTemplateModule } from '../../forms/form-input-template/form-input-template.component';
import { MaterialModule } from '../../material.module';
import { ConfirmationDialogModule } from '../../confirmation-dialog/confirmation-dialog.component';
import { AutologID, SettingID } from 'src/app/modules/core/services/user/setting';
import { ExcavatorContactsCardModule } from 'src/app/modules/create-ticket/excavator-contacts-card/excavator-contacts-card.component';
import { SchedulerCancelTicketDialogModule } from 'src/app/modules/scheduler/dialogs/scheduler-cancel-ticket-dialog/scheduler-cancel-ticket-dialog.module';
import { TicketProtectionOverrideDialogModule } from './ticket-protection-override-dialog/ticket-protection-override-dialog.moduel';
import { TicketProtectionOverrideDialogComponent } from './ticket-protection-override-dialog/ticket-protection-override-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilocateTokenPaths } from 'src/app/modules/core/services/token/token.service';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.css'],
})
export class TicketDetailsComponent extends BaseTabsPageComponent implements OnInit, OnChanges {
  className = 'TicketDetailsComponent';
  @ViewChild('ticketWorkDetailsComponent') TicketWorkDetailsComponent;

  @Input() props: any = {};
  @Output() syncFinished: EventEmitter<any> = new EventEmitter<any>();
  @Output() formGroupChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  childProps: any;

  ClientID: any = null;
  AssignmentID: any = null;
  PrimaryID: any = null;
  RequestNumber: any = null;
  CallTypeID: number;
  UserCategoryID: any;
  U3UserID: any;
  U3UserCategoryID: any;
  CurrentLocatorID: any;
  U2UserID: any;
  LocateStatusID: any;
  IFrameModal: any = null;
  isUtilityUser: boolean;

  formGroupValuesChanged: boolean = false; // update sync icon
  IOwnTicket: boolean = false;
  syncModal: any;
  isU2TicketComplete: boolean = true;

  showPreCompletions: boolean = false;
  allowEdit: boolean = true;
  allowEditingCompletionDetails: boolean = false;

  dataChanged: boolean = false;
  isValidState: boolean = true;

  // digsiteDetails
  navBackground = '';
  READ_ONLY_FORM_TEMPLATE = 2;
  OFFICE_FORM_TEMPLATE = 3;
  FIELD_FORM_TEMPLATE = 3;
  PRIVATE_TICKET_EDIT_FORM_TEMPLATE = 5;

  CLEAR_TICKET_FAILED_MODAL_ID: string = 'id_clear_ticket_failed_dialog';
  clearTicketFailMessage: string = '';

  showMakeLoadsheet: boolean = false;
  showMakeInvoice: boolean = false;

  lsps: any;

  constructor(
    loggerService: LoggerService,
    private ticketDetails$: TicketDetailsService,
    private admin$: AdminLookupService,
    private auth$: AuthenticationService,
    private alert$: NotificationService,
    public dialog: MatDialog,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private modalService: ModalService,
    private generatePDF$: GeneratePDFService,
    private userService: UserService,
    private route: ActivatedRoute,
    private baseApiService: UtilocateApiService,
    private datetimeService: DatetimeService
  ) {
    super(loggerService);
    this.tabs = ['ticketInfo', 'ticketMap', 'documentDetails', 'autologDetails', 'completionDetails'];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.props.currentValue) {
      if (changes.props.currentValue.AssignmentID) {
        this.AssignmentID = changes.props.currentValue.AssignmentID;
      }

      if (changes.props.currentValue.PrimaryID) {
        this.PrimaryID = changes.props.currentValue.PrimaryID;
      }

      if (changes.props.currentValue.RequestNumber) {
        this.RequestNumber = changes.props.currentValue.RequestNumber;
      }

      if (changes.props.currentValue[localStorageKeys.URL_KEYS.iframemodal]) {
        this.IFrameModal = changes.props.currentValue[localStorageKeys.URL_KEYS.iframemodal];
      }
    }
  }

  async ngOnInit() {
    this.checkSetting();
    this.setupProps();
    await this.startSync(false, true);
  }

  setupProps() {
    const queryParams: any = this.route.snapshot.queryParams;
    const URL_KEYS = localStorageKeys.URL_KEYS;

    if (queryParams[URL_KEYS.iframemodal]) {
      this.props[URL_KEYS.iframemodal] = queryParams[URL_KEYS.iframemodal];
      this.IFrameModal = this.props[URL_KEYS.iframemodal];
    }

    if (queryParams['AssignmentID']) {
      this.props['AssignmentID'] = queryParams['AssignmentID'];
      this.AssignmentID = this.props['AssignmentID'];
    }

    if (queryParams['PrimaryID']) {
      this.props['PrimaryID'] = queryParams['PrimaryID'];
      this.PrimaryID = this.props['PrimaryID'];
    }

    if (queryParams['RequestNumber']) {
      this.props['RequestNumber'] = queryParams['RequestNumber'];
      this.RequestNumber = this.props['RequestNumber'];
    }

    if (this.IFrameModal) {
      let newQueryParams = this.cleanURLQueryParams();
      // this.routerService.navigate([this.route.snapshot.routeConfig.path], { queryParams: newQueryParams, replaceUrl: true });
    }
  }

  private cleanURLQueryParams(): object {
    let queryParams: any = this.route.snapshot.queryParams;
    let queryParamsKeys: any = Object.keys(queryParams);
    let newQueryParams = {};

    try {
      let visisbleURLKeys: any = this.route.snapshot.routeConfig.data.VISIBLE_URL_KEYS;
      let len = queryParamsKeys.length;
      for (let i = 0; i < len; i++) {
        let queryParamsKey = queryParamsKeys[i];
        if (visisbleURLKeys && visisbleURLKeys.indexOf(queryParamsKey.toLowerCase()) > -1) {
          newQueryParams[queryParamsKey] = queryParams[queryParamsKey];
        } else {
          newQueryParams[queryParamsKey] = null;
        }
      }
      return newQueryParams;
    } catch (error) {
      console.log(error);
    }
    return newQueryParams;
  }

  async ngInitDownloadTicket() {
    this.alert$.setSyncDownloadFlat('0_token_query', 'Gather user data from token', 0);

    this.childProps = {
      workDetailsEditMode: false,
    };
    this.formGroupValuesChanged = false;

    // get user info from token
    this.UserCategoryID = this.auth$.getNestedValueFromPayload('USERCATEGORYID');
    this.ClientID = this.auth$.getNestedValueFromPayload('CLIENTID');
    this.U2UserID = this.auth$.getNestedValueFromPayload('USERID');
    this.U3UserID = this.auth$.getNestedValueFromPayload('U3USER.USERID');
    this.U3UserCategoryID = this.auth$.getNestedValueFromPayload('U3USER.USERCATEGORYID');

    if (this.ClientID == 59) {
      this.showMakeLoadsheet = true;
    }

    this.alert$.setSyncDownloadFlat('0_token_query', 'Gather user data from token', 1);

    this.alert$.setSyncDownloadFlat('1_download_prepare', 'Prepare for download', 0);
    let templateToLoad = this.READ_ONLY_FORM_TEMPLATE;
    if (this.UserCategoryID == U2_USER_CATEGORY_ID.Locator) {
      templateToLoad = this.FIELD_FORM_TEMPLATE;
    } else if (this.UserCategoryID == U2_USER_CATEGORY_ID.Manager) {
      templateToLoad = this.OFFICE_FORM_TEMPLATE;
    } else if (this.UserCategoryID == U2_USER_CATEGORY_ID.OfficeDispatch) {
      templateToLoad = this.OFFICE_FORM_TEMPLATE;
    }
    this.alert$.setSyncDownloadFlat('1_download_prepare', 'Prepare for download', 1);

    this.alert$.setSyncDownloadFlat('2_download_ticket', 'Download ticket', 0);
    var getTicketFormGroupPromiseArr = [];

    if (this.RequestNumber != null && this.U3UserCategoryID == U3_USER_CATEGORY_ID.Utility) {
      this.isUtilityUser = true;
      getTicketFormGroupPromiseArr.push(
        this.ticketDetails$.getTicketFormGroupByRequestNum(this.RequestNumber, templateToLoad)
      );
    } else {
      this.isUtilityUser = false;
      getTicketFormGroupPromiseArr.push(
        this.ticketDetails$.getTicketFormGroup(this.AssignmentID, templateToLoad, this.PrimaryID)
      );
    }
    return Promise.all(getTicketFormGroupPromiseArr)
      .then(async (result: any) => {
        if (result && result[0] && result[0][0] && result[0][1]) {
          if (result[0][1] == 'Failed: Invalid authorization') {
            this.alert$.setSyncDownloadFlat('2_download_ticket', 'Download ticket', 2);
            this.isValidState = false;
            this.syncFinished.emit(true);
            return true;
          } else {
            this.isValidState = true;
          }

          this.alert$.setSyncDownloadFlat('2_download_ticket', 'Download ticket', 1);
          this.alert$.setSyncDownloadFlat('2_download_admin', 'Download admin tables', 0);
          let ticketFormDatas = result[0][0]; // getTicketTemplate api result
          let ticketTables = result[0][1]; // downloadTicket api result
          this.PrimaryID = await this.ticketDetails$.getPrimaryID(ticketTables.tbCompletions_Primary.Data);
          sessionStorage.setItem('primaryid', this.PrimaryID);

          let adminTables = await this.admin$.getAdminTables(
            [
              ADMIN_TABLE_NAMES.tbAdmin_AutologDesc,
              ADMIN_TABLE_NAMES.tbLogin_Users,
              ADMIN_TABLE_NAMES.tbAdmin_Excavators,
              ADMIN_TABLE_NAMES.tbAdmin_LocateStatus,
              ADMIN_TABLE_NAMES.tbAdmin_LocateType,
              ADMIN_TABLE_NAMES.tbAdmin_CallType,
              ADMIN_TABLE_NAMES.tbAdmin_RegionList,
              ADMIN_TABLE_NAMES.tbAdmin_TicketTags,
              ADMIN_TABLE_NAMES.tbAdmin_CallTypeToPreCompletionCategory,
              ADMIN_TABLE_NAMES.tbAdmin_PreCompletionCategories,
              ADMIN_TABLE_NAMES.tbAdmin_PreCompletionFields,
              ADMIN_TABLE_NAMES.tbAdmin_PreCompletionFieldOptions,
              ADMIN_TABLE_NAMES.tbUser_SettingOverrides,
            ],
            {
              ExcavatorID: ticketTables.tbCompletions_Assignments.Data[0]['ExcavatorID'],
            }
          );

          this.alert$.setSyncDownloadFlat('2_download_admin', 'Download admin tables', 1);

          if (this.AssignmentID == null) {
            this.AssignmentID = ticketTables.tbCompletions_Assignments.Data[0]['AssignmentID'];
          }
          this.RequestNumber = ticketTables.tbCompletions_Assignments.Data[0]['RequestNumber'];
          this.CallTypeID = ticketTables.tbCompletions_Assignments.Data[0]['CallTypeID'];
          this.LocateStatusID = ticketTables.tbCompletions_Assignments.Data[0]['LocateStatusID'];
          this.CurrentLocatorID = ticketTables.tbCompletions_Assignments.Data[0]['CurrentLocatorID'];
          let tbAdmin_Excavators = adminTables[2].Data[0];

          this.alert$.setSyncDownloadFlat('3_ticket_protection', 'Applying ticket protection', 0);

          this.isU2TicketComplete = false;
          if (this.ticketDetails$.isU2TicketComplete(this.LocateStatusID)) {
            this.isU2TicketComplete = true;
          }

          if (this.ClientID == 59 && this.CallTypeID == 9) {
            this.showMakeInvoice = true;
          }

          let CurrentLocatorID = ticketTables.tbCompletions_Assignments.Data[0]['CurrentLocatorID'];

          if (this.U2UserID) {
            if (
              this.U3UserCategoryID != U3_USER_CATEGORY_ID.Utility &&
              (this.UserCategoryID == U2_USER_CATEGORY_ID.Manager || this.U2UserID == CurrentLocatorID)
            ) {
              // once you have the ticket, insert into ProtectedTickets, otherwise readonly mode
              let ticketProtectionCheck = await this.ticketDetails$.updateTicketProtection(
                this.AssignmentID,
                TicketProtectionActionTypeID.CHECK_TICKET_PROTECTION,
                this.U2UserID
              );
              if (
                ticketProtectionCheck != null &&
                ticketProtectionCheck.result != null &&
                ticketProtectionCheck.result == true
              ) {
                // disable form because another user already has access to the ticket

                ticketFormDatas['ticketInfo']['formGroup'].disable();
                this.TICKET_PROTECTION_MODE = true;
                this.IOwnTicket = false;

                // let snackbarConfig: MatSnackBarConfig = {
                //   duration: 5000,
                //   horizontalPosition:'center',
                //   verticalPosition:'top'
                // }
                // this.snackBarService.openSnackBar("Someone else has this ticket open, you cannot make edits", "Close", SnackbarType.error, snackbarConfig);

                const dialogRef = this.dialog.open(TicketProtectionOverrideDialogComponent, {
                  width: '560px',
                  data: {
                    ticketProtectionCheck: ticketProtectionCheck,
                  },
                });

                dialogRef
                  .afterClosed()
                  .pipe(take(1))
                  .subscribe(async (result) => {
                    if (result && result.indexOf('cancel') == -1) {
                      //remove old user, add current user
                      await this.ticketDetails$.updateTicketProtection(
                        this.AssignmentID,
                        TicketProtectionActionTypeID.REMOVE_TICKET_PROTECTION,
                        ticketProtectionCheck['userID']
                      );

                      await this.ticketDetails$.updateTicketProtection(
                        this.AssignmentID,
                        TicketProtectionActionTypeID.ADD_TICKET_PROTECTION,
                        this.U2UserID
                      );
                      ticketFormDatas['ticketInfo']['formGroup'].enable();
                      this.TICKET_PROTECTION_MODE = false;
                      this.IOwnTicket = true;

                      this.childProps = {
                        ...this.childProps,
                        TICKET_PROTECTION_MODE: this.TICKET_PROTECTION_MODE,
                      };
                    }
                  });
              } else if (this.isU2TicketComplete && this.allowEdit) {
                ticketFormDatas['ticketInfo']['formGroup'].disable();
                this.TICKET_PROTECTION_MODE = false;
                this.IOwnTicket = true;
              } else {
                let ticketProtectionAdd = await this.ticketDetails$.updateTicketProtection(
                  this.AssignmentID,
                  TicketProtectionActionTypeID.ADD_TICKET_PROTECTION,
                  this.U2UserID
                );
                ticketFormDatas['ticketInfo']['formGroup'].enable();
                this.TICKET_PROTECTION_MODE = false;
                this.IOwnTicket = true;
              }
            } else {
              //for utility users
              ticketFormDatas['ticketInfo']['formGroup'].disable();
              this.TICKET_PROTECTION_MODE = true;
              this.IOwnTicket = false;
            }
          } else {
            ticketFormDatas['ticketInfo']['formGroup'].disable();
            this.TICKET_PROTECTION_MODE = true;
            this.IOwnTicket = false;

            let snackbarConfig: MatSnackBarConfig = {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            };
            this.snackBarService.openSnackbar(
              'Someone else has this ticket open, you cannot make edits',
              SnackbarType.warning,
              'Close'
            );
          }

          // apply excavator data to form
          this.ticketDetails$.applyDataToForms(tbAdmin_Excavators, ticketFormDatas);

          // apply users data to form
          let userData = await this.admin$.getAdminTableDescFromID(ADMIN_TABLE_NAMES.tbLogin_Users, {
            UserID: CurrentLocatorID,
          });
          let name = userData[0]['FirstName'] + ' ' + userData[0]['LastName'];
          this.ticketDetails$.applyDataToForms({ CurrentLocatorID: name }, ticketFormDatas);

          // apply locate type to form
          let LocateTypeID = ticketTables.tbCompletions_Assignments.Data[0]['LocateTypeID'];
          let LocateTypeDesc = await this.admin$.getAdminTableDescFromID(ADMIN_TABLE_NAMES.tbAdmin_LocateType, {
            LocateTypeID: LocateTypeID,
          });
          this.ticketDetails$.applyDataToForms({ LocateTypeID: LocateTypeDesc[0]['LocateTypeDesc'] }, ticketFormDatas);

          // get data for summary panel
          let CallTypeDesc = false;
          let callTypeIDResult = await this.admin$.getAdminTableDescFromID(ADMIN_TABLE_NAMES.tbAdmin_CallType, {
            CallTypeID: this.CallTypeID,
          });
          if (callTypeIDResult && callTypeIDResult[0] && callTypeIDResult[0]['CallTypeDesc']) {
            CallTypeDesc = callTypeIDResult[0]['CallTypeDesc'];
          }

          this.alert$.setSyncDownloadFlat('3_ticket_protection', 'Applying ticket protection', 1);

          let canCompleteTicket = this.userService.canUserCategoryIDCompleteTicket(Number(this.UserCategoryID));

          this.lsps = await this.ticketDetails$.getLSPs(
            this.AssignmentID,
            this.PrimaryID,
            ticketTables.tbCompletions_AuxiliaryDetails.Data
          ); //[{ 'LSPID': '1', 'Name': 'TEST' }] //

          this.lsps = await this.ticketDetails$.getLSPs(
            this.AssignmentID,
            this.PrimaryID,
            ticketTables.tbCompletions_AuxiliaryDetails.Data
          ); //[{ 'LSPID': '1', 'Name': 'TEST' }] //

          // props for all components
          this.childProps = {
            ...this.childProps,
            views: ticketFormDatas,
            ticket: ticketTables.tbCompletions_Assignments.Data[0],
            tbCompletions_Documents: ticketTables.tbCompletions_Documents.Data,
            tbCompletions_S3Documents: ticketTables.tbCompletions_S3Documents.Data,
            tbCompletions_Autolog: ticketTables.tbCompletions_Autolog.Data,
            tbCompletions_AuxiliaryDetails: ticketTables.tbCompletions_AuxiliaryDetails.Data,
            tbCompletions_Billing: ticketTables.tbCompletions_Billing.Data,
            tbCompletions_CommonDetails: ticketTables.tbCompletions_CommonDetails.Data,
            tbCompletions_PrimaryDetails: ticketTables.tbCompletions_PrimaryDetails.Data,
            tbCompletions_AssignmentToTags: ticketTables.tbCompletions_AssignmentToTags.Data,
            tbCompletions_PreCompletionDetails: ticketTables.tbCompletions_PreCompletionDetails.Data,
            tbCompletions_PreCompletionCategories: ticketTables.tbCompletions_PreCompletionCategories.Data,
            tbCompletions_Primary: ticketTables.tbCompletions_Primary.Data,
            UserCategoryID: this.UserCategoryID,
            U3UserID: this.U3UserID,
            U2UserID: this.U2UserID,
            CallTypeDesc: CallTypeDesc,
            AssignmentID: this.AssignmentID,
            PrimaryID: this.PrimaryID,
            RequestNumber: this.RequestNumber,
            TICKET_PROTECTION_MODE: this.TICKET_PROTECTION_MODE,
            isU2TicketComplete: this.isU2TicketComplete,
            ClientID: this.ClientID,
            canCompleteTicket,
            isUtilityUser: this.isUtilityUser,
            lsps: this.lsps,
          };

          this.alert$.setSyncDownloadFlat('4_ready', 'Ready', 1);
          this.syncFinished.emit(true);

          return true;
        } else {
          throw new Error('Failed download section of sync');
        }
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  async handleTimeInOutChange(next) {
    try {
      console.log(next);
      await this.ticketDetails$.updateTicketData(this.AssignmentID, 'tbCompletions_Primary', next['result']);
      this.dataChanged = true;
      this.formGroupValuesChanged = true;
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  async onModalClose() {
    try {
      let result = true;
      if (this.dataChanged) {
        result = await this.startSync(true, false);
      }

      if (this.U2UserID && this.U3UserCategoryID != U3_USER_CATEGORY_ID.Utility) {
        await this.ticketDetails$.updateTicketProtection(
          this.AssignmentID,
          TicketProtectionActionTypeID.REMOVE_TICKET_PROTECTION,
          this.U2UserID
        );
      }

      if (this.IFrameModal != null) {
        sessionStorage.clear();
        this.oniFrameModalClose(result);
      } else {
        this.modalClosed.emit();
      }
    } catch (error) {
      this.loggerService.error(error.message);
    }
  }

  async oniFrameModalClose(result) {
    if (result) {
      parent.postMessage('Close Modal', '*');
    }
  }

  async newAutologEvent(event) {
    try {
      // this.formGroupValuesChanged = true;
      let newAutologTable = await this.ticketDetails$.addNewAutolog(this.AssignmentID, event.data);
      if (newAutologTable) {
        this.childProps = {
          ...this.childProps,
          tbCompletions_Autolog: newAutologTable.Data,
        };
      }
      this.handleFinishEditMode({ saved: true });
    } catch (error) {
      console.error(error);
    }
  }

  async newDocumentEvent(event) {
    this.childProps.tbCompletions_Documents.push(event.data);
  }

  // update forms
  async handleChildUpdateEvent(event) {
    try {
      let tables = event.data; // should be an array of tables to update

      let promiseArr = []; // array of tables to update in IDB
      for (let i = 0; i < tables.length; i++) {
        let tableKey = Object.keys(tables[i])[0];
        let table = tables[i][tableKey];

        promiseArr.push(this.ticketDetails$.updateTicketData(this.AssignmentID, tableKey, table));
      }

      await Promise.all(promiseArr);
    } catch (error) {
      console.error(error);
    }
  }

  async handleFinishEditMode(event) {
    this.progressBarService.start();
    this.formGroupValuesChanged = true;

    if (event.saved) {
      this.formGroupChanged.emit(event);
      this.dataChanged = true;
      this.progressBarService.stop();
    } else {
      this.snackBarService.openSnackbar('Changes discarded', SnackbarType.success);
    }
  }

  async assignTicketToUser(result) {
    try {
      if (result['UserID'] != null) {
        this.progressBarService.start();

        let assignResult = await this.ticketDetails$.reassignTicketToUser(this.PrimaryID, result['UserID']);
        if (assignResult && assignResult[0] && assignResult[0]['Error']) {
          let errorMsg = assignResult[0]['Error'].split(':')[1];
          this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Success', SnackbarType.success);
          await this.startSync(true, true);
        }

        this.progressBarService.stop();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async sendToLSP(result) {
    try {
      if (result['LSPID'] != null) {
        this.progressBarService.start();

        let toLSPResult = await this.ticketDetails$.sendToLSP(this.PrimaryID, result['LSPID']);
        if (toLSPResult && toLSPResult[0] && toLSPResult[0]['Error']) {
          let errorMsg = toLSPResult[0]['Error'].split(':')[1];
          this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
        } else {
          this.snackBarService.openSnackbar('Success', SnackbarType.success);
          await this.startSync(true, true);
        }

        this.progressBarService.stop();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onCancelTicketEvent(locateStatusID: number) {
    this.progressBarService.start();
    const dialogRef = this.dialog.open(SchedulerCancelTicketDialogComponent, {
      width: '400px',
      data: {
        message: 'Would you like to cancel the ticket?',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.indexOf('noCancel') == -1) {
        let apiValue = {};
        apiValue[this.PrimaryID] = {
          Explanation: result,
        };

        await this.ticketDetails$.completeTicketAs(LocateStatusID.OFFICE_CANCELLED, this.AssignmentID, this.U2UserID);
        this.ticketDetails$.cancelTicket(apiValue).subscribe(async (response) => {
          this.progressBarService.stop();
          if (response && response[0] && response[0]['Error']) {
            let errorMsg = response[0]['Error'].split(':')[1];
            this.snackBarService.openSnackbar(errorMsg, SnackbarType.error, 'OK');
          } else {
            this.snackBarService.openSnackbar('Success', SnackbarType.success);
            await this.startSync(true, true);
          }
        });
      }
    });
  }

  async onHandleClearTicket(clearTypeID) {
    await this.startSync(true, false);
    this.ticketDetails$.callClearTicket(clearTypeID, this.PrimaryID).subscribe((result) => {
      console.log(result);
      this.startSync(false, true);
      if (result['Cleared']) {
        this.snackBarService.openSnackbar('Ticket Cleared', SnackbarType.success);
      } else {
        // open dialog and display fail message
        this.clearTicketFailMessage = 'Error Occurred';
        if (result['FailMessage']) {
          this.clearTicketFailMessage = result['FailMessage'];
        }
        this.modalService.open(this.CLEAR_TICKET_FAILED_MODAL_ID);
      }
    });
  }

  onUncompleteEvent() {
    let apiValue = {};
    apiValue[this.PrimaryID] = {
      Check: false,
    };

    this.ticketDetails$.unCompleteTicket(apiValue).subscribe(async (response) => {
      if (response && response[0] && response[0]['Error']) {
        let errorMsg = response[0]['Error'].split(':')[1];
        this.snackBarService.openSnackbar(errorMsg, SnackbarType.error);
      } else {
        let uncompleteAutolog = new AutologRow(
          this.AssignmentID,
          this.U2UserID,
          AutologID.UnCompleted,
          'Uncompleted from Utilocate Web App',
          this.datetimeService.localDateToDBDateStr(new Date())
        );
        await this.ticketDetails$.addNewAutolog(this.AssignmentID, uncompleteAutolog);
        await this.insertAutologToServer(uncompleteAutolog);
        await this.startSync(false, true);
      }
    });
  }

  async insertAutologToServer(autolog: AutologRow) {
    try {
      let url = '/api/upload/autolog';
      if (!environment.localhost) {
        url = '/nodejs/api/upload/autolog';
      }
      let ClientID = await this.userService.getUserValueFromToken(UtilocateTokenPaths.CLIENTID);
      let isLive = false;
      if (environment.production == true) {
        isLive = true;
      }
      let body = {
        ClientID: ClientID,
        isLive: isLive,
        AssignmentID: autolog.AssignmentID,
        UserID: autolog.UserID,
        DescID: autolog.DescID,
        Explanation: autolog.Explaination,
      };

      console.log(body);
      let result = await this.baseApiService.invokeApi('PUT', url, body);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  private markAsOfficeCompleteEvent(locateStatusID: any) {
    let apiValue = {};
    apiValue[this.PrimaryID] = {
      Check: false,
    };

    this.ticketDetails$.quickTicketActions_OfficeCompleteTicket(apiValue).subscribe(async (response) => {
      this.progressBarService.stop();
      if (response && response[0] && response[0]['Error']) {
        let errorMsg = response[0]['Error'];
        console.log(errorMsg);
        this.snackBarService.openSnackbar('Unable to Office Complete Ticket', SnackbarType.error, 'OK');
      } else {
        this.snackBarService.openSnackbar('Success', SnackbarType.success);
        await this.startSync(true, true);
      }
    });
  }

  async markAsOfficeOngoingEvent() {
    let apiValue = {};
    apiValue[this.PrimaryID] = {
      Check: false,
    };
    await this.startSync(true, true);
    this.ticketDetails$.quickTicketActions_OfficeOngoingTicket(apiValue).subscribe(async (response) => {
      this.progressBarService.stop();
      if (response && response[0] && response[0]['Error']) {
        let errorMsg = response[0]['Error'].split(':')[1];
        this.snackBarService.openSnackbar(errorMsg, SnackbarType.error, 'OK');
      } else {
        this.snackBarService.openSnackbar('Successfully marked as ongoing', SnackbarType.success);
        this.TicketWorkDetailsComponent.afterOngoing(this.IFrameModal);
      }
    });
  }

  checkSetting() {
    let setting = this.userService.isSettingActive(SettingID.EDIT_COMPLETED);
    if (setting) {
      this.allowEdit = false;
      this.allowEditingCompletionDetails = true;
    }
  }

  async markAsCompleteEvent(locateStatusID: any) {
    if (locateStatusID == LocateStatusID.LOCATE_COMPLETED) {
      this.markAsOfficeCompleteEvent(locateStatusID);
    } else {
      let localSaveResult = await this.ticketDetails$.completeTicketAs(
        locateStatusID,
        this.AssignmentID,
        this.U2UserID
      );
      if (localSaveResult) {
        await this.startSync(true, true);
      } else {
        this.snackBarService.openSnackbar('Failed to complete ticket', SnackbarType.error);
      }
    }
  }

  async makeLoadsheet() {
    await this.generatePDF$.generatePDF(this.PrimaryID, 3, 'Loadsheet PDF', 0, 8);
    this.snackBarService.openSnackbar('Making Loadsheet', SnackbarType.success);
  }

  async makeInvoice() {
    await this.generatePDF$.generatePDF(this.PrimaryID, 3, 'Invoice Summary PDF', 0, 13);
    this.snackBarService.openSnackbar('Making Invoice Summary', SnackbarType.success);
  }

  async startSync(upload, download) {
    try {
      let syncSuccessful = false;
      this.syncModal = this.openSyncModal();
      if (upload && !this.TICKET_PROTECTION_MODE) {
        let result = await this.ticketDetails$.prepareToUpload(this.AssignmentID, this.U2UserID, this.IOwnTicket);

        if (result) {
          if (download) {
            let downloadResult = await this.ngInitDownloadTicket();
            if (downloadResult) {
              this.syncModal.close();
              syncSuccessful = true;
            } else {
              syncSuccessful = false;
            }
          } else {
            this.syncModal.close();
            syncSuccessful = true;
          }
        } else {
          this.snackBarService.openSnackbar('Failed to upload', SnackbarType.error);
        }
      } else if (download) {
        let downloadResult = await this.ngInitDownloadTicket();
        if (downloadResult) {
          this.syncModal.close();
          syncSuccessful = true;
        } else {
          syncSuccessful = false;
        }
      }
      // let setting = await this.setting$.isSettingIDActive(246);
      let setting = this.userService.isSettingActive(SettingID.APP_FORM_TABS_SHOWING);
      if (setting) {
        this.showPreCompletions = true;
      }
      this.dataChanged = false;
      return syncSuccessful;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  openSyncModal() {
    return this.dialog.open(TicketSyncModalComponent, {
      width: '560px',
      height: '560px',
      disableClose: true,
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHander(event) {
    if (this.formGroupValuesChanged && !this.IFrameModal) {
      this.IOwnTicket = false;
      this.TICKET_PROTECTION_MODE = true;
      this.childProps = {
        ...this.childProps,
        TICKET_PROTECTION_MODE: this.TICKET_PROTECTION_MODE,
      };
      this.childProps['views']['ticketInfo']['formGroup'].disable();

      event.returnValue = true;
      if (this.U3UserCategoryID != U3_USER_CATEGORY_ID.Utility) {
        await this.ticketDetails$.updateTicketProtection(
          this.AssignmentID,
          TicketProtectionActionTypeID.REMOVE_TICKET_PROTECTION,
          this.U2UserID
        );
      }
      this.snackBarService.openSnackbar('Please Sync', SnackbarType.warning);
    }
  }
}

@NgModule({
  declarations: [
    TicketDetailsComponent,
    TicketWorkDetailsComponent,
    TicketDigsiteDetailsComponent,
    TicketDocumentDetailsComponent,
    TicketCompletionsDetailsComponent,
    TicketPreCompletionsDetaisComponent,
    TicketAutologDetailsComponent,
    TicketSummaryComponent,
    TicketTagsComponent,
    AddNewAutologModalComponent,
    TicketSyncModalComponent,
    AssignLocatorModalComponent,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    FormInputTemplateModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    ExcavatorContactsCardModule,
    ConfirmationDialogModule,
    DigsiteModule,
    RecursiveFormTemplateModule,
    FloatingActionMenuComponent,
    EsriMapSimpleDrawingModule,
    GoogleMapsSetModule,
    BaseModalModule,
    SchedulerCancelTicketDialogModule,
    TicketProtectionOverrideDialogModule,
  ],
  exports: [TicketDetailsComponent],
})
export class TicketDetailsLibModule {}
