import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { api, apiKeys } from 'src/app/ENDPOINTS';
import { localStorageKeys } from 'src/app/LOCAL_STORAGE';
import { AdminLookupService } from 'src/app/modules/core/admin/admin-lookup.service';
import { ApiService, UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { UserService } from '../../core/services/user/user.service';
import { SettingID } from '../../core/services/user/setting';

@Injectable({
  providedIn: 'root',
})
export class EsriMapService {
  constructor(
    protected logger$: LoggerService,
    protected admin$: AdminLookupService,
    protected utilocateApiService: ApiService,
    protected userService: UserService
  ) {}

  // get all tables that will be used by service and component
  async getRequiredTables() {
    return this.admin$.getAdminTablesFromServer([
      'tbAdmin_EsriLayers',
      'tbAdmin_EsriCredentials',
      'tbAdmin_EsriService',
      'tbAdmin_EsriSubLayers',
      'tbAdmin_EsriLayerGroup',
      'tbAdmin_UtilityToEsriLayer',
    ]);
  }

  getLayers() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriLayers'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getUtilityToLayer() {
    return new Observable((subscriber) => {
      try {
        if (this.userService.isSettingActive(SettingID.UTILITIES_DETERMINE_ESRI_LAYERS)) {
          this.admin$
            .getLookupTableRows(['tbAdmin_UtilityToEsriLayer'], {})
            .then((queryResult) => {
              subscriber.next(queryResult[0]['rows']);
              subscriber.complete();
            })
            .catch((error) => {
              subscriber.next([]);
              subscriber.complete();
            });
        } else {
          subscriber.next([]);
          subscriber.complete();
        }
      } catch (error) {
        console.error(error);
        subscriber.next([]);
        subscriber.complete();
      }
    });
  }

  getSubLayers() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriSubLayers'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getURLs() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriService'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  getCredentials() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriCredentials'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  gatherTicketLatLng() {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.gatherTicketLatLng;
        const type = api[url].type;
        const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.gatherTicketLatLng,
            API_TYPE: type,
            API_BODY: { AssignmentID: aid },
          };
          from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
            console.log(response);

            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error('Failed to GET AssignmentID from storage');
        }
      } catch (error) {
        this.logger$.warn('esrpMap$: applyDigsite: function failure: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  getLayerGroups() {
    return new Observable((subscriber) => {
      try {
        this.admin$
          .getLookupTableRows(['tbAdmin_EsriLayerGroup'], {})
          .then((queryResult) => {
            subscriber.next(queryResult[0]['rows']);
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.next(false);
            subscriber.complete();
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  gatherDigsites() {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.gatherDigsites;
        const type = api[url].type;
        const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.gatherDigsites,
            API_TYPE: type,
            API_BODY: { AssignmentIDs: [aid] },
          };
          from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error('Failed to GET AssignmentID from storage');
        }
      } catch (error) {
        this.logger$.warn('esrpMap$: gatherDigsites: function failure: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  saveDigsite(digsiteDetails) {
    return new Observable((subscriber) => {
      try {
        const url = apiKeys.u2.addDigsiteToTicket;
        const type = api[url].type;
        const aid = sessionStorage.getItem(localStorageKeys.URL_KEYS.assignmentid);

        if (aid) {
          let utilocateApiRequest: UtilocateApiRequest = {
            API_KEY: apiKeys.u2.addDigsiteToTicket,
            API_TYPE: type,
            API_BODY: { DigsiteDetails: digsiteDetails, AssignmentID: aid },
          };
          from(this.utilocateApiService.invokeUtilocateApi(utilocateApiRequest)).subscribe((response) => {
            if (response.ok) {
              subscriber.next(response.body);
            } else {
              subscriber.next(false);
            }
            subscriber.complete();
          });
        } else {
          throw new Error('Failed to GET AssignmentID from storage');
        }
      } catch (error) {
        this.logger$.warn('esrpMap$: saveDigsite: function failure: ', error);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }
}
