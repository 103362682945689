import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AdminLookupService } from 'src/app/modules/core/admin/admin-lookup.service';
import { ADMIN_TABLE_NAMES } from 'src/app/modules/core/admin/tables';
import { BasePageComponent } from 'src/app/modules/core/base-set/base-set.component';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { UtilocateTokenService } from 'src/app/modules/core/services/token/token.service';
import { SettingID } from 'src/app/modules/core/services/user/setting';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { CreateTicketDialogComponent } from 'src/app/modules/create-ticket/create-ticket-dialog/create-ticket-dialog.component';
import { ExcavatorContactsCardProps } from 'src/app/modules/create-ticket/excavator-contacts-card/excavator-contacts-card.component';
import { SchedulerTicketDialogComponent } from 'src/app/modules/scheduler/dialogs/scheduler-ticket-dialog/scheduler-ticket-dialog.component';
import { FAMItem } from '../../../fab/floating-action-menu/floating-action-menu.component';
import { FormHelperService } from '../../../forms/forn-helper.service';
import { ProgressBarService } from '../../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { SnackbarType } from '../../../snackbar/snackbar/snackbar';
import { AssignLocatorModalComponent } from '../../../ticket-assignment/assignLocatorModal/assign-locator-modal.component';
import { ConfirmationModalComponent } from '../../../ticket/modals/generic-confirmation-modal/confirmation-modal.component';
import { LocateStatusID } from '../../ticket-details.module';
import { TicketDetailsService } from '../../ticket-details.service';

@Component({
  selector: 'app-ticket-work-details',
  templateUrl: `ticket-work-details.component.html`,
  styleUrls: ['ticket-work-details.component.scss'],
})
export class TicketWorkDetailsComponent extends BasePageComponent implements OnInit, OnChanges {
  className = 'TicketWorkDetailsComponent';

  @Input() props;
  @Output() uncompleteEvent = new EventEmitter<any>();
  @Output() finishEditModeEmitter = new EventEmitter<object>();
  @Output() finishAssignUserToTicket = new EventEmitter<any>();
  @Output() finishSendToLSP = new EventEmitter<any>();
  @Output() formGroupChanged = new EventEmitter<any>();
  @Output() cancelTicketEvent = new EventEmitter<any>();
  @Output() markAsOfficeOngoingEvent = new EventEmitter<any>();
  @Output() syncAfterAction = new EventEmitter<any>();

  ticket: any = null;

  excavatorContactProps: ExcavatorContactsCardProps;
  multipleExcavatorContacts: boolean = false;
  AssignmentID: any;
  PrimaryID: any;
  UserCategoryID: any;
  tbCompletions_AssignmentToTags: any;
  isUtilityUser: boolean = false;

  formProps: any;
  TICKET_PROTECTION_MODE: boolean = true;
  summaryProps: any;
  workDetailsEditMode: boolean = false;
  assignToUserModal: any;
  isU2TicketComplete: any = true;

  workDetails: any;
  workDetailsTemplateGroup: any;
  formattedWorkDetails: any;

  fabMenu: FAMItem[];
  completedFabMenu: FAMItem[];

  lsps: any;

  constructor(
    logger$: LoggerService,
    private admin$: AdminLookupService,
    private formHelper$: FormHelperService,
    public assignUserModal: MatDialog,
    private ticket$: TicketDetailsService,
    // private settingService$: SettingsService,
    private userService: UserService,
    public createTicketDialog: MatDialog,
    private progressBarService: ProgressBarService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private routerService: Router,
    private route: ActivatedRoute,
    private tokenService: UtilocateTokenService
  ) {
    super(logger$);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.props.currentValue) {
      if (changes.props.currentValue.PrimaryID != null) {
        this.PrimaryID = changes.props.currentValue.PrimaryID;
      }

      if (changes.props.currentValue.AssignmentID != null) {
        this.AssignmentID = changes.props.currentValue.AssignmentID;
      }

      if (changes.props.currentValue.isUtilityUser != null) {
        this.isUtilityUser = changes.props.currentValue.isUtilityUser;
      }

      if (changes.props.currentValue.TICKET_PROTECTION_MODE != null) {
        this.TICKET_PROTECTION_MODE = changes.props.currentValue.TICKET_PROTECTION_MODE;
      }

      if (
        changes.props.currentValue.ticket &&
        changes.props.currentValue.ticket.ExcavatorID != null &&
        changes.props.currentValue.ticket.ExcavatorID != 0 &&
        changes.props.currentValue.TICKET_PROTECTION_MODE != null
      ) {
        this.excavatorContactProps = {
          AssignmentID: this.AssignmentID,
          ExcavatorID: changes.props.currentValue.ticket.ExcavatorID,
          TICKET_PROTECTION_MODE: changes.props.currentValue.TICKET_PROTECTION_MODE,
        };
      }

      if (
        changes.props.currentValue.views &&
        changes.props.currentValue.views.ticketInfo &&
        changes.props.currentValue.ticket &&
        changes.props.currentValue.UserCategoryID != null &&
        changes.props.currentValue.CallTypeDesc != null &&
        changes.props.currentValue.workDetailsEditMode != null &&
        changes.props.currentValue.isU2TicketComplete != null &&
        changes.props.currentValue.tbCompletions_AssignmentToTags
      ) {
        this.UserCategoryID = changes.props.currentValue.UserCategoryID;
        this.workDetailsEditMode = changes.props.currentValue.workDetailsEditMode;
        this.workDetails = changes.props.currentValue.views.ticketInfo['formGroup'];
        this.workDetailsTemplateGroup = changes.props.currentValue.views.ticketInfo['groups'];
        this.isU2TicketComplete = changes.props.currentValue.isU2TicketComplete;
        this.tbCompletions_AssignmentToTags = changes.props.currentValue.tbCompletions_AssignmentToTags;

        this.excavatorContactProps['isU2TicketComplete'] = this.isU2TicketComplete;

        this.createFormattedFormData(this.workDetails, this.workDetailsTemplateGroup);
        this.listenForMyFormGroupChanges();

        // props for app-ticket-summary
        this.summaryProps = {
          ...this.summaryProps,
          ticket: changes.props.currentValue.ticket,
          UserCategoryID: changes.props.currentValue.UserCategoryID,
          CallTypeDesc: changes.props.currentValue.CallTypeDesc,
          tbCompletions_AssignmentToTags: changes.props.currentValue.tbCompletions_AssignmentToTags,
        };
        this.ticket = changes.props.currentValue.ticket;

        // props for app-form-input-template
        this.formProps = {
          ...this.formProps,
          categoryLinks: ['ticketInfo'],
          view: changes.props.currentValue.views.ticketInfo,
          views: changes.props.currentValue.views,
          ticket: changes.props.currentValue.ticket,
          TICKET_PROTECTION_MODE: this.TICKET_PROTECTION_MODE,
          isU2TicketComplete: this.isU2TicketComplete,
        };
      }

      if (changes.props.currentValue.lsps) {
        this.lsps = changes.props.currentValue.lsps;
      }

      if (changes.props.currentValue.lsps) {
        this.lsps = changes.props.currentValue.lsps;
      }
    }
  }

  ngOnInit() {
    this.fabMenu = [
      {
        label: 'Assign Ticket',
        icon: 'sendable',
        action: this.assignTicketClick,
        iconType: 'svg',
      },
      {
        label: 'Cancel Ticket',
        icon: 'close_circle',
        action: this.onCancelTicket,
        iconType: 'svg',
      },
      {
        label: 'Copy Ticket',
        icon: 'copy',
        action: this.copyTicket,
        iconType: 'svg',
      },
    ];

    this.completedFabMenu = [
      {
        label: 'Uncomplete Ticket',
        icon: 'undo',
        action: this.onUnCompleteClick,
      },
      {
        label: 'Copy Ticket',
        icon: 'copy',
        action: this.copyTicket,
        iconType: 'svg',
      },
      {
        label: 'Office Ongoing',
        icon: 'ongoing',
        action: this.markOngoing,
        iconType: 'svg',
      },
    ];

    this.multipleExcavatorContacts = this.userService.isSettingActive(SettingID.MULTI_CONTACT_EXCAVATOR);
    // this.settingService$.isSettingIDActive(SETTING_ID_MULTI_CONTACT_EXCAVATOR).then(res => {
    //   this.multipleExcavatorContacts = res;
    // })
  }

  async createFormattedFormData(workDetailsFormGroup, workDetailsTemplateGroup) {
    try {
      // readable LocateStatusID
      if (workDetailsFormGroup && workDetailsFormGroup['value'] && workDetailsFormGroup['value']['LocateStatusID']) {
        const locateStatusID = workDetailsFormGroup['value']['LocateStatusID'];
        const locateStatusResult = await this.admin$.getAdminTableDescFromID(ADMIN_TABLE_NAMES.tbAdmin_LocateStatus, {
          LocateStatusID: locateStatusID,
        });
        if (locateStatusResult && locateStatusResult[0]) {
          const LocateStatusDesc = locateStatusResult[0]['LocateStatusDesc'];
          this.workDetails.patchValue({
            ...this.workDetails['value'],
            LocateStatusID: LocateStatusDesc,
          });
        }
      }

      if (
        workDetailsTemplateGroup &&
        workDetailsTemplateGroup['workDetails'] &&
        workDetailsTemplateGroup['workDetails']['fields'] &&
        workDetailsTemplateGroup['workDetails']['fields']['TicketTags']
      ) {
        const AssignmentTagIDs = this.tbCompletions_AssignmentToTags.reduce((total, current) => {
          if (current && current.TagID) {
            total.push({ value: current.TagID, TagID: current.TagID });
          }
          return total;
        }, []);

        const tags = await this.admin$.getAdminTables([ADMIN_TABLE_NAMES.tbAdmin_TicketTags]);
        const tagDropdownVals = tags[0]['Data'].reduce((total, current) => {
          const value = {
            text: current['TagName'],
            value: current['TagID'],
          };

          total.push(value);
          return total;
        }, []);

        if (AssignmentTagIDs && AssignmentTagIDs.length > 0) {
          workDetailsFormGroup.get('TicketTags').setValue(AssignmentTagIDs, { emitEvent: false }); // set existing dropdown options
        }

        workDetailsTemplateGroup['workDetails']['fields']['TicketTags']['selectOptions'] = tagDropdownVals; // set all dropdown options
      }
    } catch (error) {
      console.error(error);
    }
  }

  listenForMyFormGroupChanges() {
    try {
      let alreadySaving = false; // prevent multiple saves in single set of listeners

      // get date fields and listen to changes
      // we need to format dates before saving to IDB
      const dateFields = this.formHelper$.getAllDateFields(this.workDetailsTemplateGroup);
      for (let i = 0; i < dateFields.length; i++) {
        // listen to changes for each date field
        this.workDetails
          .get(dateFields[i])
          .valueChanges.pipe(debounceTime(300))
          .subscribe((data) => {
            // only update when not first load
            const isPristine = this.workDetails.pristine;
            if (!isPristine && !alreadySaving) {
              alreadySaving = true;

              // format date
              const date = new Date(Date.parse(data)).toISOString();
              this.workDetails.patchValue(
                { ...this.workDetails['value'], [dateFields[i]]: date },
                { emitEvent: false }
              );
              this.formGroupChanged.emit({ saved: true });
              alreadySaving = false;
            }
          });
      }

      this.workDetails
        .get('TicketTags')
        .valueChanges.pipe(debounceTime(300))
        .subscribe(async (data) => {
          const isPristine = this.workDetails.pristine;
          if (!isPristine && !alreadySaving) {
            alreadySaving = true;

            if (this.tbCompletions_AssignmentToTags) {
              const updatedTags = data.reduce((total, current) => {
                total.push(current['value']);
                return total;
              }, []);

              const existingTags = this.tbCompletions_AssignmentToTags.reduce((total, current) => {
                total.push(current['TagID']);
                return total;
              }, []);

              const newTicketTags = [];
              for (let i = 0; i < this.tbCompletions_AssignmentToTags.length; i++) {
                const curExistingTicketTag = this.tbCompletions_AssignmentToTags[i];

                if (updatedTags.indexOf(curExistingTicketTag['TagID']) > -1) {
                  // keep existing tags
                  newTicketTags.push({
                    ...curExistingTicketTag,
                    bFieldRemoved: 0,
                    bFieldAdded: 1,
                  });
                } else if (updatedTags.indexOf(curExistingTicketTag['TagID']) == -1) {
                  // remove de-selected tags
                  newTicketTags.push({
                    ...curExistingTicketTag,
                    bFieldRemoved: 1,
                    bFieldAdded: 0,
                  });
                }
              }

              for (let i = 0; i < data.length; i++) {
                if (existingTags.indexOf(data[i]['value']) == -1) {
                  newTicketTags.push({
                    AssignmentID: this.AssignmentID,
                    TagID: data[i]['value'],
                    bFieldAdded: 1,
                    bFieldRemoved: 0,
                  });
                }
              }

              this.tbCompletions_AssignmentToTags = [...newTicketTags];
              await this.ticket$.updateTicketTags(this.AssignmentID, {
                Data: this.tbCompletions_AssignmentToTags,
              });
              this.formGroupChanged.emit({ saved: true });

              alreadySaving = false;
            }
          }
        });

      // for all other changes
      this.workDetails.valueChanges.pipe(debounceTime(300)).subscribe(async () => {
        const isPristine = this.workDetails.pristine;
        if (!isPristine && !alreadySaving) {
          alreadySaving = true;
          await this.ticket$.updateSavedDataToLocalDb(
            this.AssignmentID,
            this.workDetails,
            this.workDetailsTemplateGroup
          );
          this.formGroupChanged.emit({ saved: true });
          alreadySaving = false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  saveChanges = () => {
    this.handleEditModeFinish(true);
  };

  discardChanges = () => {
    console.log('here');

    this.handleEditModeFinish(false);
  };

  handleEditModeFinish(isSaved) {
    this.finishEditModeEmitter.emit({ saved: isSaved });
  }

  assignTicketClick = () => {
    this.initAssignTicketToUser();
  };

  async initAssignTicketToUser() {
    try {
      this.progressBarService.start();
      const queryResult = await this.admin$.getAdminTables([
        ADMIN_TABLE_NAMES.tbLogin_Users,
        ADMIN_TABLE_NAMES.tbLogin_UserCategories,
      ]);
      this.progressBarService.stop();

      if (queryResult && queryResult[0] && queryResult[1]) {
        const userList = queryResult[0]['Data']; // tbLogin_Users rows
        const userCategoryList = queryResult[1]['Data']; // tbLogin_UserCategories rows

        const formattedData = this.formatUserList(userList, userCategoryList);
        this.assignToUserModal = this.openAssignToUserModal(formattedData);
        this.assignToUserModal.afterClosed().subscribe(async (nextValue) => {
          // this.workDetails.patchValue({ CurrentLocatorID: nextValue["UserID"] });
          // await this.ticket$.updateSavedDataToLocalDb(this.AssignmentID, this.workDetails, this.workDetailsTemplateGroup);
          if (nextValue['UserID'] != null) {
            this.finishAssignUserToTicket.emit(nextValue);
          } else if (nextValue['LSPID']) {
            this.finishSendToLSP.emit(nextValue);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  onCancelTicket = () => {
    this.cancelTicketEvent.emit(LocateStatusID.OFFICE_CANCELLED);
  };

  openAssignToUserModal(listData) {
    return this.assignUserModal.open(AssignLocatorModalComponent, {
      width: '560px',
      // height: '250px',
      data: {
        dataKey: listData,
        lsps: this.lsps,
      },
    });
  }

  onUnCompleteClick = () => {
    this.uncompleteEvent.emit();
  };

  //on click of mark as ongoing
  onMarkOngoing() {
    let modalData: object;

    modalData = {
      header: 'Mark as ongoing',
      message: 'Are you sure you want to mark this ticket as ongoing?',
    };

    this.dialog
      .open(ConfirmationModalComponent, {
        width: '380px',
        data: modalData,
      })
      .afterClosed()
      .subscribe((nextVal) => {
        if (nextVal) {
          this.markAsOfficeOngoingEvent.emit();
        }
      });
  }

  markOngoing = () => {
    this.onMarkOngoing();
  };

  //closes and reopens ticket to new ticket
  afterOngoing(iframemodal) {
    console.log(iframemodal);
    let modalData: object;

    modalData = {
      header: 'New Ticket',
      message: 'The ticket has changed do you want to load the new ticket',
    };

    this.dialog
      .open(ConfirmationModalComponent, {
        width: '380px',
        data: modalData,
      })
      .afterClosed()
      .subscribe((nextVal) => {
        if (nextVal) {
          if (iframemodal) {
            this.openTicketDialogIframe();
          } else {
            this.openTicketDialogs();
          }
          this.snackBarService.openSnackbar('New sub-number loaded', SnackbarType.success);
        } else {
          this.syncAfterAction.emit();
        }
      });
  }

  openTicketDialogIframe(): void {
    console.log('in IFrame');
    const token = this.tokenService.getToken();
    const newQueryParams = {
      AssignmentID: this.AssignmentID,
      iframemodal: true,
      Authorization: token,
    };
    console.log(newQueryParams);
    this.routerService.navigate([this.route.snapshot.routeConfig.path], {
      queryParams: newQueryParams,
      replaceUrl: true,
    });
    // window.location.reload()
  }

  // closes and opens new ticket
  openTicketDialogs(): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(SchedulerTicketDialogComponent, {
      width: window.innerWidth.toString() + 'px',
      maxWidth: '100%',
      height: window.innerHeight.toString() + 'px', // if small screen make full screen
      panelClass: 'margin-dialog-container',
      data: {
        ticketData: this.ticket,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  // will format tbLogin_Users and tbLogin_UserCateogies
  // { name: "FirstName LastName (UserCategoryDesc)", value: UserID }
  formatUserList(userList: Array<any>, userCategoryList: Array<any>) {
    try {
      const formattedUserList = userList.reduce((total, current) => {
        // non-archived users only
        if (current['Archived'] != null && current['Archived'] == 0) {
          const name = current['FirstName'] + ' ' + current['LastName'];
          const userCategoryID = current['UserCategoryID'];
          const UserID = current['UserID'];
          let UserCategoryDesc = '';

          for (let i = 0; i < userCategoryList.length; i++) {
            if (userCategoryList[i]['UserCategoyID'] == userCategoryID) {
              UserCategoryDesc = userCategoryList[i]['Description'];
              break;
            }
          }

          const formattedName = `${name} (${UserCategoryDesc})`;
          total.push({
            name: formattedName,
            value: UserID,
          });
        }

        return total;
      }, []);

      return formattedUserList;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  copyTicket = () => {
    console.log('copy');

    const ticketDialog = this.createTicketDialog.open(CreateTicketDialogComponent, {
      width: window.innerWidth.toString() + 'px',
      maxWidth: '100%',
      height: window.innerHeight.toString() + 'px', //TODO: if small screen make full screen
      panelClass: 'margin-dialog-container',
      data: {
        AssignmentID: this.AssignmentID,
        PrimaryID: this.PrimaryID,
        Office: true,
      },
    });

    ticketDialog.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  };
}
